import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export default function MainPAR({
  products,
  countries,
  setMainFormData,
  storedData,
  reportNum,
  setMainForm,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const VaccineList = products.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  const CountryList = countries;
  const validate = (values) => {
    const errors = {};
    if (!values.product)
      errors.product = t("please_select") + " " + t("product");

    if (!values.country)
      errors.country = t("please_select") + " " + t("country");

    if (!values.date_of_invoice)
      errors.date_of_invoice = t("please_enter") + " " + t("date_of_invoice");

    if (!values.reportDate)
      errors.reportDate = t("please_enter") + " " + t("report_date");

    if (!values.place_of_inspection)
      errors.place_of_inspection =
        t("please_enter") + " " + t("place_of_inspection");
    if (!values.date_of_shipment)
      errors.date_of_shipment = t("please_enter") + " " + t("date_of_shipment");

    if (!values.time_of_shipment)
      errors.time_of_shipment = t("please_enter") + " " + t("time_of_shipment");

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      product: storedData?.product || "",
      country: storedData?.country || "Bangladesh",
      reportDate:
        dayjs(storedData?.reportDate).format("YYYY-MM-DD") ||
        dayjs().format("YYYY-MM-DD"),
      place_of_inspection: storedData?.placeOfInspection || "Central Authority",
      date_of_invoice:
        storedData?.date_of_invoice || dayjs().format("YYYY-MM-DD"),
      date_of_shipment:
        storedData?.date_of_shipment || dayjs().format("YYYY-MM-DD"),
      time_of_shipment: storedData?.time_of_shipment || dayjs().format("HH:mm"),
    },
    validate,
    onSubmit: async (values) => {
      setMainFormData(0, values);
      setIsSubmitted(true);
      navigate("?form=part1");
      setMainForm(true);
      toast.success(t("general_info_saved"));
    },
  });

  return (
    <React.Fragment>
      <div className='VAR__card'>
        <form
          className='ModalPopup__form_container'
          onSubmit={formik.handleSubmit}
        >
          {/* Form Row */}
          <article className='ModalPopup__form_section'>
            <div className='Input__table'>
              <div className='Input__row three_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("product")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      disabled={isSubmitted}
                      className='mui_custom_input_field'
                      id='product'
                      name='product'
                      value={formik.values.product}
                      onChange={formik.handleChange}
                    >
                      {VaccineList?.map((vaccine, index) => (
                        <MenuItem key={index} value={vaccine._id}>
                          {vaccine.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.product && formik.touched.product && (
                    <span className='Input__error_fs'>
                      {formik.errors.product}
                    </span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("country")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      disabled={isSubmitted}
                      className='mui_custom_input_field'
                      id='country'
                      name='country'
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    >
                      {CountryList?.map((country, index) => (
                        <MenuItem key={index} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.country && formik.touched.country && (
                    <span className='Input__error_fs'>
                      {formik.errors.country}
                    </span>
                  )}
                </div>

                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("date_of_invoice")} <b>*</b>
                  </p>
                  <input
                    disabled={isSubmitted}
                    type='date'
                    className='mi_custom_textfield date_field'
                    id='date_of_invoice'
                    name='date_of_invoice'
                    onChange={formik.handleChange}
                    value={formik.values.date_of_invoice}
                    min={formik.values.date_of_shipment}
                  />
                  {formik.errors.date_of_invoice &&
                    formik.touched.date_of_invoice && (
                      <span className='Input__error_fs'>
                        {formik.errors.date_of_invoice}
                      </span>
                    )}
                </div>
              </div>
            </div>
          </article>

          <article className='ModalPopup__form_section'>
            <div className='Input__table'>
              <div className='Input__row three_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("place_of_inspection")} <b>*</b>
                  </p>
                  <input
                    type='text'
                    disabled
                    className={`mi_custom_textfield `}
                    id='place_of_inspection'
                    name='place_of_inspection'
                    value={formik.values.place_of_inspection}
                    onChange={formik.handleChange}
                    style={{ opacity: "0.5" }}
                  />
                  {formik.errors.place_of_inspection &&
                    formik.touched.place_of_inspection && (
                      <span className='Input__error_fs'>
                        {formik.errors.place_of_inspection}
                      </span>
                    )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t("document_number")}</p>
                  <input
                    disabled
                    type='text'
                    className='mi_custom_textfield date_field'
                    id='reportDate'
                    name='reportDate'
                    value={reportNum}
                  />
                  {formik.errors.reportDate && formik.touched.reportDate && (
                    <span className='Input__error_fs'>
                      {formik.errors.reportDate}
                    </span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t("report_date")}</p>
                  <input
                    disabled={isSubmitted}
                    type='date'
                    className='mi_custom_textfield date_field'
                    id='reportDate'
                    name='reportDate'
                    onChange={formik.handleChange}
                    value={formik.values.reportDate}
                  />
                  {formik.errors.reportDate && formik.touched.reportDate && (
                    <span className='Input__error_fs'>
                      {formik.errors.reportDate}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </article>

          <article className='ModalPopup__form_section'>
            <div className='Input__table'>
              <div className='Input__row two_column'>
                <div className='VARform__column'>
                  <div className='Input__row two_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("date_of_shipment_received")}
                      </p>
                      <input
                        disabled={isSubmitted}
                        type='date'
                        className='mi_custom_textfield date_field'
                        id='date_of_shipment'
                        name='date_of_shipment'
                        onChange={formik.handleChange}
                        value={formik.values.date_of_shipment}
                      />
                      {formik.errors.date_of_shipment &&
                        formik.touched.date_of_shipment && (
                          <span className='Input__error_fs'>
                            {formik.errors.date_of_shipment}
                          </span>
                        )}
                    </div>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("time_of_shipment_received")} <b>*</b>
                      </p>
                      <input
                        disabled={isSubmitted}
                        type='time'
                        className='mi_custom_textfield date_field'
                        id='time_of_shipment'
                        name='time_of_shipment'
                        onChange={formik.handleChange}
                        value={formik.values.time_of_shipment}
                      />

                      {formik.errors.time_of_shipment &&
                        formik.touched.time_of_shipment && (
                          <span className='Input__error_fs'>
                            {formik.errors.time_of_shipment}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <div className='Mainvar_form_action'>
            <div className='Mainvar_button_actions'>
              {isSubmitted && (
                <button
                  className='mi_btn mi_btn_medium mi_btn_secondary'
                  type='button'
                  onClick={() => setIsSubmitted(false)}
                >
                  <span>{t("edit")}</span>
                </button>
              )}

              <button
                disabled={isSubmitted}
                className={`mi_btn mi_btn_medium ${
                  isSubmitted ? "mi_btn_disabled" : "mi_btn_primary"
                } `}
                type='submit'
              >
                <span>{t("save_continue")}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
