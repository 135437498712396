import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./VARform.css";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { getDates } from "../../../../shared/common/commonFunctions";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function VARform3({
  setFormComplete,
  setData,
  countries,
  storedData,
  products,
}) {
  const { t } = useTranslation();
  const selectedProduct = products?.filter(
    (prod) => prod._id === storedData.product,
  );

  const vaccineInfo = {
    _id: selectedProduct?.[0]?._id,
    name: selectedProduct?.[0]?.name,
    type: selectedProduct?.[0]?.type,
    units: selectedProduct?.[0]?.units,
    manufacturer: selectedProduct?.[0]?.manufacturers?.[0]?.details?.name,
  };

  const [qtysList, setQtysList] = useState();
  const [qtyPerUnit, setQtyPerUnit] = useState(null);

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    Country: Yup.string().required(t("country_error")),
    // po: Yup.string().required("Purchase Order Number is Required"),

    consignee: Yup.string().required(t("consignee_error")),
    manufacturer: Yup.string().required(t("manufacturer_error")),
  });
  const formik = useFormik({
    initialValues: {
      Country: storedData?.shipmentDetails?.originCountry || "",
      Question1: storedData?.shipmentDetails?.shortShipmentNotification || true,
      Question2: storedData?.shipmentDetails?.shortShipment || true,
      po: storedData?.shipmentDetails?.poNo || "",

      manufacturer:
        storedData?.shipmentDetails?.products?.[0]?.manufacturerId || "",
      volume: storedData?.shipmentDetails?.products?.[0]?.volumePerUnit || "",
      consignee: storedData?.shipmentDetails?.consignee || "CMSD",
      comment: storedData?.shipmentDetails?.comments || "",

      vaccineDetails: storedData?.shipmentDetails?.products
        ? storedData?.shipmentDetails?.products?.map((detail) => ({
            batchNo: detail?.batchNo || "",
            boxes: detail?.noOfBoxes || "",
            quantity: detail?.noOfUnits || "",
            mfgDate: dayjs(detail?.mfgDate).format("YYYY-MM-DD") || "",
            expDate: dayjs(detail?.expDate).format("YYYY-MM-DD") || "",
            qpUnit: detail?.quantityPerUnit || "1",
          }))
        : [
            {
              batchNo: "",
              boxes: "",
              quantity: "",
              mfgDate: "",
              expDate: "",
            },
          ],

      diluentDetails: storedData?.shipmentDetails?.diluentDroppers
        ? storedData?.shipmentDetails?.diluentDroppers?.map((detail) => ({
            batchNo: detail?.batchNo || "",
            boxes: detail?.noOfBoxes || "",
            quantity: detail?.noOfUnits || "",
            mfgDate: dayjs(detail?.mfgDate).format("YYYY-MM-DD") || "",
            expDate: dayjs(detail?.expDate).format("YYYY-MM-DD") || "",
            qpUnit: detail?.quantityPerUnit || "1",
          }))
        : [
            {
              batchNo: "",
              boxes: "",
              quantity: "",
              mfgDate: "",
              expDate: "",
            },
          ],
    },
    validationSchema,
    onSubmit: (values) => {
      setData(3, {
        Country: values.Country,
        consignee: values.consignee,
        manufacture: values.manufacturer,
        volume: values.volume,
        po: values.po,
        Question1: values.Question1,
        Question2: values.Question2,
        comment: values.comment,
        vaccineDetails: values.vaccineDetails,
        diluentDetails: values.diluentDetails,
        vaccineInfo: vaccineInfo,
        qtyPerUnit: qtyPerUnit,
      });
      navigate("?form=part4");
      setFormComplete(4);

      toast.success(t("vaccine_shipment") + " " + t("saved"));
    },
  });

  const handlePrev = () => {
    navigate("?form=part2");
    setFormComplete(2);
  };

  const addQtysList = (id) => {
    let qtUn = selectedProduct[0]?.manufacturers.find((item) => id === item.id);
    setQtysList(qtUn?.quantityPerUnit);
  };

  useEffect(() => {
    const resData = selectedProduct[0]?.manufacturers.find(
      (item) => item.id === formik.values.manufacturer,
    );

    if (resData?.details?.country) {
      formik.setFieldValue("Country", resData?.details?.country);
      formik.setFieldValue("volume", resData?.volume);
    }
  }, [formik.values.manufacturer]);

  const addQpUnit = (event) => {
    formik.setFieldValue(
      "vaccineDetails",
      formik.values.vaccineDetails.map((prod) =>
        prod
          ? {
              ...prod,
              qpUnit: event.target.value,
            }
          : prod,
      ),
    );
    formik.setFieldValue(
      "diluentDetails",
      formik.values.diluentDetails.map((prod) =>
        prod
          ? {
              ...prod,
              qpUnit: event.target.value,
            }
          : prod,
      ),
    );
  };

  useEffect(() => {
    if (qtysList?.length) {
      setQtyPerUnit(qtysList?.[0]);
      formik.setFieldValue(
        "vaccineDetails",
        formik.values.vaccineDetails.map((prod) =>
          prod
            ? {
                ...prod,
                qpUnit: qtysList?.[0],
              }
            : prod,
        ),
      );
      formik.setFieldValue(
        "diluentDetails",
        formik.values.diluentDetails.map((prod) =>
          prod
            ? {
                ...prod,
                qpUnit: qtysList?.[0],
              }
            : prod,
        ),
      );
    }
  }, [qtysList, formik.values.manufacturer]);

  useEffect(() => {
    if (storedData?.shipmentDetails?.products?.[0]?.quantityPerUnit) {
      setQtyPerUnit(
        storedData?.shipmentDetails?.products?.[0]?.quantityPerUnit,
      );
    }
  }, [storedData?.shipmentDetails?.products]);

  useEffect(() => {
    addQtysList(storedData?.shipmentDetails?.manufacturerInfo);
    if (storedData?.shipmentDetails?.qtyPerUnit) {
      setQtyPerUnit(storedData?.shipmentDetails?.qtyPerUnit);
    }
  }, [storedData?.shipmentDetails]);

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className='VARform'>
        <div className='VARform__header'>
          <h1 className='page__body_bigheading_fs'>{t("VAR_part3_title")}</h1>
        </div>
        <div className='VARform__body'>
          <div className='VARform__table'>
            <article className='VARform__row three_column'>
              <div className='VARform__column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("purchase_order_number")} <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("po")}
                    required
                  />
                  {formik.touched.po && formik.errors.po && (
                    <span className='Input__error_fs'>{formik.errors.po}</span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("country")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      {...formik.getFieldProps("Country")}
                      disabled
                    >
                      {countries?.map((country, index) => (
                        <MenuItem key={index} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.Country && formik.errors.Country && (
                    <span className='Input__error_fs'>
                      {formik.errors.Country}
                    </span>
                  )}
                </div>
              </div>

              <div className='VARform__column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("consignee")} <b>*</b>
                  </p>
                  <input
                    type='text'
                    disabled
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("consignee")}
                    style={{ opacity: "0.5" }}
                  />
                  {formik.touched.consignee && formik.errors.consignee && (
                    <span className='Input__error_fs'>
                      {formik.errors.consignee}
                    </span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("dose_per_vial")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      value={qtyPerUnit}
                      onChange={(e) => {
                        setQtyPerUnit(e.target.value);
                        addQpUnit(e);
                      }}
                      disabled={qtysList?.length > 1 ? false : true}
                      required
                    >
                      {qtysList ? (
                        qtysList?.map((qty, index) => (
                          <MenuItem key={index} value={qty}>
                            {qty}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={"1"} value={"1"}>
                          1
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {formik.touched.qpUnit && formik.errors.Country && (
                    <span className='Input__error_fs'>
                      {formik.errors.Country}
                    </span>
                  )}
                </div>
              </div>

              <div className='VARform__column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("manufacturer")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      {...formik.getFieldProps("manufacturer")}
                      onChange={(event) => {
                        formik.handleChange(event);
                        addQtysList(event.target.value);
                      }}
                    >
                      {selectedProduct[0]?.manufacturers?.map((org) => (
                        <MenuItem
                          key={org.details._id}
                          value={org.details?._id}
                        >
                          {org?.details?.name?.length > 39
                            ? org?.details?.name.substring(0, 40) + "..."
                            : org?.details?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.manufacturer &&
                    formik.errors.manufacturer && (
                      <span className='Input__error_fs'>
                        {formik.errors.manufacturer}
                      </span>
                    )}
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className='VARgreen__form'>
          <GreenFormRow formik={formik} qtyPerUnit={qtyPerUnit} t={t} />

          {selectedProduct[0]?.name === "BCG" && (
            <DiluentDroppersForm
              formik={formik}
              qtyPerUnit={qtyPerUnit}
              t={t}
            />
          )}
          {selectedProduct[0]?.name === "MR" && (
            <DiluentDroppersForm
              formik={formik}
              qtyPerUnit={qtyPerUnit}
              t={t}
            />
          )}
          {selectedProduct[0]?.name === "Measles and Rubella" && (
            <DiluentDroppersForm
              formik={formik}
              qtyPerUnit={qtyPerUnit}
              t={t}
            />
          )}
        </div>
        <div className='VARform__radio_list'>
          <div className='VARradio__questions one_half_by_column'>
            <p className='page__body_fs mi_black'>{t("PAR_part1_question1")}</p>
            <FormControl>
              <RadioGroup
                row
                name='Question1'
                {...formik.getFieldProps("Question1")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='VARradio__questions one_half_by_column'>
            <p className='page__body_fs mi_black'>{t("VAR_part1_question2")}</p>
            <FormControl>
              <RadioGroup
                row
                name='Question2'
                {...formik.getFieldProps("Question2")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className='VARform__footer'>
          <article className='VARform__row two_by_one_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t("comments")} </p>
              <input
                type='text'
                className='mi_custom_textfield'
                {...formik.getFieldProps("comment")}
              />
            </div>
            <div className='Input__column_action'>
              <button
                type='button'
                className='mi_btn mi_btn_medium mi_btn_secondary'
                onClick={handlePrev}
              >
                <span>{t("prev")}</span>
              </button>
              <button
                className='mi_btn mi_btn_medium mi_btn_primary'
                type='submit'
              >
                <span>{t("save_continue")}</span>
              </button>
            </div>
          </article>
        </div>
      </form>
    </React.Fragment>
  );
}

function GreenFormRow({ formik, qtyPerUnit, t }) {
  const [yesterday, tomorrow] = getDates();
  const addNewRow = () => {
    formik.setValues({
      ...formik.values,
      vaccineDetails: [
        ...formik.values.vaccineDetails,

        {
          batchNo: "",
          boxes: "",
          quantity: "",
          mfgDate: "",
          expDate: "",
          qpUnit: qtyPerUnit,
        },
      ],
    });
  };

  const removeRow = (indexOfObject) => {
    formik.setValues({
      ...formik.values,
      vaccineDetails: formik.values.vaccineDetails.filter(
        (_, index) => index !== indexOfObject,
      ),
    });
  };

  return (
    <React.Fragment>
      <div className='GreenFormRow'>
        {formik.values.vaccineDetails.map((vaccineDetail, index) => (
          <article key={index} className='VARform__row three_column space_btm'>
            <div className='VARform__column'>
              <div className='Input__row two_column'>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='page__body_fs mi_black'>{t("vaccine")}</p>
                  </div>
                  {formik.values.vaccineDetails.length > 1 && (
                    <button
                      onClick={() => removeRow(index)}
                      type='button'
                      className='VAR__delete_btn'
                    >
                      <i className='fa-solid fa-trash'></i>
                    </button>
                  )}
                </div>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("lot_number")}
                      <b>*</b>
                    </p>
                    <input
                      type='text'
                      name={`vaccineDetails[${index}].batchNo`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={vaccineDetail.batchNo}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='VARform__column'>
              <div className='Input__row two_column'>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("no_of_boxes")} <b>*</b>
                    </p>
                    <input
                      type='number'
                      name={`vaccineDetails[${index}].boxes`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={vaccineDetail.boxes}
                      required
                    />
                  </div>
                </div>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("no_of_vials")} <b>*</b>
                    </p>
                    <input
                      type='number'
                      name={`vaccineDetails[${index}].quantity`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={vaccineDetail.quantity}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='VARform__column'>
              <div className='Input__row two_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("manufacturing_date")} <b>*</b>
                  </p>
                  <input
                    type='date'
                    name={`vaccineDetails[${index}].mfgDate`}
                    className='mi_custom_textfield'
                    max={yesterday}
                    onChange={formik.handleChange}
                    value={vaccineDetail.mfgDate}
                    required
                  />
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("expiry_date")} <b>*</b>
                  </p>
                  <input
                    type='date'
                    name={`vaccineDetails[${index}].expDate`}
                    className='mi_custom_textfield'
                    min={tomorrow}
                    onChange={formik.handleChange}
                    value={vaccineDetail.expDate}
                    required
                  />
                </div>
              </div>
            </div>
          </article>
        ))}
        <div className='VARgreen__btn'>
          <button
            type='button'
            onClick={addNewRow}
            className='mi_btn mi_btn_medium mi_btn_white'
          >
            <span>{t("add_new_row_button")}</span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function DiluentDroppersForm({ formik, qtyPerUnit, t }) {
  const [yesterday, tomorrow] = getDates();
  const addDiluentNewRow = () => {
    formik.setValues({
      ...formik.values,
      diluentDetails: [
        ...formik.values.diluentDetails,

        {
          batchNo: "",
          boxes: "",
          quantity: "",
          mfgDate: "",
          expDate: "",
          qpUnit: qtyPerUnit,
        },
      ],
    });
  };
  const removeRow = (indexOfObject) => {
    formik.setValues({
      ...formik.values,
      diluentDetails: formik.values.diluentDetails.filter(
        (_, index) => index !== indexOfObject,
      ),
    });
  };

  return (
    <React.Fragment>
      <div className='GreenFormRow'>
        {formik.values.diluentDetails.map((diluentDetail, index) => (
          <article key={index} className='VARform__row three_column space_btm'>
            <div className='VARform__column'>
              <div className='Input__row two_column'>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='page__body_fs mi_black'>
                      {t("diluent_droppers")}
                    </p>
                  </div>
                  {formik.values.diluentDetails.length > 1 && (
                    <button
                      onClick={() => removeRow(index)}
                      type='button'
                      className='VAR__delete_btn'
                    >
                      <i className='fa-solid fa-trash'></i>
                    </button>
                  )}
                </div>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("lot_number")} <b>*</b>
                    </p>
                    <input
                      type='text'
                      name={`diluentDetails[${index}].batchNo`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={diluentDetail.batchNo}
                      required
                    />
                    <span className='Input__error_fs d_none'></span>
                  </div>
                </div>
              </div>
            </div>
            <div className='VARform__column'>
              <div className='Input__row two_column'>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("no_of_boxes")} <b>*</b>
                    </p>
                    <input
                      type='number'
                      name={`diluentDetails[${index}].boxes`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={diluentDetail.boxes}
                      required
                    />
                    <span className='Input__error_fs d_none'></span>
                  </div>
                </div>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("no_Of_ampules")} <b>*</b>
                    </p>
                    <input
                      type='number'
                      name={`diluentDetails[${index}].quantity`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={diluentDetail.quantity}
                      required
                    />
                    <span className='Input__error_fs d_none'></span>
                  </div>
                </div>
              </div>
            </div>
            <div className='VARform__column'>
              <div className='Input__row two_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t("manufacturing_date")}</p>
                  <input
                    type='date'
                    name={`diluentDetails[${index}].mfgDate`}
                    className='mi_custom_textfield'
                    max={yesterday}
                    onChange={formik.handleChange}
                    value={diluentDetail.mfgDate}
                    required
                  />
                  <span className='Input__error_fs d_none'></span>
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("expiry_date")} <b>*</b>
                  </p>
                  <input
                    type='date'
                    name={`diluentDetails[${index}].expDate`}
                    className='mi_custom_textfield'
                    min={tomorrow}
                    onChange={formik.handleChange}
                    value={diluentDetail.expDate}
                    required
                  />
                  <span className='Input__error_fs d_none'></span>
                </div>
              </div>
            </div>
          </article>
        ))}
        <div className='VARgreen__btn'>
          <button
            type='button'
            onClick={addDiluentNewRow}
            className='mi_btn mi_btn_medium mi_btn_white'
          >
            <span>{t("add_new_row_button")}</span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
