import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Collapse, TablePagination, Tooltip } from "@mui/material";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import InfoIcon from "/icons/table/info.svg";
import { Link } from "react-router";
import { shipmentStatus } from "../../../../shared/common/commonFunctions";

export default function ShipmentsOutTable({
  headers,
  data,
  tab,
  page,
  setPage,
  limit,
  setLimit,
}) {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {headers.map((item, index) => (
              <TableCell key={index}>
                <FieldHead title={t(item?.title)} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'>
          {data?.data?.length > 0 ? (
            data?.data?.map((row, i) => (
              <ShipmentsOutRow
                row={row}
                key={i}
                t={t}
                headers={headers}
                tab={tab}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: "2rem" }} colSpan={6}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={data?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function ShipmentsOutRow({ row, t, headers, tab }) {
  const [statusLabel, statusColor] = shipmentStatus(row?.status, tab);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipOpen(true);
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      "This browser does not support the Clipboard API. Please use a modern browser!",
    );
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        className=' mi_table__row_clickable'
      >
        {headers.map((column, index) => (
          <React.Fragment key={index}>
            {column.key === "from" && (
              <TableCell>
                <FieldBody text={row.source.name} />
              </TableCell>
            )}
            {column.key === "shipment_date" && (
              <TableCell>
                <FieldBody
                  text={new Date(row.createdAt).toLocaleDateString()}
                />
              </TableCell>
            )}
            {column.key === "shipment_id" && (
              <TableCell>
                <FieldBody text={row.id} color='blue' />
              </TableCell>
            )}
            {column.key === "transit_no" && (
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.6rem",
                  }}
                >
                  <FieldBody text={row.transitNo} />
                  <Tooltip
                    title='Copied'
                    open={tooltipOpen}
                    leaveDelay={1000}
                    onClose={() => setTooltipOpen(false)}
                  >
                    <div
                      className='copy_icon mi_link'
                      onClick={() => copyToClipboard(row?.transitNo)}
                    >
                      <i className='fa-regular fa-copy'></i>
                    </div>
                  </Tooltip>
                </div>
              </TableCell>
            )}
            {column.key === "order_id" && (
              <TableCell>
                <FieldBody text={row?.order?.id ? row?.order?.id : "----"} />
              </TableCell>
            )}
            {column.key === "to" && (
              <TableCell>
                <FieldBody text={row.destination.name} />
              </TableCell>
            )}
            {column.key === "status" && (
              <TableCell>
                <StatusLabel status={statusLabel} tone={statusColor} />
              </TableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </React.Fragment>
  );
}
