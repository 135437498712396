import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import {
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  LabelList,
} from 'recharts';
import GraphEmpty from '../../../../shared/utils/emptyTable/GraphEmpty';
import { useMemo } from 'react';
import { stringToNiceColor } from '@/shared/utils/utils';

// Get unique statuses dynamically
const getUniqueStatuses = (data) => [
  ...new Set(data.map((item) => item.status)),
];

// Transform data to match the required chart format
const transformData = (data, statuses) => {
  return Object.values(
    data.reduce((acc, { location, status, count }) => {
      if (!acc[location]) {
        acc[location] = { location };
        statuses.forEach((s) => (acc[location][s] = 0)); // Initialize all statuses with 0
      }
      acc[location][status] = count;
      return acc;
    }, {})
  );
};

// Define colors for each status
const statusColors = {
  WORKING: '#81C784',
  OUT_OF_SERVICE: '#FFB74D',
  INTACT: '#7986CB',
  MAINTENANCE: '#64B5F6',
  REPAIRABLE: '#4DD0E1',
  NOT_REPAIRABLE: '#E57373',
  DISPOSED: '#BDBDBD',
  TRANSIT: '#A1887F',
};

const keys = [
  'WORKING',
  'OUT_OF_SERVICE',
  'INTACT',
  'MAINTENANCE',
  'REPAIRABLE',
  'NOT_REPAIRABLE',
  'DISPOSED',
  'TRANSIT',
];

const chartConfig = keys.reduce((config, key) => {
  config[key] = {
    label: key.replace('_', ' '), // Formatting label
    color: stringToNiceColor(key),
  };
  return config;
}, {});

const AssetBarChart = ({ data }) => {
  const uniqueStatuses = useMemo(() => getUniqueStatuses(data), [data]);
  const chartData = useMemo(
    () => transformData(data, uniqueStatuses),
    [data, uniqueStatuses]
  );

  return data?.length > 0 ? (
    <Card>
      <CardHeader>
        <section className='w-full flex items-center justify-between'>
          <article className='flex flex-col items-start justify-start'>
            <div className='flex gap-2 text-base font-medium leading-none'>
              Group by locations
            </div>
            {/* {date && (
              <div className='leading-none text-xs text-muted-foreground'>
                Showing total records for the last {date}
              </div>
            )} */}
          </article>
        </section>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey='location'
              tickLine={false}
              tickMargin={10}
              axisLine={false}
            />
            <YAxis tickLine={false} tickMargin={10} axisLine={false} />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator='dashed' />}
            />
            <ChartLegend content={<ChartLegendContent />} />

            {uniqueStatuses.map((status) => (
              <Bar
                key={status}
                dataKey={status}
                fill={statusColors[status] || '#607D8B'}
                name={status}
                radius={6}
              />
            ))}

            {/* {bars.map((bar, index) => (
              <Bar key={index} dataKey={bar.dataKey} fill={bar.fill} radius={6}>
                <LabelList
                  dataKey={bar.dataKey}
                  position={'top'}
                  offset={6}
                  className='fill-foreground'
                  fontSize={12}
                  content={({ value, x, y, width }) =>
                    value > 0 ? (
                      <text
                        x={x + width / 2}
                        y={y - 12}
                        fill='currentColor'
                        fontSize={12}
                        textAnchor='middle'
                        dominantBaseline='middle'
                      >
                        {value}
                      </text>
                    ) : null
                  }
                />
              </Bar>
            ))} */}
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardHeader>
        <div className='w-full flex flex-col items-start justify-start'>
          <div className='flex gap-2 font-medium leading-none'>
            {title} <TrendingUp className='h-4 w-4' />
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className='w-full flex flex-col items-center justify-center'>
          <GraphEmpty />
        </div>
      </CardContent>
    </Card>
  );
};

export default AssetBarChart;
