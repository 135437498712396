import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import "./VARform.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const VARForm1 = ({
  setFormComplete,
  setData,
  error,
  storedData,
  mainForm,
}) => {
  const validationSchema = Yup.object().shape({
    dateReceivedPre: Yup.date().required(
      "Date Received by Consignee is Required",
    ),
    dateReceivedShip: Yup.date().required(
      "Date Received by Consignee is Required",
    ),
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      dateReceivedPre:
        dayjs(storedData?.advanceNotice?.preAdviceDate).format("YYYY-MM-DD") ||
        dayjs().format("YYYY-MM-DD"),
      dateReceivedShip:
        dayjs(storedData?.advanceNotice?.shippingNotificationDate).format(
          "YYYY-MM-DD",
        ) || dayjs().format("YYYY-MM-DD"),
      CopyAwb: storedData?.advanceNotice?.copyAwb || true,
      CopyPacking: storedData?.advanceNotice?.copyPackingList || true,
      CopyInvoice: storedData?.advanceNotice?.copyInvoice || true,
      CopyReleaseCertificate:
        storedData?.advanceNotice?.copyReleaseCertificate || true,
    },
    validationSchema,
    onSubmit: (values) => {
      setData(1, values);

      if (!error) {
        navigate("?form=part2");
        setFormComplete(2);
        toast.success(t("VAR_part1_title") + " " + t("saved"));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='VARform'>
        <div className='VARform__header'>
          <h1 className='page__body_bigheading_fs'>{t("VAR_part1_title")}</h1>
        </div>
        <div className='VARform__body'>
          <div className='VARform__table'>
            <article className='VARform__row three_column'>
              <div className='VARform__column'>
                <div className='Input__column'>
                  <h2 className='page__tiny_fs mi_sky'>{t("pre_advice")} </h2>
                  <p className='Input__label_fs'>
                    {t("date_received_by_consignee")}
                    <b>*</b>
                  </p>
                  <input
                    type='date'
                    className='mi_custom_textfield date_field'
                    {...formik.getFieldProps("dateReceivedPre")}
                  />
                  {formik.touched.dateReceivedPre &&
                    formik.errors.dateReceivedPre && (
                      <span className='Input__error_fs'>
                        {formik.errors.dateReceivedPre}
                      </span>
                    )}
                </div>
                <div className='Input__column'>
                  <h2 className='page__tiny_fs mi_sky'>
                    {t("shipping_notification")}
                  </h2>
                  <p className='Input__label_fs'>
                    {t("date_received_by_consignee")} <b>*</b>
                  </p>
                  <input
                    type='date'
                    className='mi_custom_textfield date_field'
                    {...formik.getFieldProps("dateReceivedShip")}
                  />

                  {formik.touched.dateReceivedShip &&
                    formik.errors.dateReceivedShip && (
                      <span className='Input__error_fs'>
                        {formik.errors.dateReceivedShip}
                      </span>
                    )}
                </div>
              </div>

              <div className='VARform__column space_top'>
                <div className='VARform__column_center'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("copy_of_airway_bill")}(Awb)
                    </p>
                    <FormControl>
                      <RadioGroup
                        name='CopyAwb'
                        row
                        {...formik.getFieldProps("CopyAwb")}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label='Yes'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label='No'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>{t("copy_of_invoice")}</p>
                    <FormControl>
                      <RadioGroup
                        row
                        name='CopyInvoice'
                        {...formik.getFieldProps("CopyInvoice")}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label='Yes'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label='No'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className='VARform__column space_top'>
                <div className='VARform__column_center'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("copy_of_packing_list")}
                    </p>
                    <FormControl>
                      <RadioGroup
                        name='CopyPacking'
                        row
                        {...formik.getFieldProps("CopyPacking")}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label='Yes'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label='No'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("copy_of_release_certificate")}
                    </p>
                    <FormControl>
                      <RadioGroup
                        name='CopyReleaseCertificate'
                        row
                        {...formik.getFieldProps("CopyReleaseCertificate")}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label='Yes'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label='No'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className='VARform__footer'>
          <div className='Input__column_space_between_action'>
            <div>
              {error && <span className='Input__error_fs'>{error}</span>}
            </div>

            <button
              type='submit'
              className='mi_btn mi_btn_medium mi_btn_primary'
              disabled={!formik.isValid || mainForm == false}
            >
              <span>{t("save_continue")}</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VARForm1;
