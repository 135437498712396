import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  getInboundIndentData,
  getInboundSupplyData,
  getOrderGraph,
  getOutboundIndentData,
  getOutboundSupplyData,
  getShipmentGraph,
  getShipmentGraphDecision,
  getShipmentStockGraphDecision,
} from './api';

export const useGetInboundSupplyData = (data, page, limit) => {
  return useQuery({
    queryKey: ['inboundSupplyDataViz', { data, page }],
    queryFn: () => getInboundSupplyData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetOutboundSupplyData = (data, page, limit) => {
  return useQuery({
    queryKey: ['outboundSupplyDataViz', { data, page }],
    queryFn: () => getOutboundSupplyData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetInboundIndentData = (data, page, limit) => {
  return useQuery({
    queryKey: ['inboundIndentDataViz', { data, page }],
    queryFn: () => getInboundIndentData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetOutboundIndentData = (data, page, limit) => {
  return useQuery({
    queryKey: ['outboundIndentDataViz', { data, page }],
    queryFn: () => getOutboundIndentData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetOrdersGraph = (data, status, isTransfer) => {
  return useQuery({
    queryKey: ['orderGraph', { data, status, isTransfer }],
    queryFn: () => getOrderGraph(data, status, isTransfer),
  });
};

export const useGetShipmentsGraph = (data, status) => {
  return useQuery({
    queryKey: ['shipmentGraph', { data, status }],
    queryFn: () => getShipmentGraph(data, status),
  });
};

export const useGetShipmentGraphDecision = (data, status) => {
  return useQuery({
    queryKey: ['getShipmentGraphDecision', { data, status }],
    queryFn: () => getShipmentGraphDecision(data, status),
  });
};

export const useGetShipmentStockGraphDecision = (data, status) => {
  return useQuery({
    queryKey: ['getShipmentStockGraphDecision', { data, status }],
    queryFn: () => getShipmentStockGraphDecision(data, status),
  });
};
