import { useFormik } from "formik";
import * as Yup from "yup";
import "./Campaign.css";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import { createCampaign, updateCampaign } from "../../../redux/shipmentActions";
import { useEffect, useMemo, useState } from "react";
import {
  getLocations,
  getOrganizationLevel,
  getProducts,
} from "../../../redux/userActions";
import { uploadImage } from "../../../redux/imageAction";
import {
  Autocomplete,
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, Link } from "react-router";
import { useSelector } from "react-redux";

export default function AddCampaign() {
  const { theToken } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [levels, setLevels] = useState([]);
  const [locations, setLocations] = useState([]);
  const { url } = useParams();
  const editDataLocation = useLocation();
  const editingData = editDataLocation?.state?.editData;

  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts("VACCINE");
      setProducts(res.data);
      const resultLevel = await getOrganizationLevel();
      setLevels(resultLevel?.data);
    }
    fetchData();
  }, []);

  const validationSchema = Yup.object().shape({
    campaignName: Yup.string().required(t("campaign_name_error")),
    campaignDescription: Yup.string().required(t("campaign_description_error")),
    startDate: Yup.date().required(t("start_date_error")),
    endDate: Yup.date().required(t("end_date_error")),
    vaccineAdministered: Yup.string().required(t("vaccine_administered_error")),
    administrationType: Yup.string().required(t("administration_type_error")),
    locationIds: Yup.array()
      .min(1, "At least one location is required")
      .required("Location is required"),
  });

  const [editStartDate, editStartTime] = editingData?.startDate
    ? editingData?.startDate?.split("T")
    : "";
  const [editEndDate, editEndTime] = editingData?.endDate
    ? editingData?.endDate?.split("T")
    : "";

  const formik = useFormik({
    initialValues: {
      orgLevel: "",
      campaignName: editingData?.name || "",
      campaignDescription: editingData?.description || "",
      campaignLogo: "",
      startDate: editStartDate || dayjs().format("YYYY-MM-DD"),
      endDate: editEndDate || dayjs().format("YYYY-MM-DD"),
      vaccineAdministered: editingData?.productId || "",
      administrationType: editingData?.administrationType || "",
      locationIds: editingData?.locationIds || [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (url === "new") {
          const campaignLogoId = await uploadCampaignImage(imageFile);
          const apiData = {
            name: values.campaignName.toLocaleUpperCase(),
            description: values.campaignDescription,
            logoId: campaignLogoId,
            administrationType: values.administrationType,
            productId: values.vaccineAdministered,
            startDate: values.startDate,
            endDate: values.endDate,
            locationIds: values.locationIds,
          };

          const res = await createCampaign(apiData);
          if (res.success) {
            Navigate("/user/campaign/manage");
            toast.success(t("campaign_created_msg"));
          }
        }
        if (url === "edit") {
          const campaignLogoId = await uploadCampaignImage(imageFile);
          const apiData = {
            campaignId: editingData?._id,
            name: values.campaignName,
            description: values.campaignDescription,
            logoId: campaignLogoId,
            administrationType: values.administrationType,
            productId: values.vaccineAdministered,
            startDate: values.startDate,
            endDate: values.endDate,
            locationIds: values.locationIds,
            isActive: true,
          };
          const res = await updateCampaign(apiData);
          if (res.success) {
            Navigate("/user/campaign/manage");
            toast.success(t("campaign_updated_msg"));
          }
        }
      } catch (error) {
        console.error(`Campaign API Error: ${error}`);
      }
    },
  });

  const uploadCampaignImage = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const imageKey = await uploadImage(formData, theToken);
        if (imageKey?.data) {
          return imageKey?.data;
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Image upload error");
      }
    }
  };

  useEffect(() => {
    if (url === "new") {
      if (dayjs(formik.values.startDate).isAfter(formik.values.endDate)) {
        formik.setFieldValue("endDate", formik.values.startDate);
      }
    }
  }, [formik.values.startDate]);

  useEffect(() => {
    async function fetchData(level) {
      const res = await getLocations(level);
      setLocations(res?.data);
    }
    fetchData(formik?.values?.orgLevel);
  }, [formik?.values?.orgLevel]);

  const districtOptions = useMemo(
    () =>
      locations.map((district) => ({ id: district._id, name: district.name })),
    [locations],
  );

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div className='back_Link' onClick={() => Navigate(-1)}>
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>
          <h1 className='Page__headline_title_fs'>
            {url === "edit" ? t("edit") : t("new_campaign")}
          </h1>
        </div>
        <BreadCrumb
          url1='/user/campaign/manage'
          Link1={t("manage_campaign")}
          Link2={t("add_campaign")}
        />
      </div>

      <div className='AddCampaign__container'>
        <form onSubmit={formik.handleSubmit}>
          <div className='campaign_filters Input__table'>
            <div className='Input__row two_long_column'>
              <div className='Input__column'>
                <p className='Input__label_fs'>{t("org_level")}</p>
                <FormControl fullWidth>
                  <Select
                    size='small'
                    className='mui_custom_input_field'
                    {...formik.getFieldProps("orgLevel")}
                  >
                    {levels?.map((level, index) => (
                      <MenuItem key={index} value={level?.value}>
                        {level?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.errors.orgLevel && formik.touched.orgLevel && (
                  <div className='Input__error_fs'>
                    {formik.errors.orgLevel}
                  </div>
                )}
              </div>
              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("location")}
                  <b>*</b>{" "}
                </p>
                <Autocomplete
                  multiple
                  id='tags-filled'
                  size='small'
                  className='mui_custom_autocomplete'
                  options={districtOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={districtOptions.filter((option) =>
                    formik.values.locationIds.includes(option.id),
                  )}
                  disabled={!formik?.values?.orgLevel}
                  onChange={(event, value) =>
                    formik.setFieldValue(
                      "locationIds",
                      value.map((option) => option.id),
                    )
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option.name}
                        {...getTagProps({ index })}
                        key={option.id}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className='mui_custom_autocomplete'
                      size='small'
                      variant='outlined'
                      placeholder={
                        formik?.values?.locationIds?.length > 0
                          ? ""
                          : "Select locations"
                      }
                    />
                  )}
                />
                {formik.errors.locationIds && formik.touched.locationIds && (
                  <div className='Input__error_fs'>
                    {formik.errors.locationIds}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='Input__table'>
            <div className='Input__row two_column'>
              <article className='AddCampaign__form'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("campaign_name")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    id='category'
                    name='category'
                    {...formik.getFieldProps("campaignName")}
                  />
                  {formik.errors.campaignName &&
                    formik.touched.campaignName && (
                      <div className='Input__error_fs'>
                        {formik.errors.campaignName}
                      </div>
                    )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("campaign_description")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    id='category'
                    name='category'
                    {...formik.getFieldProps("campaignDescription")}
                  />
                  {formik.errors.campaignDescription &&
                    formik.touched.campaignDescription && (
                      <div className='Input__error_fs'>
                        {formik.errors.campaignDescription}
                      </div>
                    )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t("campaign_logo")}</p>
                  <input
                    type='file'
                    className='mi_custom_textfield'
                    id='campaignLogo'
                    name='campaignLogo'
                    onChange={(e) => setImageFile(e.currentTarget.files[0])}
                  />
                  {formik.errors.campaignLogo &&
                    formik.touched.campaignLogo && (
                      <div className='Input__error_fs'>
                        {formik.errors.campaignLogo}
                      </div>
                    )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("start_date")}
                    <b>*</b>
                  </p>
                  <input
                    type='date'
                    className='mi_custom_textfield'
                    id='category'
                    name='category'
                    {...formik.getFieldProps("startDate")}
                    min={dayjs().format("YYYY-MM-DD")}
                  />
                  {formik.errors.startDate && formik.touched.startDate && (
                    <div className='Input__error_fs'>
                      {formik.errors.startDate}
                    </div>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("end_date")}
                    <b>*</b>
                  </p>
                  <input
                    type='date'
                    className='mi_custom_textfield'
                    id='category'
                    name='category'
                    {...formik.getFieldProps("endDate")}
                  />
                  {formik.errors.endDate && formik.touched.endDate && (
                    <div className='Input__error_fs'>
                      {formik.errors.endDate}
                    </div>
                  )}
                </div>
              </article>
              <article className='AddCampaign__form'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("vaccine_administered")}
                    <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      {...formik.getFieldProps("vaccineAdministered")}
                      disabled={url === "edit"}
                    >
                      {products?.map((item, index) => (
                        <MenuItem key={index} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.vaccineAdministered &&
                    formik.touched.vaccineAdministered && (
                      <div className='Input__error_fs'>
                        {formik.errors.vaccineAdministered}
                      </div>
                    )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("administration-type")}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    id='category'
                    name='category'
                    {...formik.getFieldProps("administrationType")}
                  />
                  {formik.errors.administrationType &&
                    formik.touched.administrationType && (
                      <div className='Input__error_fs'>
                        {formik.errors.administrationType}
                      </div>
                    )}
                </div>
              </article>
            </div>
          </div>
          <section className='InventoryOrders__actions'>
            <div className='Page__left_actions'>
              <Link to={"/user/campaign/manage"}>
                <button className='mi_btn mi_btn_medium mi_btn_outline'>
                  <span>{t("back")}</span>
                </button>
              </Link>
              <button
                type='submit'
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <span>{url === "edit" ? t("update") : t("create")}</span>
              </button>
            </div>
          </section>
        </form>
      </div>
    </section>
  );
}
