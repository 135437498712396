import axios from 'axios';
import { API_URL } from '../../config';
import {
  buildParamsForAssetDecision,
  buildParamsWithPagination,
} from '../../utils/helper';

// Assets Data Visualization
export const getAssetsData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.getEquipmentList}`, {
      params: {
        locationIds: data.locations,
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Assets
export const addAsset = async (data) => {
  return (await axios.post(API_URL.getEquipmentList, data)).data.data;
};

// Transfer Assets
export const createAssetTransfer = async (data) => {
  return (await axios.post(API_URL.assetTransferUrl, data)).data.data;
};

// Accept Transfer Assets
export const acceptAssetTransfer = async (data) => {
  return (await axios.patch(`${API_URL.assetTransferUrl}/accept`, data)).data
    .data;
};

// Cancel Transfer Assets
export const cancelAssetTransfer = async (data) => {
  return (await axios.patch(`${API_URL.assetTransferUrl}/cancel`, data)).data
    .data;
};

//Get Transfer Assets
export const getAssetTransfer = async (page, limit) => {
  return (
    await axios.get(API_URL.assetTransferUrl, {
      params: { page: page + 1, limit: limit },
    })
  ).data.data;
};

export const getAssetAnalytics = async () => {
  return (await axios.get(`${API_URL.getEquipmentList}/analytics`)).data;
};

export const getGapAnalytics = async () => {
  return (await axios.get(`${API_URL.getEquipmentList}/gap`)).data;
};

// Assets Graph Visualization
export const getAssetsGraph = async (data) => {
  const params = buildParamsWithPagination(data);
  return (
    await axios.get(`${API_URL.getEquipmentList}/graph`, {
      params,
    })
  ).data.data;
};

// Assets Graph Visualization
export const getAssetsGraphDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(`${API_URL.getEquipmentList}/graph/decision`, {
      params,
    })
  ).data.data;
};

// Assets Graph Visualization
export const getAssetsGapGraphDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(`${API_URL.getEquipmentList}/gap/decision`, {
      params,
    })
  ).data.data;
};
