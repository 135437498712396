import React from 'react';
import OrderIcon from '/icons/all/orders/Orders.svg';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InventoryDecision from './graphModules/InventoryDecision';
import ColdchainDecision from './graphModules/ColdchainDecision';
import SupplyDecision from './graphModules/SupplyDecision';

export default function DecisionMaking() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('inventory');
  return (
    <section className='Page__main_wrapper bg-slate-50'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img src={OrderIcon} style={{ height: '22px' }} alt='Order Icon' />
          <h1 className='Page__headline_title_fs'>
            {t('decision_making_graphs')}
          </h1>
        </div>

        <Tabs
          value={currentTab}
          onValueChange={setCurrentTab}
          defaultValue='account'
        >
          <TabsList>
            <TabsTrigger value='inventory' className='px-6'>
              {t('inventory')}
            </TabsTrigger>

            <TabsTrigger value='shipments' className='px-6'>
              {t('shipments')}
            </TabsTrigger>

            <TabsTrigger value='coldchain' className='px-6'>
              {t('cold_chain')}
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {currentTab === 'inventory' && <InventoryDecision />}
      {currentTab === 'coldchain' && <ColdchainDecision />}
      {currentTab === 'shipments' && <SupplyDecision />}
    </section>
  );
}
