import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { getLocations, getOrganizationLevel } from "@/redux/userActions";
import { useGetAssetsGraphDecision } from "@/services/coldchain-services/queries";
import {
  FormControl,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  useGetExpiredDecision,
  useGetNearExpiryDecision,
  useGetWastedDecision,
  useOutOfStockGraphDecision,
} from "@/services/inventory-services/queries";
import { use } from "react";

import { Button } from "@/components/ui/button";
import OutofStocks from "../inventoryGraphs/OutofStocks";
import NearExpiry from "../inventoryGraphs/NearExpiry";
import WastedGraph from "../inventoryGraphs/WastedGraph";
import SupplyGraphs from "../supplyGraphs/SupplyGraphs";
import {
  useGetShipmentGraphDecision,
  useGetShipmentStockGraphDecision,
} from "@/services/shipment-services/queries";
import StockGraphs from "../supplyGraphs/StockGraphs";

export default function SupplyDecision() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentGraph, setCurrentGraph] = useState("Severity of Mismatch");

  const [levels, setLevels] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedOrgLevel, setSelectedOrgLevel] = useState("All Levels");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [checked, setChecked] = useState(false);
  const [sorted, setSorted] = useState(false);

  useEffect(() => {
    async function fetchLevels() {
      const resultLevel = await getOrganizationLevel();
      setLevels(resultLevel?.data || []);
    }
    fetchLevels();
  }, []);

  useEffect(() => {
    async function fetchLocations() {
      if (selectedOrgLevel) {
        const res = await getLocations(selectedOrgLevel);
        setLocations(res?.data || []);
      }
    }
    fetchLocations();
  }, [selectedOrgLevel]);

  const districtOptions = useMemo(
    () =>
      locations.map((district) => ({ id: district._id, name: district.name })),
    [locations],
  );

  const { data: shipmentData } = useGetShipmentGraphDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
    sortOrder: sorted ? "ASC" : "DESC",
  });

  const { data: stockData } = useGetShipmentStockGraphDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/user/decision-making/graphs");
  };

  return (
    <section>
      <div className='Page__action_wrapper'>
        <div className='DataVisualization__container'>
          <form
            onSubmit={handleSubmit}
            className='dataviz_filters Input__table'
          >
            <div className='Input__row four_equal_column'>
              <div className='Input__column'>
                <p className='Input__label_fs'>{t("org_level")}</p>
                <FormControl fullWidth>
                  <Select
                    size='small'
                    value={selectedOrgLevel}
                    onChange={(e) => setSelectedOrgLevel(e.target.value)}
                  >
                    <MenuItem value={"All Levels"}>All Levels</MenuItem>
                    {levels.map((level, index) => (
                      <MenuItem key={index} value={level.value}>
                        {level.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("location")} <b>*</b>
                </p>
                <Autocomplete
                  multiple
                  size='small'
                  options={districtOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={districtOptions.filter((option) =>
                    selectedLocations.includes(option.id),
                  )}
                  onChange={(event, value) =>
                    setSelectedLocations(value.map((option) => option.id))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      variant='outlined'
                      placeholder='Select locations'
                    />
                  )}
                  disabled={!selectedOrgLevel}
                />
              </div>

              <div className='mt-6'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      name='gilad'
                    />
                  }
                  label='Group by Products'
                />
              </div>

              <div className='mt-6'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sorted}
                      onChange={() => setSorted(!sorted)}
                    />
                  }
                  label='Sort the Data'
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className='flex items-center gap-4 mb-4'>
        <Button
          variant={currentGraph === "Severity of Mismatch" ? "" : "outline"}
          onClick={() => setCurrentGraph("Severity of Mismatch")}
        >
          Indent vs Supply GAP - Severity of Mismatch
        </Button>
        <Button
          variant={currentGraph === "Stock Levels" ? "" : "outline"}
          onClick={() => setCurrentGraph("Stock Levels")}
        >
          Stock Levels
        </Button>
      </div>

      {currentGraph === "Severity of Mismatch" && (
        <SupplyGraphs data={shipmentData?.data} checked={checked} />
      )}

      {currentGraph === "Stock Levels" && (
        <StockGraphs data={stockData} checked={checked} />
      )}
    </section>
  );
}
