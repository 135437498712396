import React from 'react';
import BarGraph from '../../dataVisualization/dataGraph/InvGraphs/BarGraph';

import LineGraph from '../../dataVisualization/dataGraph/InvGraphs/LineGraph';
import AreaGraph from '../../dataVisualization/dataGraph/InvGraphs/AreaGraph';

export default function OutofStocks({ outOfStockData, checked }) {
  const bars = [{ dataKey: 'totalOutOfStockDays', fill: '#4F46E5' }];
  const lines = [{ dataKey: 'totalOutOfStockDays', stoke: '#4F46E5' }];
  return (
    <div className='Graph_list grid'>
      <BarGraph
        data={
          checked
            ? outOfStockData?.data.map((item) => ({
                ...item,
                name: `${item.product} - ${item.name}`,
              }))
            : outOfStockData?.data
        }
        xAxisKey={'name'}
        bars={bars}
        title='Out of Stock Decision'
        height={200}
        chartConfig={{
          margin: { top: 20, right: 30, left: 20, bottom: 5 },
        }}
        graphType='Bars'
        setGraphType={() => {}}
        noLegends={true}
      />
      <AreaGraph
        data={
          checked
            ? outOfStockData?.data.map((item) => ({
                ...item,
                name: `${item.product} - ${item.name}`,
              }))
            : outOfStockData?.data
        }
        xAxisKey={'name'}
        areas={lines}
        title='Out of Stock Decision'
        height={200}
        chartConfig={{
          margin: { top: 20, right: 30, left: 20, bottom: 5 },
        }}
        graphType='Bars'
        setGraphType={() => {}}
        noLegends={true}
      />
    </div>
  );
}
