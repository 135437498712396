import "./UserDashboard.css";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AnalyticCard from "../../../shared/utils/analyticCard/AnalyticCard";
import IconTotalProducts from "/icons/dashboard/totalproducts.svg";
import InventorySummary from "./summaryTable/InventorySummary";
import ShipmentSummary from "./summaryTable/ShipmentSummary";

//icons
import InboundOrder from "/icons/all/orders/Outbound Orders.svg";
import OutboundOrder from "/icons/all/orders/Inbound Orders.svg";
import InboundShipment from "/icons/all/shipments/Inbound Shipment.svg";
import OutboundShipment from "/icons/all/shipments/Outbound Shipment.svg";
import TotalNoOfProducts from "/icons/all/shipments/Total No of Products.svg";
import Dashboard from "/icons/all/Dashboard.svg";

import { useSelector } from "react-redux";
import {
  getInBoundOrders,
  getOutboundOrders,
} from "../../../redux/orderActions";
import {
  getInBoundShipment,
  getOutboundShipments,
} from "../../../redux/shipmentActions";
import {
  currentInventoryStock,
  getBeneficiariesAnalytics,
  getInventoryValue,
} from "../../../redux/inventoryActions";
import { formatNumber } from "../../../shared/common/commonFunctions";
import NotificationDetails from "../../components/notificationDetails/NotificationDetails";
import { Link } from "react-router";

export default function UserDashboard() {
  const { t } = useTranslation();
  const { permissions } = useSelector((state) => state.auth);
  const [data, setData] = useState({
    beneficiaryAnalytics: null,
    inboundOrders: null,
    outboundOrders: null,
    inboundShipments: null,
    outboundShipments: null,
    inventory: null,
    inventoryValue: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          beneficiaryAnalytics,
          inboundOrdersData,
          outboundOrdersData,
          inboundShipmentsData,
          outboundShipmentsData,
          inventoryData,
          inventoryValue,
        ] = await Promise.all([
          getBeneficiariesAnalytics(),
          getInBoundOrders(),
          getOutboundOrders(),
          getInBoundShipment(),
          getOutboundShipments(),
          currentInventoryStock(),
          getInventoryValue(),
        ]);
        setData({
          beneficiaryAnalytics: beneficiaryAnalytics?.data,
          inboundOrders: inboundOrdersData?.data,
          outboundOrders: outboundOrdersData?.data,
          inboundShipments: inboundShipmentsData?.data,
          outboundShipments: outboundShipmentsData?.data,
          inventory: inventoryData?.data?.data,
          inventoryValue: inventoryValue?.data?.inStockValue,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className='Page__main'>
      <section className='Page__main_wrapper'>
        {/* <div className="Page__headline_wrapper">
          <div className="Page__headline">
            <img src={Dashboard} style={{ height: "22px" }} />
            <h1 className="Page__headline_title_fs">{t("overview")}</h1>
          </div>
        </div> */}

        <div className='Page__Analytic_list'>
          {permissions.includes("LAST_MILE") ? (
            <AnalyticCard
              state='summary'
              icon={IconTotalProducts}
              title={t("total_no_beneficiary")}
              number={formatNumber(
                data.beneficiaryAnalytics?.totalBeneficiaries || 0,
              )}
              nonClickable={true}
            />
          ) : null}
          <AnalyticCard
            state='summary'
            icon={TotalNoOfProducts}
            title={t("total_value_of_inventory")}
            number={`$ ${formatNumber(data.inventoryValue ?? 0)}`}
            nonClickable={true}
          />
          <AnalyticCard
            state='summary'
            icon={InboundShipment}
            title={t("inbound_shipments")}
            number={formatNumber(data.inboundShipments?.totalRecords ?? 0)}
            nonClickable={true}
          />
          <AnalyticCard
            state='summary'
            icon={OutboundShipment}
            title={t("outbound_shipments")}
            number={formatNumber(data.outboundShipments?.totalRecords ?? 0)}
            nonClickable={true}
          />
          <AnalyticCard
            state='summary'
            icon={InboundOrder}
            title={t("inbound_orders")}
            number={formatNumber(data.inboundOrders?.totalRecords ?? 0)}
            nonClickable={true}
          />
          <AnalyticCard
            state='summary'
            icon={OutboundOrder}
            title={t("outbound_orders")}
            number={formatNumber(data.outboundOrders?.totalRecords ?? 0)}
            nonClickable={true}
          />
        </div>
      </section>

      <div className='UserDashboard__grid_layout'>
        <div className='Dashboard_card UserDashboard__inv_summary'>
          <div className='UserDashboard__summary_card'>
            <div className='UserDashboard__summary_card_header'>
              <h1 className='page__body_heading_fs mi_white'>
                {t("inventory_summary")}
              </h1>
            </div>
            <article className='dashboard__body_wrapper'>
              <div
                className={`${
                  data?.inventory?.length > 0
                    ? "UserDashboard__summary_card_table"
                    : "UserDashboard__summary_card_table_one"
                }`}
              >
                <InventorySummary inventory={data.inventory} />
              </div>
            </article>
            <article className='dashboard__footer_wrapper'>
              <Link to='/user/inventory'>
                <button className='mi_btn mi_btn_link'>
                  <i className='fa-solid fa-eye'></i>
                  <span>{t("view_more")}</span>
                </button>
              </Link>
            </article>
          </div>
        </div>
        <div className='Dashboard_card UserDashboard__shipment_summary'>
          <div className='UserDashboard__summary_card'>
            <div className='UserDashboard__summary_card_header'>
              <h1 className='page__body_heading_fs mi_white'>
                {t("shipment_summary")}
              </h1>
            </div>
            <article className='dashboard__body_wrapper'>
              <div
                className={`${
                  data?.inventory?.length > 0
                    ? "UserDashboard__summary_card_table"
                    : "UserDashboard__summary_card_table_one"
                } only_desktop`}
              >
                <ShipmentSummary shipments={data.inboundShipments?.data} />
              </div>
              <div
                className={`${
                  data?.inventory?.length > 0
                    ? "UserDashboard__summary_card_table"
                    : "UserDashboard__summary_card_table_one"
                } only_mobile`}
              >
                <ShipmentSummary
                  ismobile={true}
                  shipments={data.inboundShipments?.data}
                />
              </div>
            </article>
            <article className='dashboard__footer_wrapper'>
              <Link to='/user/shipments'>
                <button className='mi_btn mi_btn_link'>
                  <i className='fa-solid fa-eye'></i>
                  <span>{t("view_more")}</span>
                </button>
              </Link>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}
