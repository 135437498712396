import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import { TablePagination, Tooltip } from "@mui/material";
import { shipmentStatus } from "../../../../shared/common/commonFunctions";
import PermissionRoute from "../../../../web/routes/routeLayers/PermissionRoute";

// Icons
import InfoIcon from "/icons/table/info.svg";
import TrackIcon from "/icons/table/track.svg";
import RedirectIcon from "/icons/table/redirect.svg";
import NotAcceptIcon from "/icons/table/not-accepted.svg";
import ErrorIcon from "/icons/table/error.svg";
import { Link } from "react-router";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { useTranslation } from "react-i18next";
import React from "react";

export default function ShipmentInboundTable({
  shipments,
  filteredColumns,
  pagination,
  page,
  setPage,
  limit,
  setLimit,
  currentShipment,
}) {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {/* <TableCell>
              <FieldHead title="From" />
            </TableCell>
            <TableCell>
              <FieldHead title="Shipment Date" />
            </TableCell>
            <TableCell>
              <FieldHead title="Shipment ID" />
            </TableCell>

            <TableCell>
              <FieldHead title="To" />
            </TableCell>

            <TableCell>
              <FieldHead title="Status" />
            </TableCell>
            <TableCell align="center">
              <FieldHead title="View" align="center" />
            </TableCell>
            <TableCell align="center">
              <FieldHead title="Report" align="center" />
            </TableCell>

            <TableCell align="center">
              <FieldHead title="Accept" align="center" />
            </TableCell> */}
            {filteredColumns.map((column) => (
              <TableCell key={column.key}>
                <FieldHead
                  title={t(column.title)}
                  filteredColumns={filteredColumns}
                  align={
                    column.key === "view"
                      ? "center"
                      : column.key === "report"
                      ? "center"
                      : column.key === "action"
                      ? "center"
                      : "start"
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body mi_table_collapse_body'>
          {shipments ? (
            <>
              {shipments?.length > 0 ? (
                <>
                  {shipments?.map((row, i) => (
                    <TableBodyRow
                      row={row}
                      key={i}
                      i={i}
                      filteredColumns={filteredColumns}
                      currentShipment={currentShipment}
                      t={t}
                    />
                  ))}
                </>
              ) : (
                <TableRow className='mi_table__body_No_padding'>
                  <TableCell style={{ padding: "2rem" }} colSpan={7}>
                    <EmptyTable />
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: 0 }} colSpan={7} align='center'>
                <div className='Loading_message'>
                  <p className='page__note_fs'>Loading...</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={pagination?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function TableBodyRow({ row, i, filteredColumns, currentShipment, t }) {
  const [statusLabel, statusColor] = shipmentStatus(
    row?.status,
    currentShipment,
  );

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipOpen(true);
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      "This browser does not support the Clipboard API. Please use a modern browser!",
    );
  };

  return (
    <TableRow
      key={i}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      className=' mi_table__row_clickable'
    >
      {filteredColumns.map((column) => (
        <>
          {column.key === "from" && (
            <TableCell>
              <FieldBody text={row.source.name} />
            </TableCell>
          )}
          {column.key === "shipment_date" && (
            <TableCell>
              <FieldBody text={new Date(row.createdAt).toLocaleDateString()} />
            </TableCell>
          )}
          {column.key === "shipment_id" && (
            <TableCell>
              <FieldBody text={row.id} color='blue' />
            </TableCell>
          )}
          {column.key === "transit_no" && (
            <TableCell>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}
              >
                <FieldBody text={row.transitNo} />
                <Tooltip
                  title='Copied'
                  open={tooltipOpen}
                  leaveDelay={1000}
                  onClose={() => setTooltipOpen(false)}
                >
                  <div
                    className='copy_icon mi_link'
                    onClick={() => copyToClipboard(row?.transitNo)}
                  >
                    <i className='fa-regular fa-copy'></i>
                  </div>
                </Tooltip>
              </div>
            </TableCell>
          )}
          {column.key === "order_id" && (
            <TableCell>
              <FieldBody text={row?.order?.id ? row?.order?.id : "----"} />
            </TableCell>
          )}
          {column.key === "to" && (
            <TableCell>
              <FieldBody text={row.destination.name} />
            </TableCell>
          )}
          {column.key === "status" && (
            <TableCell>
              <StatusLabel status={statusLabel} tone={statusColor} />
            </TableCell>
          )}
          {column.key === "view" && (
            <TableCell align='center'>
              <PermissionRoute allowed={"VIEW_SHIPMENT"} type='actions'>
                <Link
                  to={"/user/shipments/view-shipment/" + row._id}
                  className='Table__Icon'
                >
                  <img src={InfoIcon} alt='Icon' />
                </Link>
              </PermissionRoute>
            </TableCell>
          )}
          {/* {column.key === "report" && (
             <TableCell align="center">
             {currentTab === "damaged" ? (
                <div className="Table__Icon">
                  <img src={ErrorIcon} alt="Icon" />
                </div>
              ) : (
                <div className="Table__Icon Track__Icon">
                  <img src={TrackIcon} alt="Icon" />
                </div>
              )}
            </TableCell>
          )} */}
          {column.key === "action" && (
            <TableCell align='center'>
              {/* {currentTab === "summary" ? (
              <Link>
                {row.status == "CREATED" ? (
                  <Link to={"/user/shipments/accept-shipments/" + row._id}>
                    <div className="Table__Icon">
                      <img src={AcceptIcon} alt="Icon" />
                    </div>
                  </Link>
                ) : (
                  <div className="Table__Icon">
                    <img src={NotAcceptIcon} alt="Icon" />
                  </div>
                )}
              </Link>
            ) : currentTab === "delivered" ? (
              <div className="Table__Icon">
                <img src={NotAcceptIcon} alt="Icon" />
              </div>
            ) : (
              currentTab === "shipped" && (
                <div className="Table__Icon">
                  <img src={AcceptIcon} alt="Icon" />
                </div>
              )
            )} */}
              {row.status === "RECEIVED" ? (
                <div className='Table__Icon'>
                  <img src={NotAcceptIcon} alt='Icon' />
                </div>
              ) : (
                row.status === "CREATED" && (
                  <Link to={"/user/shipments/accept-shipments/" + row._id}>
                    <div className='Table__actions'>
                      <button className='mi_btn mi_btn_medium mi_btn_primary'>
                        <span>{t("accept_shipment")}</span>
                      </button>
                    </div>
                  </Link>
                )
              )}
            </TableCell>
          )}
        </>
      ))}
    </TableRow>
  );
}
