import React, { useState } from "react";
import { Link } from "react-router";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, Collapse } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { respondOrder } from "../../../../../redux/orderActions";
import EmptyTable from "../../../../../shared/utils/emptyTable/EmptyTable";
import StatusLabel from "../../../../../shared/utils/statusLabel/StatusLabel";
import { orderInStatus } from "../../../../../shared/common/commonFunctions";
import PermissionRoute from "../../../../../web/routes/routeLayers/PermissionRoute";
import OrderProductList from "../../../../components/orderProductList/OrderProductList";
import FieldHead from "../../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../../shared/utils/tableField/FieldBody";

export default function OrderInTable({
  orders,
  currentTab,
  setCurrentTab,
  setAnalyticUpdated,
  filteredColumns,
  pagination,
  page,
  setPage,
  limit,
  setLimit,
}) {
  const { t } = useTranslation();
  const [orderIds, setOrderIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const bulkAccept = (id) => {
    if (orderIds.includes(id) === false) {
      setOrderIds([...orderIds, id]);
    } else {
      const updatedIds = orderIds.filter((item) => item !== id);
      setOrderIds(updatedIds);
    }
  };

  const bulkAcceptReject = async (status) => {
    await respondOrder({
      orderIds: orderIds,
      status: status,
    });
    setOrderIds([]);
    setAnalyticUpdated((prev) => !prev);
  };

  const handleSelectAllClick = () => {
    if (!selectAll) {
      const newOrderIds = orders.map((order) => order._id);
      setOrderIds(newOrderIds);
    } else {
      setOrderIds([]);
    }
    setSelectAll(!selectAll);
  };

  const headAlign = (key) => {
    switch (key) {
      case "product_quantity":
        return "start";
      case "status":
        return "end";
      case "action":
        return "end";
      default:
        return "center";
    }
  };

  return (
    <TableContainer style={{ marginTop: orderIds?.length > 0 ? "3rem" : "0" }}>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className='mi_table__head'>
          <TableRow>
            <TableCell>
              {currentTab === "received" ? (
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllClick}
                  indeterminate={
                    orderIds.length > 0 && orderIds.length < orders.length
                  }
                />
              ) : null}
            </TableCell>
            {filteredColumns.map((column) => (
              <TableCell key={column.key}>
                <FieldHead
                  title={t(column.title)}
                  align={headAlign(column.key)}
                />
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body mi_table_collapse_body'>
          {orders?.length > 0 ? (
            <>
              {orders.map((row, i) => (
                <InvSummaryRow
                  row={row}
                  key={i}
                  setCurrentTab={setCurrentTab}
                  setAnalyticUpdated={setAnalyticUpdated}
                  filteredColumns={filteredColumns}
                  bulkAccept={bulkAccept}
                  orderIds={orderIds}
                  currentTab={currentTab}
                />
              ))}
            </>
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: "2rem" }} colSpan={8}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={pagination?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {orderIds.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "-1rem",
            right: "-0.5rem",
            padding: "0.5rem",
            borderRadius: "0.6rem",
          }}
        >
          <div className='Table__actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_fixed'
              onClick={() => bulkAcceptReject("REJECTED")}
            >
              <span>{"Bulk " + t("reject_button")}</span>
            </button>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={() => bulkAcceptReject("ACCEPTED")}
            >
              <span>{"Bulk " + t("accept")}</span>
            </button>
          </div>
        </div>
      )}
    </TableContainer>
  );
}

function InvSummaryRow({
  row,
  setAnalyticUpdated,
  filteredColumns,
  bulkAccept,
  orderIds,
  currentTab,
}) {
  const { t } = useTranslation();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [statusLabel, statusColor] = orderInStatus(row.status);
  const isChecked = orderIds.includes(row._id);

  const acceptReject = async (status) => {
    await respondOrder({
      orderIds: [row._id],
      status: status,
    });
    row.status = status === "ACCEPTED" ? "ACCEPTED" : "REJECTED";

    if (status === "ACCEPTED") {
      toast.success(t("order_accepted"));
      setAnalyticUpdated((prev) => !prev);
    } else if (status === "REJECTED") {
      toast.error(t("order_rejected"));
      setAnalyticUpdated((prev) => !prev);
    }
  };

  const handleToggle = () => {
    bulkAccept(row._id);
  };

  const isShipmentCreated =
    row.status === "ACCEPTED" ||
    row.status === "PARTIALLY_FULFILLED" ||
    row.status === "PARTIALLY_SHIPPED";

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className='mi_collapse_visible_row'
      >
        <TableCell>
          {currentTab === "received" ? (
            <Checkbox checked={isChecked} onChange={handleToggle} />
          ) : null}
        </TableCell>

        {filteredColumns.map((column) => (
          <React.Fragment key={column.key}>
            {column.key === "product_quantity" && (
              <TableCell align='center'>
                <div className='table__field_two_data'>
                  <FieldBody
                    text={row.products.length + " " + "Product(s)"}
                    color='bold'
                  />
                </div>
              </TableCell>
            )}
            {column.key === "order_sent_by" && (
              <TableCell align='center'>
                <FieldBody
                  text={
                    row.createdBy?.user?.firstName +
                    " " +
                    row.createdBy?.user?.lastName
                  }
                  color='bold'
                />
              </TableCell>
            )}
            {column.key === "orderId" && (
              <TableCell align='center'>
                <FieldBody text={row.id} />
              </TableCell>
            )}
            {column.key === "order_placed_on" && (
              <TableCell align='center'>
                <FieldBody
                  text={new Date(row.createdAt).toLocaleDateString()}
                />
              </TableCell>
            )}
            {column.key === "delivery_location" && (
              <TableCell align='center'>
                <FieldBody text={row.source?.name} />
              </TableCell>
            )}

            {column.key === "status" && (
              <TableCell align='right'>
                <StatusLabel status={statusLabel} tone={statusColor} />
              </TableCell>
            )}

            {column.key === "action" && (
              <TableCell>
                <div className='table__field_two_data end'>
                  {row.status === "CREATED" ? (
                    <div className='Table__actions'>
                      <button
                        className='mi_btn mi_btn_medium mi_btn_outline'
                        onClick={() => acceptReject("REJECTED")}
                      >
                        <span>{t("reject_button")}</span>
                      </button>
                      <button
                        className='mi_btn mi_btn_medium mi_btn_secondary'
                        onClick={() => acceptReject("ACCEPTED")}
                      >
                        <span>{t("accept")}</span>
                      </button>
                    </div>
                  ) : isShipmentCreated ? (
                    <PermissionRoute allowed={"CREATE_SHIPMENT"} type='actions'>
                      <Link to={"/user/shipments/new-shipments/" + row._id}>
                        <div className='Table__actions'>
                          <button className='mi_btn mi_btn_medium mi_btn_primary'>
                            <span>{t("create_shipment")}</span>
                          </button>
                        </div>
                      </Link>
                    </PermissionRoute>
                  ) : (
                    row.status === "REJECTED" && null
                  )}
                </div>
              </TableCell>
            )}
          </React.Fragment>
        ))}
        <TableCell align='center'>
          {collapseOpen ? (
            <i
              className='fa-solid fa-chevron-up table_collapse_icon mi_link'
              onClick={() => setCollapseOpen(!collapseOpen)}
            ></i>
          ) : (
            <i
              className='fa-solid fa-chevron-down table_collapse_icon mi_link'
              onClick={() => setCollapseOpen(!collapseOpen)}
            ></i>
          )}
        </TableCell>
      </TableRow>
      <TableRow className='mi_table__body_No_padding'>
        <TableCell style={{ padding: 0 }} colSpan={9}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <div className='Collapse__table'>
              <div className='Collapse__row single_column'>
                <article className='Collapse__column_list'>
                  <OrderProductList products={row.products} tab='inbound' />
                </article>

                <article className='Collapse__actions'>
                  <div className='table__field_two_data'>
                    <PermissionRoute allowed={"VIEW_ORDER"} type='actions'>
                      <Link
                        to={"/user/orders/view-order/" + row._id}
                        className='mi_btn mi_btn_medium mi_btn_outline'
                      >
                        <span>{t("view_details")}</span>
                      </Link>
                    </PermissionRoute>
                  </div>
                </article>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
