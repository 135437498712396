import React, { useEffect, useRef, useState } from "react";
import QuantityEntry from "../quantityEntry/QuantityEntry";
import "./SelectCatProduct.css";
import { Link, useLocation, useNavigate } from "react-router";
import { getProductType } from "../../../redux/orderActions";
import { getProducts } from "../../../redux/userActions";
import ProductInvTable from "./productTable/ProductInvTable";
import ProductOrdTable from "./productTable/ProductOrdTable";
import UpdateInvOrders from "../updateInvOrders/UpdateInvOrders";
import { useTranslation } from "react-i18next";

import { vaccineIcon } from "../../../shared/utils/utils";
import { APP_ENVIRONMENT } from "../../../config";
import ProductIcon from "../../../shared/utils/productIcon/ProductIcon";

export default function SelectCatProduct({
  type,
  addedOrderProducts,
  setAddedOrderProducts,
  campaignProducts,
  campaignModified,
  setCampaignModified,
}) {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState();
  const [activeProduct, setActiveProduct] = useState();
  const [productTypes, setProductTypes] = useState();
  const [products, setProducts] = useState();
  const [allProducts, setAllProducts] = useState(null);
  const [addedProducts, setAddedProducts] = useState();
  const [product, setProduct] = useState();
  const [error, setError] = useState(false);
  const params = useLocation();

  const [EditedProduct, setEditedProduct] = useState();

  React.useEffect(() => {
    async function fetchData() {
      const res = await getProductType();
      setProductTypes(res?.data);
      const result = await getProducts();
      setAllProducts(result?.data);

      if (result?.data?.length > 0) {
        setActiveProduct(result?.data[0]?.name);
        setProduct(result?.data[1]);
        setProducts(
          result?.data?.filter(
            (p) => p.type.toLowerCase() == res?.data[0]?.name?.toLowerCase(),
          ),
        );
      }
      setActiveCategory(res?.data[0]?.toLowerCase());
      setCampaignModified((prev) => !prev);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (params?.state?.products) {
      if (type == "inventory") setAddedProducts(params.state.products);
      else setAddedOrderProducts(params.state.products);
    }
  }, [params, setAddedOrderProducts, type]);

  // Active Details based on campaign
  React.useEffect(() => {
    if (campaignProducts) {
      setActiveCategory(campaignProducts?.type.toLowerCase());
      // setCampaignModified((prev) => !prev);
    }
  }, [campaignProducts, campaignModified]);

  // Active Details based on campaign
  React.useEffect(() => {
    if (campaignProducts) {
      setActiveProduct(campaignProducts?.name);
    }
  }, [campaignModified]);

  React.useEffect(() => {
    const data = allProducts?.filter(
      (p) => p.type.toLowerCase() == activeCategory,
    );
    if (data?.length > 0) {
      setProducts(data);
      // setActiveProduct(data[0].name);
      setProduct(data[0]);
    }
  }, [activeCategory, allProducts]);

  React.useEffect(() => {
    const data = allProducts?.filter(
      (p) =>
        p.type.toLowerCase() == activeCategory?.toLowerCase() &&
        p.name.toLowerCase() == activeProduct?.toLowerCase(),
    );
    if (data?.length > 0) {
      setProduct(data[0]);
    }
  }, [activeCategory, activeProduct, allProducts]);

  const filterProduct = (value) => {
    const data = allProducts?.filter(
      (p) =>
        p.name.toLowerCase().includes(value.toLowerCase()) &&
        p.type.toLowerCase() == activeCategory,
    );
    setProducts(data);
  };

  const reviewInventory = () => {
    if (addedProducts?.length > 0)
      Navigate("/user/inventory/review-inventory", {
        state: { products: addedProducts },
      });
    else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const removeProductOrders = (id) => {
    const AfterDelete = addedOrderProducts?.filter(
      (item) => item.productId !== id,
    );
    setAddedOrderProducts(AfterDelete);
  };

  const removeProductInventory = (id, batchNo) => {
    const AfterDelete = addedProducts?.filter(
      (item) => item.productId !== id || item.batchNo !== batchNo,
    );
    setAddedProducts(AfterDelete);
  };

  const myref = useRef();
  const [scrollClick, setScrollClicked] = useState(false);
  const scrollRight = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
    setScrollClicked(true);
  };

  const scrollLeft = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;

    if (myref.current.scrollLeft < 320) {
      setScrollClicked(false);
    }
  };

  const productManufactureList = products?.filter(
    (item) => item.name.toLowerCase() === activeProduct.toLowerCase(),
  );
  const manufacturerList = productManufactureList?.[0]?.manufacturers;

  const [defaultManufacturer, setDefaultManufacturer] = useState();

  useEffect(() => {
    if (manufacturerList && manufacturerList?.length > 0) {
      setDefaultManufacturer(manufacturerList && manufacturerList?.[0]?._id);
    }
  }, [manufacturerList]);

  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const handleOpenEditPopup = () => {
    setOpenEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };

  return (
    <section className='SelectCatProduct__container'>
      <div className='Page__select_tab_wrapper'>
        <div className='Page__select_tab_header'>
          <div className='Select__tab_headline'>
            <h1 className='page__body_heading_fs'>{t("product_category")}</h1>
          </div>
        </div>
        <div className='Page__select_tab_list'>
          {campaignProducts ? (
            <>
              <div className={`SelectCatTab__wrapper active`}>
                <div className='SelectCatTab__content'>
                  <span>{activeCategory}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {productTypes?.map((pt, i) => (
                <SelectCatTab
                  key={i}
                  categoryName={pt}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                />
              ))}
            </>
          )}
        </div>
      </div>

      <section className='InventoryOrders__entry_grid'>
        <div className='InventoryOrders__product_list'>
          <div className='Page__select_tab_wrapper'>
            <div className='Page__select_tab_header'>
              <div className='Select__tab_headline'>
                <h1 className='page__body_bigheading_fs all_uppers'>
                  {activeCategory}
                </h1>
                <p className='page__body_fs '>
                  {t("these_are_available_product_list")}
                </p>
              </div>
              <div className='Page__right_actions'>
                <article className='mi_search_bar'>
                  <i className='fa-solid fa-magnifying-glass'></i>
                  <input
                    type='text'
                    placeholder={t("search_by_product")}
                    onInput={(e) => filterProduct(e.target.value)}
                  />
                </article>
              </div>
            </div>
            <div className='scroll_position mi_space_top'>
              <div
                className={`Page__select_tab_list gap_large ${
                  APP_ENVIRONMENT === "epi" && activeCategory === "vaccine"
                    ? "vaccine_prods"
                    : ""
                } ${activeCategory}`}
                ref={myref}
              >
                {campaignProducts ? (
                  <>
                    {/* <div className={`SelectProdTab__wrapper active`}>
                      <div className="SelectProdTab__content">
                        <span>{activeProduct}</span>
                      </div>
                    </div> */}
                    <SelectProdTab
                      key={campaignProducts?._id}
                      type={campaignProducts?.type}
                      product={campaignProducts.name}
                      activeProduct={activeProduct}
                      setActiveProduct={setActiveProduct}
                    />
                  </>
                ) : (
                  <>
                    {products?.map((pr, i) => (
                      <SelectProdTab
                        key={i}
                        type={pr?.type}
                        product={pr.name}
                        activeProduct={activeProduct}
                        setActiveProduct={setActiveProduct}
                      />
                    ))}
                  </>
                )}
              </div>

              {activeCategory === "stationery_item" && (
                <>
                  {scrollClick && (
                    <div
                      className='scroll_btn left_btn'
                      onClick={() => scrollLeft(-300)}
                    >
                      <i className='fa-solid fa-chevron-left'></i>
                    </div>
                  )}

                  {activeCategory === "vaccine" ? null : (
                    <div
                      className='scroll_btn right_btn'
                      onClick={() => scrollRight(300)}
                    >
                      <i className='fa-solid fa-chevron-right'></i>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* <div className="SelectedProd__preview">
              <h1 className="page__tiny_fs mi_bold">Product Detailed Info:</h1>
              <div className="SelectedProd__preview_card">
                <p className="page__body_fs mi_blue">
                  Product Name:{" "}
                  {PreviewSelectedProd?.[0]?.name
                    ? PreviewSelectedProd?.[0]?.name
                    : "Nil"}
                </p>
                <p className="page__body_fs">
                  Manufacturer:{" "}
                  {PreviewSelectedProd?.[0]?.manufacturer?.name
                    ? PreviewSelectedProd?.[0]?.manufacturer?.name
                    : "Nil"}
                </p>
                <p className="page__note_fs">
                  Product ID:{" "}
                  {PreviewSelectedProd?.[0]?.id
                    ? PreviewSelectedProd?.[0]?.id
                    : "Nil"}
                </p>
              </div>
            </div> */}

            {type === "inventory" ? (
              <ProductInvTable
                products={addedProducts}
                setAddedProducts={setAddedProducts}
                removeProductInventory={removeProductInventory}
                handleOpenEditPopup={handleOpenEditPopup}
                setEditedProduct={setEditedProduct}
              />
            ) : (
              <ProductOrdTable
                products={addedOrderProducts}
                setAddedProducts={setAddedOrderProducts}
                removeProductOrders={removeProductOrders}
                handleOpenEditPopup={handleOpenEditPopup}
                setEditedProduct={setEditedProduct}
              />
            )}
          </div>
        </div>
        <div className='InventoryOrders__entry_list'>
          <QuantityEntry
            product={product}
            type={type}
            addedProducts={
              type === "inventory" ? addedProducts : addedOrderProducts
            }
            setAddedProducts={
              type === "inventory" ? setAddedProducts : setAddedOrderProducts
            }
            manufacturersList={manufacturerList}
            defaultManufacturer={defaultManufacturer}
            activeCategory={activeCategory}
          />
        </div>
      </section>

      {type === "inventory" && (
        <section className='InventoryOrders__actions'>
          <div className='Page__left_actions'>
            <Link to={"/user/inventory"}>
              <button className='mi_btn mi_btn_medium mi_btn_outline'>
                <span>{t("back")}</span>
              </button>
            </Link>

            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={reviewInventory}
            >
              <span>{t("confirm")}</span>
            </button>
          </div>
          {error && (
            <span className='Input__error_fs'>{t("select_a_product")}</span>
          )}
        </section>
      )}

      {openEditPopup && (
        <UpdateInvOrders
          keepMounted
          open={openEditPopup}
          onClose={handleCloseEditPopup}
          product={product}
          type={type}
          addedProducts={
            type === "inventory" ? addedProducts : addedOrderProducts
          }
          setAddedProducts={
            type === "inventory" ? setAddedProducts : setAddedOrderProducts
          }
          EditedProduct={EditedProduct}
          manufacturersList={manufacturerList}
        />
      )}
    </section>
  );
}

function SelectCatTab({ categoryName, activeCategory, setActiveCategory }) {
  const condition =
    categoryName?.toLowerCase() === "stationery item"
      ? "stationery_item"
      : categoryName?.toLowerCase();

  const categoryIcon = (category) => {
    switch (category) {
      case "syringe":
        return "fa-syringe";
      case "vaccine":
        return "fa-prescription-bottle-medical";
      case "stationery item":
        return "fa-box-archive";
      default:
        return null;
    }
  };

  return (
    <div
      className={`SelectCatTab__wrapper ${
        activeCategory === condition && "active"
      }`}
      onClick={() =>
        setActiveCategory(
          categoryName?.toLowerCase() === "stationery item"
            ? "stationery_item"
            : categoryName?.toLowerCase(),
        )
      }
    >
      <div className='SelectCatTab__content'>
        <i
          className={`fa-solid ${categoryIcon(categoryName?.toLowerCase())}`}
        ></i>
        <span>{categoryName}</span>
      </div>
    </div>
  );
}

function SelectProdTab({ product, activeProduct, setActiveProduct, type }) {
  return (
    <>
      {APP_ENVIRONMENT === "epi" ? (
        <>
          {type !== "STATIONERY_ITEM" ? (
            <div
              className={`vaccine__icon ${
                activeProduct === product && "active"
              }`}
              onClick={() => setActiveProduct(product)}
            >
              <ProductIcon type={type} name={product} />
            </div>
          ) : (
            <div
              className={`SelectProdTab__wrapper ${
                activeProduct === product && "active"
              }`}
              onClick={() => setActiveProduct(product)}
            >
              <div className='SelectProdTab__content'>
                <span>{product}</span>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`SelectProdTab__wrapper ${
              activeProduct === product && "active"
            }`}
            onClick={() => setActiveProduct(product)}
          >
            <div className='SelectProdTab__content'>
              <span>{product}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}
