import React from "react";

import StockGraph from "./InvGraphs/LineGraph";
import ConfigureTable from "../../../../shared/configureTable/ConfigureTable";
import { Tooltip } from "@mui/material";
import BarGraph from "./InvGraphs/BarGraph";
import { useSearchParams } from "react-router";
import { useGetShipmentsGraph } from "../../../../services/shipment-services/queries";
import {
  createBarsAndLinesConfig,
  stringToColorHex,
} from "../../../../shared/utils/utils";
import { FormControl, MenuItem, Select } from "@mui/material";
import TabContainer from "./containers/TabContainer";
import GraphContainer from "./containers/GraphContainer";
import CircularLoader from "@/shared/Loader/CircularLoader";

export default function DataShipmentGraph({
  handlePrint,
  currentTab,
  setCurrentTab,
  type,
}) {
  // States
  const [view, setView] = React.useState("grid");
  const [graphType, setGraphType] = React.useState("Bars");
  const [graphHeight, setGraphHeight] = React.useState(400);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: "sentSupply", title: "Sent Supply" },
    { key: "receivedSupply", title: "Received Supply" },
    { key: "inboundReceived", title: "Received Supply" },
    { key: "outboundReceived", title: "Received Supply" },
    { key: "inboundPartiallyReceived", title: "PartiallyReceived Supply" },
    { key: "outboundPartiallyReceived", title: "PartiallyReceived Supply" },
  ];

  const initialColumns = {
    sentSupply: true,
    receivedSupply: true,
    inboundReceived: true,
    outboundReceived: true,
    inboundPartiallyReceived: true,
    outboundPartiallyReceived: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get("locations").split(","),
    fromDate: searchParams.get("fromDate") || null,
    toDate: searchParams.get("toDate") || null,
  };

  const {
    data: createdData,
    isLoading,
    isError,
  } = useGetShipmentsGraph(apiBody, "CREATED");

  const { data: receivedData } = useGetShipmentsGraph(apiBody, "RECEIVED");
  const { data: partiallyReceivedData } = useGetShipmentsGraph(
    apiBody,
    "PARTIALLY_RECEIVED",
  );

  if (isLoading) return <>Loading...</>;

  if (isError) return <div>Error: {isError.message}</div>;

  const SupplyGraphs = [
    {
      key: "receivedSupply",
      data: createdData?.inbound,
      xAxisKey: "date",
      title: "Shipped No. of.Shipments by any given date (You sent)",
      ...createBarsAndLinesConfig(createdData?.inbound),
    },
    {
      key: "sentSupply",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Shipped No. of.Shipments by any given date (Others sent)",
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: "inboundReceived",
      data: receivedData?.inbound,
      xAxisKey: "date",
      title: "Delivered No. of Supply by any given date",
      ...createBarsAndLinesConfig(receivedData?.inbound),
    },
    {
      key: "outboundReceived",
      data: receivedData?.outbound,
      xAxisKey: "date",
      title: "Received No.of.Supply by any given date",
      ...createBarsAndLinesConfig(receivedData?.outbound),
    },
    {
      key: "inboundPartiallyReceived",
      data: partiallyReceivedData?.inbound,
      xAxisKey: "date",
      title: "Partially Delivered No .of.Supply by any given date",
      ...createBarsAndLinesConfig(partiallyReceivedData?.inbound),
    },
    {
      key: "outboundPartiallyReceived",
      data: partiallyReceivedData?.outbound,
      xAxisKey: "date",
      title: "Partially Received No Of Supply By any given date",
      ...createBarsAndLinesConfig(partiallyReceivedData?.outbound),
    },
  ];

  return (
    <div>
      <TabContainer
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        columnData={columnData}
        setColumnData={setColumnData}
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnToggle={handleColumnToggle}
        handleResetColumns={handleResetColumns}
        handleClick={handleClick}
        handlePrint={handlePrint}
        title={"Filter the Graph"}
        view={view}
        setView={setView}
        graphHeight={graphHeight}
        setGraphHeight={setGraphHeight}
        type={type}
      />

      <div className={`Graph_list ${view}`}>
        {SupplyGraphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            <GraphContainer graph={graph} graphHeight={graphHeight} />
          ) : null,
        )}
      </div>
    </div>
  );
}
