import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import { getUtilized } from "../../../../redux/inventoryActions";

export default function BeneficiarySummary({ t, tableRef, filteredColumns }) {
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const handleChangePage = React.useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = React.useCallback((event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUtilized(page, limit);
      setBeneficiariesList(result?.data);
    };
    fetchData();
  }, [page, limit]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }} ref={tableRef}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {filteredColumns.map((column, index) => (
              <TableCell key={column.key}>
                <FieldHead title={t(column.title)} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'>
          {beneficiariesList?.data?.length > 0 ? (
            beneficiariesList?.data?.map((row, i) => (
              <BenefSummaryRow
                row={row}
                key={i}
                t={t}
                filteredColumns={filteredColumns}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: "2rem" }} colSpan={6}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={beneficiariesList?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function BenefSummaryRow({ row, t, filteredColumns }) {
  const navigate = useNavigate();

  const handleOpenBatch = () => {
    navigate("/user/beneficiary/add-beneficiary", {
      state: { beneficiaries: row },
    });
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {filteredColumns.map((column) => (
        <React.Fragment key={column?.key}>
          {column.key === "vaccine" && (
            <TableCell>
              <FieldBody text={row?.productDetails?.name} />
            </TableCell>
          )}

          {column.key === "batch_no" && (
            <TableCell>
              <FieldBody text={row?.batchNo} />
            </TableCell>
          )}

          {column.key === "dose_utilized" && (
            <TableCell>
              <FieldBody text={row?.quantityUtilized} />
            </TableCell>
          )}

          {column.key === "status" && (
            <TableCell>
              <StatusLabel
                status={row.status !== "OPEN" ? "completed" : "Open"}
                tone={row.status !== "OPEN" ? "green" : "red"}
              />
            </TableCell>
          )}

          {column.key === "last_updated" && (
            <TableCell>
              <FieldBody text={new Date(row?.updatedAt).toLocaleDateString()} />
            </TableCell>
          )}

          {column.key === "action" && (
            <TableCell>
              <button
                onClick={handleOpenBatch}
                className='mi_btn mi_btn_medium mi_btn_outline'
                disabled={row.status !== "OPEN"}
              >
                <span>{t("add")} +</span>
              </button>
            </TableCell>
          )}
        </React.Fragment>
      ))}
    </TableRow>
  );
}
