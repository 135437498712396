import { TrendingUp } from 'lucide-react';
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
} from 'recharts';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

export default function InvStockGraph({ data, checked }) {
  if (!data || data.length === 0) return <p>No data available</p>;

  const chartConfig = {
    quantity: {
      label: 'Quantity',
      color: '#10b981',
    },
    minThreshold: {
      label: 'Min Threshold',
      color: '#2563eb',
    },
    maxThreshold: {
      label: 'Max Threshold',
      color: '#fda4af',
    },
  };
  return (
    <div className='Graph_list grid'>
      <Card>
        <CardHeader>
          <section className='w-full flex items-center justify-between'>
            <article className='flex flex-col items-start justify-start'>
              <div className='flex gap-2 text-base font-medium leading-none'>
                Supply vs Order Bar Chart
              </div>

              <div className='leading-none text-xs text-muted-foreground'>
                Comparison of supply and order quantities
              </div>
            </article>
          </section>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart
              accessibilityLayer
              data={
                checked
                  ? data.map((item) => ({
                      ...item,
                      name: `${item.product} - ${item.name}`,
                    }))
                  : data
              }
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='name'
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator='dashed' />}
              />
              <Bar dataKey='quantity' fill='#10b981' radius={4} />
              <Bar dataKey='minThreshold' fill='#2563eb' radius={4} />
              <Bar dataKey='maxThreshold' fill='#fda4af' radius={4} />
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <section className='w-full flex items-center justify-between'>
            <article className='flex flex-col items-start justify-start'>
              <div className='flex gap-2 text-base font-medium leading-none'>
                Supply vs Order Line Chart
              </div>

              <div className='leading-none text-xs text-muted-foreground'>
                Comparison of supply and order quantities
              </div>
            </article>
          </section>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <AreaChart
              accessibilityLayer
              data={
                checked
                  ? data.map((item) => ({
                      ...item,
                      name: `${item.product} - ${item.name}`,
                    }))
                  : data
              }
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='name'
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator='dashed' />}
              />
              <Area
                type='monotone'
                dataKey='quantity'
                stroke='var(--color-supply)'
                strokeWidth={2}
              />
              <Area
                type='monotone'
                dataKey='minThreshold'
                stroke='var(--color-order)'
                strokeWidth={2}
              />
              <Area
                type='monotone'
                dataKey='maxThreshold'
                stroke='var(--color-mismatch)'
                strokeWidth={2}
              />
            </AreaChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
}
