import { Link } from "react-router";
import VARtable from "./VARtable/VARtable";
import PARtable from "../productReport/PARtable/PARtable";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import ConfigureTable from "@/shared/configureTable/ConfigureTable";
import Export from "/icons/all/Export.svg";
import Filter from "/icons/all/Filter.svg";
import DataShadowExport from "@/shared/dataExport/DataShadowExport";
import VARExportTable from "./VARtable/VARExportTable";
import PARExportTable from "../productReport/PARtable/PARExportTable";
import { getParList, getVarList } from "@/redux/inventoryActions";

export default function VaccineArrivalReport({ currentTab }) {
  const { t } = useTranslation();

  const [data, setData] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const [columnData, setColumnData] = useState([]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openPrint, setOpenPrint] = useState(false);
  const [printData, setShipmentsPrint] = useState();

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  let initialColumns = {};
  let columns = [];

  const varColumns = [
    { key: "varid_no", title: "varid_no" },
    { key: "date_of_report", title: "date_of_report" },
    { key: "date_of_inspection", title: "date_of_inspection" },
    { key: "vaccine", title: "vaccine" },
    { key: "from", title: "from" },
    { key: "to", title: "to" },
    { key: "status", title: "status" },
    { key: "view", title: "view" },
  ];

  const varInitialColumns = {
    varid_no: true,
    date_of_report: true,
    date_of_inspection: true,
    vaccine: true,
    from: true,
    to: true,
    status: true,
    view: true,
  };

  const parColumns = [
    { key: "parid_no", title: "parid_no" },
    { key: "date_of_report", title: "date_of_report" },
    { key: "product", title: "product" },
    { key: "from", title: "from" },
    { key: "to", title: "to" },
    { key: "created_at", title: "created_at" },
    { key: "status", title: "status" },
    { key: "view", title: "view" },
  ];

  const parInitialColumns = {
    parid_no: true,
    date_of_report: true,

    product: true,
    from: true,
    to: true,
    created_at: true,
    status: true,
    view: true,
  };

  if (currentTab === "VAR") {
    initialColumns = varInitialColumns;
    columns = varColumns;
  } else if (currentTab === "PAR") {
    initialColumns = parInitialColumns;
    columns = parColumns;
  }

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);
  const [disableFilter, setDisableFilter] = useState(true);

  useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [currentTab]);

  function areAllTrue(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== true) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    const result = areAllTrue(selectedColumns);
    setDisableFilter(result);
  }, [selectedColumns]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key],
  );

  const tableRef = useRef();

  const openPrintPopup = async () => {
    if (currentTab === "VAR") {
      const res = await getVarList(0, 0);
      console.log(res);
      if (res?.success === true) {
        const data = res?.data?.data?.map((row) => ({
          varid_no: row?.id,
          date_of_report: new Date(row?.reportDate).toLocaleDateString(),
          date_of_inspection: new Date(
            row?.inspectionDateTime,
          ).toLocaleDateString(),
          vaccine: row?.shipmentDetails?.products[0]?.product?.name,
          from: row?.shipmentDetails?.products[0]?.manufacturer?.name,
          to: "CMSD",
          status: row?.status, // Map to "status"
          last_updated: new Date(row?.updatedAt).toLocaleDateString(),
        }));

        setShipmentsPrint(data);
        setOpenPrint(true);
      }
    }
    if (currentTab === "PAR") {
      const result = await getParList(0, 0);
      if (result?.success === true) {
        const data = result?.data?.data?.map((row) => ({
          parid_no: row?.id,
          date_of_report: new Date(row?.reportDate).toLocaleDateString(),
          product: row?.shipmentDetails?.products[0]?.productDetails?.name,
          from: row?.shipmentDetails?.products[0]?.manufacturer?.name,
          to: "CMSD",
          created_at: new Date(row?.createdAt).toLocaleDateString(),
          status: row?.status, // Map to "status"
        }));
        setShipmentsPrint(data);
        setOpenPrint(true);
      }
    }
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          {currentTab === "VAR" ? (
            <Link
              to='/user/arrival-report/vaccine/new'
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              <i className='fa-solid fa-plus'></i>
              <span>{t("add_vaccine_report")}</span>
            </Link>
          ) : (
            currentTab === "PAR" && (
              <Link
                to='/user/arrival-report/product/new'
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <i className='fa-solid fa-plus'></i>
                <span>{t("add_product_report")}</span>
              </Link>
            )
          )}
        </div>
        <div className='Page__right_actions'>
          <button
            className='mi_btn mi_btn_medium mi_btn_outline'
            onClick={handleClick}
          >
            {/* <i className='fa-solid fa-sliders'></i> */}
            <img src={Filter} style={{ height: "18px" }} />
            <span>{t("filter")}</span>
          </button>

          <ConfigureTable
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            value={columnData}
            setValue={setColumnData}
            columns={columns}
            selectedColumns={selectedColumns}
            onColumnToggle={handleColumnToggle}
            handleResetColumns={handleResetColumns}
            filters={disableFilter}
          />

          <button
            onClick={openPrintPopup}
            className='mi_btn mi_btn_medium mi_btn_outline'
          >
            <img src={Export} style={{ height: "18px" }} />
            <span>{t("export")}</span>
          </button>
        </div>
      </div>

      <div className='Page__table_wrapper'>
        {currentTab === "VAR" ? (
          <VARtable
            filteredColumns={filteredColumns}
            data={data}
            setData={setData}
          />
        ) : (
          currentTab === "PAR" && (
            <PARtable
              filteredColumns={filteredColumns}
              data={data}
              setData={setData}
            />
          )
        )}
      </div>

      <DataShadowExport
        open={openPrint}
        onClose={handleClosePrintPopup}
        tableComponent={
          <>
            {currentTab === "VAR" ? (
              <VARExportTable
                filteredColumns={filteredColumns}
                tableRef={tableRef}
                printData={printData}
              />
            ) : (
              <PARExportTable
                filteredColumns={filteredColumns}
                tableRef={tableRef}
                printData={printData}
              />
            )}
          </>
        }
        title='Report Details'
        printData={printData}
        currentTab={currentTab}
      />
    </section>
  );
}
