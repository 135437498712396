import axios from 'axios';
import { API_URL } from '../../config';
import { buildParamsForAssetDecision } from '@/utils/helper';

// Current Stock Data Visualization
export const getCurrentStockData = async (data, page, limit) => {
  return (
    await axios.get(API_URL.inventoryUrlv2, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Inventory Events Data Visualization
export const getInventoryEventsData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/events`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Out of Stock Data Visualization
export const getOutOfStockData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/outOfStock`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Near Expiry Data Visualization
export const getNearExpiryData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/nearExpiry`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Expired Stock Data Visualization
export const getExpiredStockData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/expired`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Rejected Stock Data Visualization
export const getRejectedStockData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/rejected`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Units Wasted Data Visualization
export const getUnitsWastedData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/wasted`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Units Wasted Data Visualization
export const getUnitsQuarantinedData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/quarantine`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// Units Wasted Data Visualization
export const getUnitsDisposedData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/disposed`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// All Inventory Products Data Visualization
export const getAllInventoryProductsData = async (data, page, limit) => {
  return (
    await axios.get(`${API_URL.getAllInventoryProducts}`, {
      params: {
        locations: JSON.stringify(data.locations),
        page: page + 1,
        limit: limit,
      },
    })
  ).data.data;
};

// All Inventory Products Data Visualization
export const getNeedForCastData = async (id) => {
  return (await axios.get(`${API_URL.needForCastUrl}/${id}`)).data.data;
};

// Dispose API
export const disposeProducts = async (data) => {
  return (await axios.post(`${API_URL.adjustmentUrl}/dispose`, data)).data.data;
};

// Data Visualization Graphs
export const getCurrentStockGraph = async (data) => {
  const params = buildParamsWithPagination(data);
  return (await axios.get(API_URL.currentStockGraph, { params })).data.data;
};

export const outOfStockGraph = async (data) => {
  const params = buildParamsWithPagination(data);
  return (
    await axios.get(`${API_URL.outOfStockInventoryUrl}/graph`, { params })
  ).data.data;
};

export const outOfStockGraphDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(`${API_URL.outOfStockInventoryUrl}/decision`, { params })
  ).data.data;
};

export const getNearExpiryDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(`${API_URL.currentInventoryStockUrl}/nearExpiry/decision`, {
      params,
    })
  ).data.data;
};

export const getExpiredDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(`${API_URL.currentInventoryStockUrl}/expired/decision`, {
      params,
    })
  ).data.data;
};

export const getWastedDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(`${API_URL.currentInventoryStockUrl}/wasted/decision`, {
      params,
    })
  ).data.data;
};

export const getStockDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(`${API_URL.currentInventoryStockUrl}/stock/decision`, {
      params,
    })
  ).data.data;
};

export const getNetUtilizationDecision = async (data) => {
  const params = buildParamsForAssetDecision(data);
  return (
    await axios.get(
      `${API_URL.currentInventoryStockUrl}/netUtilization/decision`,
      {
        params,
      }
    )
  ).data.data;
};
