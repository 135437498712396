import { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import "./ViewShipments.css";
import ShipmentDetails from "./ShipmentInfo/ShipmentDetails";
import { useParams, useNavigate, Link } from "react-router";
import { getShipmentDetails } from "../../../redux/shipmentActions";
import ShipmentProdTable from "./ShipmentMap/ShipmentProdTable";
import ShipmentMap from "./ShipmentMap/ShipmentMap";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import Printer from "../../../shared/printer/Printer";

export default function ViewShipments() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const [shipment, setShipment] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    async function fetchData() {
      const res = await getShipmentDetails(id);
      setShipment(res?.data);
      const category = [];
      const products = res?.data?.products;
      for (let index = 0; index < products?.length; index++) {
        const element = products[index].product;
        if (category.indexOf(element.type) === -1) category.push(element.type);
      }
      setCategories(category);
    }
    fetchData();
  }, [id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({ contentRef: componentRef });

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div className='back_Link' onClick={() => navigate(-1)}>
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>
          <h1 className='Page__headline_title_fs'>{t("view_shipment")} </h1>
        </div>
        <BreadCrumb
          url1='/user/shipments'
          Link1={t("shipment")}
          Link2={`${t("shipment")}: ${shipment?.id}`}
        />
      </div>

      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <button
            className='mi_btn mi_btn_medium mi_btn_outline'
            onClick={handlePrint}
          >
            <i className='fa-solid fa-download'></i>
            <span>{t("generate_pdf")}</span>
          </button>
        </div>

        {userInfo?.locationId !== shipment?.source?._id && (
          <>
            {shipment?.status === "CREATED" && (
              <Link to={"/user/shipments/accept-shipments/" + id}>
                <div className='Table__actions'>
                  <button className='mi_btn mi_btn_medium mi_btn_primary'>
                    <span>{t("accept_shipment")}</span>
                  </button>
                </div>
              </Link>
            )}
          </>
        )}
      </div>

      <section className='shipment__print' ref={componentRef}>
        <Printer title={"Shipment Info"} id={shipment?.id}>
          <section className='shipment__print_spacing'>
            <div className='ViewShipments__grid_layout'>
              <div className='ViewShipments__shipment_info'>
                <ShipmentDetails shipment={shipment} t={t} />
                {/* <ChainOfCustody shipment={shipment} t={t} /> */}
                {/* <ProdInfo shipment={shipment} categories={categories} />
          {shipment?.receivedProducts.length > 0 && (
            <ReceivedProduct shipment={shipment} />
          )} */}
              </div>
              <div className='ViewShipments__shipment_map'>
                <ShipmentMap shipment={shipment} t={t} />
              </div>
            </div>
          </section>
          <div className='ViewShipments__grid_layout_one'>
            <section className='shipment__print_spacing'>
              <div className='ViewShipments__table'>
                <h1
                  className='page__body_heading_fs'
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {t("product_list")}
                </h1>
                <ShipmentProdTable shipment={shipment} t={t} />
              </div>
            </section>
          </div>
        </Printer>
      </section>
    </section>
  );
}
