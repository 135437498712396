import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import SelectCatProduct from "../../components/selectCatProduct/SelectCatProduct";
import OrderAddressCard from "../../components/orderAddressCard/OrderAddressCard";
import OrderTransferCard from "../../components/orderAddressCard/OrderTransferCard";
import { Link, useNavigate, useLocation } from "react-router";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

export default function AddOrders() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [addedOrderProducts, setAddedOrderProducts] = useState();
  const [campaignInfo, setCampaignInfo] = useState();
  const [campaignModified, setCampaignModified] = useState(false);

  useEffect(() => {
    if (state?.campaign) {
      setCampaignInfo(state?.campaign);
    }
  }, [state?.campaign, campaignModified]);

  const validate = (values) => {
    let errors = {};
    if (!values.supplier) errors.supplier = t("required");
    if (!values.delivery) errors.delivery = t("required");
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      supplier: "",
      delivery: "",
    },
    validate,
    onSubmit: (values) => {
      const locations = {
        supplier: values.supplier,
        delivery: values.delivery,
      };

      if (addedOrderProducts?.length > 0) {
        navigate("/user/orders/review-orders", {
          state: {
            products: addedOrderProducts,
            locations: locations,
            campaignId: campaignInfo?._id,
            campaign: campaignInfo,
            isTransfer: state?.transfer,
          },
        });
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2000);
      }
    },
  });
  const reviewOrder = () => {
    if (addedOrderProducts?.length > 0) {
      navigate("/user/orders/review-orders", {
        state: {
          products: addedOrderProducts,
          campaignId: campaignInfo?._id,
          campaign: campaignInfo,
          isTransfer: state?.transfer,
        },
      });
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  return (
    <>
      {state?.transfer ? (
        <section className='Page__main_wrapper'>
          <form onSubmit={formik.handleSubmit}>
            <div className='Page__main_wrapper'>
              <div className='Page__headline_wrapper'>
                <div className='Page__headline'>
                  <div className='back_Link' onClick={() => navigate(-1)}>
                    <i className='bx bx-arrow-back icon_blue'></i>
                  </div>
                  <h1 className='Page__headline_title_fs'>
                    {` ${t("transfer_orders")}`}
                  </h1>
                </div>
                <BreadCrumb
                  url1='/user/orders'
                  Link1={`${t("orders")}`}
                  Link2={`${t("add_orders")}`}
                />
              </div>
              <SelectCatProduct
                type='order'
                addedOrderProducts={addedOrderProducts}
                setAddedOrderProducts={setAddedOrderProducts}
                campaignProducts={campaignInfo?.productDetails}
                campaignModified={campaignModified}
                setCampaignModified={setCampaignModified}
              />
              <OrderTransferCard formik={formik} />
              <section className='InventoryOrders__actions'>
                <div className='Page__left_actions'>
                  <Link to={"/user/orders"}>
                    <button className='mi_btn mi_btn_medium mi_btn_outline'>
                      <span>{t("back")}</span>
                    </button>
                  </Link>
                  <button
                    type='submit'
                    className='mi_btn mi_btn_medium mi_btn_primary'
                  >
                    <span>{t("confirm")}</span>
                  </button>
                </div>
                {error && (
                  <span className='Input__error_fs'>
                    {t("select_a_product")}
                  </span>
                )}
              </section>
            </div>
          </form>
        </section>
      ) : (
        <section className='Page__main_wrapper'>
          <div className='Page__headline_wrapper'>
            <div className='Page__headline'>
              <div className='back_Link' onClick={() => navigate(-1)}>
                <i className='bx bx-arrow-back icon_blue'></i>
              </div>
              <h1 className='Page__headline_title_fs'>{`${t("add")} ${t(
                "orders",
              )}`}</h1>
            </div>
            <BreadCrumb
              url1='/user/orders'
              Link1={`${t("orders")}`}
              Link2={`${t("add_orders")}`}
            />
          </div>

          <SelectCatProduct
            type='order'
            addedOrderProducts={addedOrderProducts}
            setAddedOrderProducts={setAddedOrderProducts}
            campaignProducts={campaignInfo?.productDetails}
            campaignModified={campaignModified}
            setCampaignModified={setCampaignModified}
          />
          <OrderAddressCard />
          <section className='InventoryOrders__actions'>
            <div className='Page__left_actions'>
              <Link to={"/user/orders"}>
                <button className='mi_btn mi_btn_medium mi_btn_outline'>
                  <span>{t("back")}</span>
                </button>
              </Link>
              <button
                onClick={reviewOrder}
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <span>{t("confirm")}</span>
              </button>
            </div>
            {error && (
              <span className='Input__error_fs'>{t("select_a_product")}</span>
            )}
          </section>
        </section>
      )}
    </>
  );
}
