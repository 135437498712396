import React from "react";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import ReviewProducts from "../../components/reviewProducts/ReviewProducts";
import "./ReviewInventory.css";
import { useLocation, useNavigate } from "react-router";
import OrderAddressCard from "../../components/orderAddressCard/OrderAddressCard";
import TransferAddressCard from "../../components/orderAddressCard/TransferAddressCard";
import { Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import { createOrder, getUserLocationInfo } from "../../../redux/orderActions";
import { useDispatch } from "react-redux";
import { callPopup, closePopup } from "../../../store/slices/popupSlice";
import { useTranslation } from "react-i18next";
import Orders from "/icons/all/orders/Orders.svg";

export default function ReviewOrders() {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);

  const [Remainder, setRemainder] = React.useState(1);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const [products, setProducts] = React.useState();

  const [locations, setLocations] = React.useState();

  const [campaignID, setCampaignID] = React.useState();
  const [campaign, setCampaign] = React.useState();

  const [reOrderCount, setReOrderCount] = React.useState(0);

  const [orderCompleted, setOrderCompleted] = React.useState(false);

  const [transferLocations, setTransferLocations] = React.useState();
  const [isTransfer, setIsTransfer] = React.useState(false);

  const [newProd, setNewProd] = React.useState();

  async function fetchData() {
    const result = await getUserLocationInfo();
    setLocations(result?.data);
  }
  React.useEffect(() => {
    fetchData();
  }, []);

  const params = useLocation();
  React.useEffect(() => {
    setTransferLocations(params.state.locations);
    setCampaignID(params.state.campaignId);
    setCampaign(params.state.campaign);
    setProducts(params.state.products);
    setIsTransfer(params.state.isTransfer);
    if (params.state.products[0].quantity != 0) {
      setReOrderCount(params.state.products[0].quantity);
    }
  }, [params]);

  const removeProduct = (id) => {
    const AfterDelete = products?.filter((item) => item.productId !== id);
    setProducts(AfterDelete);
  };

  const goBack = () => {
    if (!transferLocations?.supplier?.name) {
      Navigate("/user/orders/add-orders", {
        state: { transfer: isTransfer, products: products },
      });
    }
    if (campaign?._id) {
      Navigate("/user/orders/add-orders", {
        state: {
          transfer: isTransfer,
          products: products,
          locations: transferLocations,
          campaign: campaign,
        },
      });
    } else {
      Navigate("/user/orders/add-orders", {
        state: {
          transfer: isTransfer,
          products: products,
          locations: transferLocations,
        },
      });
    }
  };
  React.useEffect(() => {
    const NewProduct = products;
    NewProduct?.forEach((product, index) => {
      if (product?.quantity === 0 || reOrderCount > 0) {
        if (NewProduct[index].productId === newProd?.productId) {
          NewProduct[index].quantity = reOrderCount;
        }
      } else {
        NewProduct[index].quantity = product?.quantity;
      }
      setProducts(NewProduct);
    });
  }, [reOrderCount]);

  const addOrder = async () => {
    const NewProduct = [...products];
    // NewProduct.forEach((product, index) => {
    //   if (product?.quantity === 0 || reOrderCount > 0) {
    //     if(NewProduct[index].productId === newProd?.productId){
    //       NewProduct[index].quantity = reOrderCount;
    //     }
    //   } else {
    //     NewProduct[index].quantity = product?.quantity;
    //   }
    //   setProducts(NewProduct)
    // });

    const OrderData = campaignID
      ? {
          campaignId: campaignID,
          destination: transferLocations
            ? transferLocations?.supplier?._id
            : locations?.parentLocation?._id,
          source: transferLocations
            ? transferLocations?.delivery?._id
            : locations?._id,
          fromName: locations?.parentLocation?.name,
          toName: locations?.name,
          products: NewProduct,
          locations: locations,
        }
      : {
          destination: transferLocations
            ? transferLocations?.supplier?._id
            : locations?.parentLocation?._id,
          source: transferLocations
            ? transferLocations?.delivery?._id
            : locations?._id,
          fromName: locations?.parentLocation?.name,
          toName: locations?.name,
          products: NewProduct,
          locations: locations,
        };

    const res = await createOrder(OrderData);
    if (res.success) {
      dispatch(
        callPopup({
          open: true,
          type: "success",
          page: "orders",
          message: t("order_created_msg"),
          action: "/user/orders",
        }),
      );
      setOrderCompleted(true);
    }
    if (res?.data?.success === false) {
      dispatch(
        callPopup({
          open: true,
          type: "warn",
          page: "orders",
          title: res?.data?.message, // Validation
          message: res?.data?.data, // Invalid OrderID
        }),
      );
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleRemainderChange = (event) => {
    setRemainder(event.target.value);
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={Orders} className='Campaign__icon' />
            <h1 className='Page__headline_title_fs'>{t("review_orders")}</h1>
          </div>
          <BreadCrumb
            url1='/user/orders'
            Link1={t("orders")}
            Link2={`${t("add_orders")}`}
            Link3={`${t("review_orders")}`}
          />
        </div>

        <div className='Page__select_tab_wrapper'>
          <div className='Page__select_tab_header'>
            <div className='Select__tab_headline'>
              <h1 className='page__body_heading_fs'>{t("product_list")}</h1>
            </div>
          </div>
          <div className='Review__product_list'>
            {products?.map((row, i) => (
              <ReviewProducts
                key={i}
                row={row}
                i={i}
                type='order'
                products={products}
                removeProduct={removeProduct}
                reOrderCount={reOrderCount}
                setReOrderCount={setReOrderCount}
                newProd={newProd}
                setNewProd={setNewProd}
                t={t}
              />
            ))}
          </div>
        </div>

        {params.state.locations ? (
          <TransferAddressCard locations={transferLocations} />
        ) : (
          <OrderAddressCard editable={false} />
        )}

        <section className='InventoryOrders__flex_space_actions'>
          <div className='order__remainder_space'>
            <div className='order__remainder_headline'>
              <Checkbox
                sx={{ padding: 0 }}
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <h1 className='page__body_fs'>
                {t("receive_reminder_for_reorder")}
              </h1>
            </div>
            <div className='order__remainder_input'>
              <FormControl fullWidth>
                <Select
                  value={Remainder}
                  size='small'
                  className='mui_custom_input_field'
                  onChange={handleRemainderChange}
                >
                  <MenuItem value={1}>1 {t("month")}</MenuItem>
                  <MenuItem value={2}>2 {t("month")}</MenuItem>
                  <MenuItem value={3}>3 {t("month")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='Page__left_actions'>
            {orderCompleted ? (
              <button
                onClick={() => Navigate("/user/orders")}
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <span>{t("go_to_orders")} </span>
              </button>
            ) : (
              <>
                {products?.[0]?.fromPage === "ReOrder" ? (
                  <button
                    className='mi_btn mi_btn_medium mi_btn_outline'
                    onClick={() => Navigate(-1)}
                  >
                    <span>{t("back")}</span>
                  </button>
                ) : (
                  <button
                    className='mi_btn mi_btn_medium mi_btn_outline'
                    onClick={goBack}
                  >
                    <span>{t("back")}</span>
                  </button>
                )}

                <button
                  onClick={addOrder}
                  className='mi_btn mi_btn_medium mi_btn_primary'
                >
                  <span>{t("confirm")}</span>
                </button>
              </>
            )}
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}
