import { useEffect, useState } from "react";
import MainVAR from "./mainVAR/MainVAR";
import "./VaccineArrivalReport.css";
import VARform1 from "./formVAR/VARform1";
import { Tooltip } from "@mui/material";
import VARform2 from "./formVAR/VARform2";
import VARform3 from "./formVAR/VARform3";
import VARform4 from "./formVAR/VARform4";
import VARform5 from "./formVAR/VARform5";
import VARform6 from "./formVAR/VARform6";
import VARform7 from "./formVAR/VARform7";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import { getProducts, getCountries } from "../../../redux/userActions";
import { useLocation, useNavigate, useSearchParams } from "react-router";
import toast from "react-hot-toast";
import { getInventories } from "../../../redux/inventoryActions";
import { useTranslation } from "react-i18next";
import VaccineReport from "/icons/all/Vaccine Report.svg";

export default function CreateVAR() {
  const { t } = useTranslation();
  const params = useLocation();
  const EditVARData = params?.state?.value;
  const [searchParams, setSearchParams] = useSearchParams();

  const formTab = searchParams.get("form");

  // const [formTab, setFormTab] = useState("part1");
  const [formComplete, setFormComplete] = useState(1);
  const [products, setProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [mainForm, setMainForm] = useState(false);

  const [error, setError] = useState(t("VAR_form_error"));
  const reportNum =
    "VAR-BANA-" +
    new Date().getFullYear() +
    (new Date().getMonth() + 1) +
    (new Date().getDate() + 1) +
    "-" +
    new Date().getHours() +
    new Date().getMinutes() +
    "-" +
    new Date().getSeconds();

  const [nData, setNData] = useState(EditVARData || {});
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts("VACCINE");
      setProducts(res.data);
      const re = await getCountries();
      setCountries(re.data);
    }
    fetchData();
  }, []);

  let CurrentCountry;

  if (nData?.country) {
    CurrentCountry = countries?.filter(
      (country) => country?.name === nData?.country,
    );
  }

  const [storageInfo, setStorageInfo] = useState();

  // Getting Storage Info
  useEffect(() => {
    const fetchData = async () => {
      const data = await getInventories();
      setStorageInfo(data?.data);
    };
    fetchData();
  }, []);

  const setData = (form, data) => {
    const saveData = { ...nData };

    if (form == 0) {
      saveData.inventoryId = storageInfo?.[0]._id;
      saveData.product = data.vaccine;
      saveData.country = data.country;
      saveData.reportNo = data.report_no;
      saveData.reportDate = data.report_date;
      saveData.placeOfInspection = data.place_of_inception;
      saveData.inspectionDateTime =
        data.date_of_inspection + "T" + data.time_of_inspection + ":00.552Z";
      saveData.storageName = data.cold_storage;

      setError("");
    } else if (form === 1) {
      if (saveData.product) {
        setError("");
        saveData.advanceNotice = {
          preAdviceDate: data.dateReceivedPre,
          shippingNotificationDate: data.dateReceivedShip,
          copyAwb: data.CopyAwb,
          copyInvoice: data.CopyInvoice,
          copyPackingList: data.CopyPacking,
          copyReleaseCertificate: data.CopyReleaseCertificate,
        };
      } else {
        setError(t("VAR_form_error"));
      }
    } else if (form == 2) {
      saveData.flightArrivalReport = {
        awb: data.awb,
        flightNo: data.flight,
        destination: data.destination,
        scheduledDateTime: data.etaDate + "T" + data.etaTime + ":00.552Z",
        actualDateTime: data.arrivalDate + "T" + data.arrivalTime + ":00.552Z",
        clearingAgent: {
          name: data.clearingAgent,
          behalf: data.behalfOf,
        },
      };
    } else if (form == 3) {
      const prodsInv = [];
      const diluent = [];

      for (let i = 0; i < data?.vaccineDetails?.length; i++) {
        prodsInv.push({
          productId: saveData?.product,
          manufacturerId: data?.manufacture, // manufacturerId
          volumePerUnit: data?.volume,
          noOfUnits: parseInt(data?.vaccineDetails[i].quantity),
          quantityPerUnit: data?.vaccineDetails[i].qpUnit,
          mfgDate: data?.vaccineDetails[i].mfgDate,
          expDate: data?.vaccineDetails[i].expDate,
          batchNo: data?.vaccineDetails[i].batchNo,
          noOfBoxes: parseInt(data?.vaccineDetails[i].boxes),
        });
      }

      for (let i = 0; i < data?.diluentDetails?.length; i++) {
        diluent.push({
          productId: saveData?.product,
          manufacturerId: data?.manufacture, // manufacturerId
          noOfUnits: parseInt(data?.diluentDetails[i].quantity),
          quantityPerUnit: data?.diluentDetails[i].qpUnit,
          mfgDate: data?.diluentDetails[i].mfgDate,
          expDate: data?.diluentDetails[i].expDate,
          batchNo: data?.diluentDetails[i].batchNo,
          noOfBoxes: parseInt(data?.diluentDetails[i].boxes),
        });
      }

      saveData.shipmentDetails = {
        poNo: data.po,
        Consignee: data.consignee,
        originCountry: data?.Country,
        vaccineType: data.description,
        shortShipmentNotification: data.Question1,
        shortShipment: data.Question2,
        comments: data.comment,
        products: prodsInv,
        diluentDroppers: diluent,
        qtyPerUnit: data.qtyPerUnit,
        manufacturerInfo: data?.manufacture,
      };
      saveData.vaccineInfo = data?.vaccineInfo;
    } else if (form == 4) {
      saveData.documents = data;
    } else if (form == 5) {
      saveData.shippingIndicators = data;
    } else if (form == 6) {
      saveData.conditions = data;
    } else if (form == 7) {
      saveData.signatures = data;
    } else if (form == 8) {
      if (saveData?.shipmentDetails?.diluentDroppers) {
        if (
          saveData.product !== "657896a7ddb839656f4a5f89" &&
          saveData.product !== "65789738ddb839656f4a5fc2"
        ) {
          saveData.shipmentDetails.diluentDroppers = [];
        }
      }
      if (
        nData?.signatures?.inspectionSupervisor?.date &&
        nData?.signatures?.receivedDate &&
        nData?.signatures?.contact &&
        nData?.signatures?.epiManager?.date
      ) {
        navigate("/user/arrival-report/vaccine/review", {
          state: { value: saveData },
        });
        setMainForm(false);
        toast.success(t("VAR_completed_review_msg"));
      } else {
        toast.error(t("please_save_form_msg"));
      }
    }
    setNData(saveData);
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img src={VaccineReport} className='Sidebar__icon' />
          <h1 className='Page__headline_title_fs'>
            {t("vaccine_arrival_report")}
          </h1>
        </div>
        <BreadCrumb
          url1='/user/arrival-report/vaccine'
          Link1={t("vaccine_arrival_report")}
          Link2={t("create_VAR")}
        />
      </div>
      <div className='Page__report_wrapper'>
        <MainVAR
          products={products}
          countries={countries}
          reportNum={reportNum}
          setMainFormData={setData}
          storedData={nData}
          setNData={setNData}
          setMainForm={setMainForm}
        />
      </div>
      {formTab && (
        <div className='VaccineArrivalReport__timeline_wrapper'>
          <div className='VaccineArrivalReport__timeline'>
            <div
              className={`TimelineCard ${formComplete > 1 && "done"}`}
              onClick={() => {
                navigate("?form=part1");
              }}
            >
              <TimeDot
                tooltip={t("VAR_part1_title")}
                status={
                  formTab === "part1"
                    ? "progress"
                    : formComplete > 1
                    ? "done"
                    : ""
                }
              />
            </div>
            <div
              className={`TimelineCard ${formComplete > 2 && "done"}`}
              onClick={() => {
                if (formComplete > 1) {
                  navigate("?form=part2");
                }
              }}
            >
              <TimeDot
                tooltip={t("VAR_part2_title")}
                status={
                  formTab === "part2"
                    ? "progress"
                    : formComplete > 2
                    ? "done"
                    : ""
                }
              />
            </div>
            <div
              className={`TimelineCard ${formComplete > 3 && "done"}`}
              onClick={() => {
                if (formComplete > 2) {
                  navigate("?form=part3");
                }
              }}
            >
              <TimeDot
                tooltip={t("VAR_part3_title")}
                status={
                  formTab === "part3"
                    ? "progress"
                    : formComplete > 3
                    ? "done"
                    : ""
                }
              />
            </div>
            <div
              className={`TimelineCard ${formComplete > 4 && "done"}`}
              onClick={() => {
                if (formComplete > 3) {
                  navigate("?form=part4");
                }
              }}
            >
              <TimeDot
                tooltip={t("VAR_part4_title")}
                status={
                  formTab === "part4"
                    ? "progress"
                    : formComplete > 4
                    ? "done"
                    : ""
                }
              />
            </div>

            <div
              className={`TimelineCard ${formComplete > 5 && "done"}`}
              onClick={() => {
                if (formComplete > 4) {
                  navigate("?form=part5");
                }
              }}
            >
              <TimeDot
                tooltip={t("VAR_part5_title")}
                status={
                  formTab === "part5"
                    ? "progress"
                    : formComplete > 5
                    ? "done"
                    : ""
                }
              />
            </div>
            <div
              className={`TimelineCard ${formComplete > 6 && "done"}`}
              onClick={() => {
                if (formComplete > 5) {
                  navigate("?form=part6");
                }
              }}
            >
              <TimeDot
                tooltip={t("VAR_part6_title")}
                status={
                  formTab === "part6"
                    ? "progress"
                    : formComplete > 6
                    ? "done"
                    : ""
                }
              />
            </div>
            <div
              className={`TimelineCard VAR_Complete ${
                formComplete > 7 && "done"
              }`}
              onClick={() => {
                if (formComplete > 6) {
                  navigate("?form=part7");
                }
              }}
            >
              <TimeDot
                tooltip={t("VAR_part7_title")}
                status={
                  formTab === "part7"
                    ? "progress"
                    : formComplete > 7
                    ? "done"
                    : ""
                }
              />
              <div className='VAR_Complete__icon'>
                <Tooltip title={t("review_form")} arrow>
                  <span>
                    <i className='fa-solid fa-clipboard-check mi_white'></i>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='VaccineArrivalReport__forms'>
        {formTab === "part1" && (
          <VARform1
            setFormComplete={setFormComplete}
            setData={setData}
            error={error}
            storedData={nData}
            mainForm={mainForm}
          />
        )}
        {formTab === "part2" && (
          <VARform2
            setFormComplete={setFormComplete}
            setData={setData}
            storedData={nData}
            currentCountry={CurrentCountry}
          />
        )}
        {formTab === "part3" && (
          <VARform3
            setFormComplete={setFormComplete}
            setData={setData}
            countries={countries}
            storedData={nData}
            products={products}
          />
        )}
        {formTab === "part4" && (
          <VARform4
            setFormComplete={setFormComplete}
            setData={setData}
            reportNum={reportNum}
            storedData={nData}
          />
        )}
        {formTab === "part5" && (
          <VARform5
            setFormComplete={setFormComplete}
            setData={setData}
            storedData={nData}
          />
        )}
        {formTab === "part6" && (
          <VARform6
            setFormComplete={setFormComplete}
            setData={setData}
            storedData={nData}
          />
        )}
        {formTab === "part7" && (
          <VARform7
            setFormComplete={setFormComplete}
            setData={setData}
            storedData={nData}
          />
        )}
      </div>
    </section>
  );
}

function TimeDot({ status, tooltip }) {
  return (
    <Tooltip title={tooltip} arrow>
      <div className={`TimeDot ${status && status}`}></div>
    </Tooltip>
  );
}
