import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "../../../../shared/utils/breadCrumb/BreadCrumb";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { createTemperature } from "../../../../redux/inventoryActions";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import dayjs from "dayjs";
import AlarmData from "./AlarmData";

const validationSchema = Yup.object({
  reportingDate: Yup.date().required("Required"),
  reportingTime: Yup.string().required("Required"),
  facilityId: Yup.string().required("Required"),
  refrigeratorId: Yup.string().required("Required"),
  ft2DataDownloaded: Yup.boolean().required("Required"),
  temperature1: Yup.number().required("Required"),
  ambientTemperature: Yup.number().required("Required"),
  humidity: Yup.number().required("Required"),
});

export default function FunctionalData() {
  const navigate = useNavigate();
  const assetState = useLocation();
  const assetData = assetState?.state?.asset;
  const formik = useFormik({
    initialValues: {
      reportingDate: dayjs().format("YYYY-MM-DD"),
      reportingTime: dayjs().format("HH:mm"),
      facilityId: assetData?.model || "",
      refrigeratorId: assetData?.id || "",
      ft2DataDownloaded: true,
      temperature1: "",
      ambientTemperature: "",
      humidity: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const apiData = {
        assetId: assetData?._id,
        timestamp: dayjs(values.reportingDate)
          .hour(values.reportingTime.split(":")[0])
          .minute(values.reportingTime.split(":")[1]),
        temperatures: [values.temperature1],
        ambientTemperature: values.ambientTemperature,
        humidity: values.humidity,
      };

      const res = await createTemperature(apiData);

      if (res?.success == true) {
        toast.success("Temperature Updated");
        navigate("/user/cold-chain");
      } else {
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <i className='bx bx-box'></i>
          <h1 className='Page__headline_title_fs'>Historical Data Form</h1>
        </div>
        <BreadCrumb
          url1='/user/cold-chain'
          Link1='Cold Chain'
          Link2='View Function Status'
        />
      </div>
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <button className='mi_btn mi_btn_medium mi_btn_secondary bg_green'>
            <i className='fa-solid fa-plus'></i>
            <span>Download</span>
          </button>
          <button className='mi_btn mi_btn_medium mi_btn_outline'>
            <i className='fa-solid fa-download'></i>
            <span>Import</span>
          </button>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>Report Information</h1>
            </div>
            <div className='FunctionalData__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Reporting Period Date</p>

                  <input
                    type='date'
                    {...formik.getFieldProps("reportingDate")}
                    className='mi_custom_textfield'
                  />
                  {formik.touched.reportingDate &&
                  formik.errors.reportingDate ? (
                    <div className='Input__error_fs'>
                      {formik.errors.reportingDate}
                    </div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Reporting Time</p>
                  <input
                    type='time'
                    {...formik.getFieldProps("reportingTime")}
                    className='mi_custom_textfield'
                  />
                  {formik.touched.reportingTime &&
                  formik.errors.reportingTime ? (
                    <div className='Input__error_fs'>
                      {formik.errors.reportingTime}
                    </div>
                  ) : null}
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>Asset Information</h1>
            </div>
            <div className='FunctionalData__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Model</p>
                  <h1>{formik?.values?.facilityId}</h1>
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Refrigerator ID</p>
                  <h1>{formik?.values?.refrigeratorId}</h1>
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    FT2 Data: Were the data downloaded?
                  </p>
                  <FormControl>
                    <RadioGroup
                      row
                      {...formik.getFieldProps("ft2DataDownloaded")}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                    {formik.touched.ft2DataDownloaded &&
                    formik.errors.ft2DataDownloaded ? (
                      <div className='Input__error_fs'>
                        {formik.errors.ft2DataDownloaded}
                      </div>
                    ) : null}
                  </FormControl>
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>Functional Status</h1>
            </div>
            <div className='FunctionalData__body extra__bottom_space'>
              <section className='FunctionalData__row_group'>
                <div className='FunctionalData__row three_column'>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>Temperature</p>
                    <input
                      type='number'
                      placeholder='Enter here...'
                      {...formik.getFieldProps("temperature1")}
                      className='mi_custom_textfield'
                    />
                    {formik.touched.temperature1 &&
                    formik.errors.temperature1 ? (
                      <div className='Input__error_fs'>
                        {formik.errors.temperature1}
                      </div>
                    ) : null}
                  </article>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>Ambient Temperature</p>
                    <input
                      type='number'
                      placeholder='Enter here...'
                      {...formik.getFieldProps("ambientTemperature")}
                      className='mi_custom_textfield'
                    />
                    {formik.touched.ambientTemperature &&
                    formik.errors.ambientTemperature ? (
                      <div className='Input__error_fs'>
                        {formik.errors.ambientTemperature}
                      </div>
                    ) : null}
                  </article>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>Humidity</p>
                    <input
                      type='number'
                      placeholder='Enter here...'
                      {...formik.getFieldProps("humidity")}
                      className='mi_custom_textfield'
                    />
                    {formik.touched.humidity && formik.errors.humidity ? (
                      <div className='Input__error_fs'>
                        {formik.errors.humidity}
                      </div>
                    ) : null}
                  </article>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className='FunctionalData__actions mr-5'>
          <button type='submit' className='mi_btn mi_btn_medium mi_btn_primary'>
            <span>Submit Temperature</span>
          </button>
        </div>
      </form>

      <AlarmData assetData={assetData} />
    </section>
  );
}
