import React from "react";
import {
  doseUtilizedData,
  noOfBeneficiariesData,
  stockAvailabilityData,
  stockOutData,
} from "../data";
import StockGraph from "./InvGraphs/LineGraph";
import ConfigureTable from "../../../../shared/configureTable/ConfigureTable";
import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import BarGraph from "./InvGraphs/BarGraph";
import { useGetOrdersGraph } from "../../../../services/shipment-services/queries";
import { useSearchParams } from "react-router";
import {
  createBarsAndLinesConfig,
  stringToColorHex,
  stringToNiceColor,
} from "../../../../shared/utils/utils";
import GraphContainer from "./containers/GraphContainer";
import TabContainer from "./containers/TabContainer";
import CircularLoader from "@/shared/Loader/CircularLoader";

export default function DataOrderGraph({
  handlePrint,
  currentTab,
  setCurrentTab,
  type,
}) {
  // States
  const [view, setView] = React.useState("grid");
  const [graphHeight, setGraphHeight] = React.useState(400);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const columns = [
    { key: "sentOrders", title: "Sent Indent" },
    { key: "receivedOrders", title: "Received Indent" },
    { key: "acceptedInboundOrders", title: "Your Indent Accepted" },
    { key: "acceptedOutboundOrders", title: "Indents You Accepted" },
    { key: "cancelledInboundOrders", title: "Your Cancelled Indent" },
    { key: "cancelledOutboundOrders", title: "Others Cancelled Indent" },
    { key: "rejectedInboundOrders", title: "Your Indent Rejected" },
    { key: "rejectedOutboundOrders", title: "Indents You Rejected" },
    { key: "shippedInboundOrders", title: "Your Indent Shipped" },
    { key: "shippedOutboundOrders", title: "Indents You Shipped" },
    { key: "fulfilledInboundOrders", title: "Your Indent Fulfilled" },
    { key: "fulfilledOutboundOrders", title: "Indents You Fulfilled" },
    {
      key: "partiallyFulfilledInboundOrders",
      title: "Your Indent Partially Fulfilled",
    },
    {
      key: "partiallyFulfilledOutboundOrders",
      title: "Indents You Partially Fulfilled",
    },
    { key: "transferInboundOrders", title: "You Sent (Transfer)" },
    { key: "transferOutboundOrders", title: "You Received (Transfer)" },
  ];

  const initialColumns = {
    sentOrders: true,
    receivedOrders: true,
    acceptedInboundOrders: true,
    acceptedOutboundOrders: true,
    cancelledInboundOrders: true,
    cancelledOutboundOrders: true,
    rejectedInboundOrders: true,
    rejectedOutboundOrders: true,
    shippedInboundOrders: true,
    shippedOutboundOrders: true,
    fulfilledInboundOrders: true,
    fulfilledOutboundOrders: true,
    partiallyFulfilledInboundOrders: true,
    partiallyFulfilledOutboundOrders: true,
    transferInboundOrders: true,
    transferOutboundOrders: true,
  };

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);
  const [columnData, setColumnData] = React.useState([]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.key],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get("locations")?.split(",") || [],
    fromDate: searchParams.get("fromDate") || null,
    toDate: searchParams.get("toDate") || null,
  };

  const {
    data: createdData,
    isLoading,
    isError,
  } = useGetOrdersGraph(apiBody, "CREATED");

  const { data: acceptedData } = useGetOrdersGraph(apiBody, "ACCEPTED");
  const { data: rejectedData } = useGetOrdersGraph(apiBody, "REJECTED");
  const { data: cancelledData } = useGetOrdersGraph(apiBody, "CANCELLED");
  const { data: shippedData } = useGetOrdersGraph(apiBody, "SHIPPED");
  const { data: fulfilledData } = useGetOrdersGraph(apiBody, "FULFILLED");
  const { data: partiallyFulfilledData } = useGetOrdersGraph(
    apiBody,
    "PARTIALLY_FULFILLED",
  );

  const { data: transferData } = useGetOrdersGraph(apiBody, null, true);

  const OrderGraphs = [
    {
      key: "sentOrders",
      data: createdData?.outbound,
      xAxisKey: "date",
      title: "Sent Indents",
      ...createBarsAndLinesConfig(createdData?.outbound),
    },
    {
      key: "receivedOrders",
      data: createdData?.inbound,
      xAxisKey: "date",
      title: "Received Indents",
      ...createBarsAndLinesConfig(createdData?.inbound),
    },
    {
      key: "acceptedInboundOrders",
      data: acceptedData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Accepted",
      ...createBarsAndLinesConfig(acceptedData?.inbound),
    },
    {
      key: "acceptedOutboundOrders",
      data: acceptedData?.outbound,
      xAxisKey: "date",
      title: "Indents You Accepted",
      ...createBarsAndLinesConfig(acceptedData?.outbound),
    },
    {
      key: "rejectedInboundOrders",
      data: rejectedData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Rejected",
      ...createBarsAndLinesConfig(rejectedData?.inbound),
    },
    {
      key: "rejectedOutboundOrders",
      data: rejectedData?.outbound,
      xAxisKey: "date",
      title: "Indents You Rejected",
      ...createBarsAndLinesConfig(rejectedData?.outbound),
    },
    {
      key: "cancelledInboundOrders",
      data: cancelledData?.inbound,
      xAxisKey: "date",
      title: "Your Cancelled Indent",
      ...createBarsAndLinesConfig(cancelledData?.inbound),
    },
    {
      key: "cancelledOutboundOrders",
      data: cancelledData?.outbound,
      xAxisKey: "date",
      title: "Others Cancelled Indent",
      ...createBarsAndLinesConfig(cancelledData?.outbound),
    },
    {
      key: "shippedInboundOrders",
      data: shippedData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Shipped",
      ...createBarsAndLinesConfig(shippedData?.inbound),
    },
    {
      key: "shippedOutboundOrders",
      data: shippedData?.outbound,
      xAxisKey: "date",
      title: "Indents You Shipped",
      ...createBarsAndLinesConfig(shippedData?.outbound),
    },
    {
      key: "fulfilledInboundOrders",
      data: fulfilledData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Fulfilled",
      ...createBarsAndLinesConfig(fulfilledData?.inbound),
    },
    {
      key: "fulfilledOutboundOrders",
      data: fulfilledData?.outbound,
      xAxisKey: "date",
      title: "Indents You Fulfilled",
      ...createBarsAndLinesConfig(fulfilledData?.outbound),
    },
    {
      key: "partiallyFulfilledInboundOrders",
      data: partiallyFulfilledData?.inbound,
      xAxisKey: "date",
      title: "Your Indent Partially Fulfilled",
      ...createBarsAndLinesConfig(partiallyFulfilledData?.inbound),
    },
    {
      key: "partiallyFulfilledOutboundOrders",
      data: partiallyFulfilledData?.outbound,
      xAxisKey: "date",
      title: "Indents You Partially Fulfilled",
      ...createBarsAndLinesConfig(partiallyFulfilledData?.outbound),
    },
    {
      key: "transferInboundOrders",
      data: transferData?.inbound,
      xAxisKey: "date",
      title: "You Sent (Transfer)",
      ...createBarsAndLinesConfig(transferData?.inbound),
    },
    {
      key: "transferOutboundOrders",
      data: transferData?.outbound,
      xAxisKey: "date",
      title: "You Received (Transfer)",
      ...createBarsAndLinesConfig(transferData?.outbound),
    },
  ];

  if (isLoading) return <>Loading...</>;

  if (isError) return <div>Error: {isError.message}</div>;
  return (
    <div>
      <TabContainer
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        columnData={columnData}
        setColumnData={setColumnData}
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnToggle={handleColumnToggle}
        handleResetColumns={handleResetColumns}
        handleClick={handleClick}
        handlePrint={handlePrint}
        title={"Filter the Graph"}
        view={view}
        setView={setView}
        graphHeight={graphHeight}
        setGraphHeight={setGraphHeight}
        type={type}
      />

      <div className={`Graph_list ${view}`}>
        {OrderGraphs.map((graph) =>
          filteredColumns.some((col) => col.key === graph.key) ? (
            <GraphContainer graph={graph} graphHeight={graphHeight} />
          ) : null,
        )}
      </div>
    </div>
  );
}
