import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';

export default function FilterBar({
  formik,
  levels,
  districtOptions,
  currentTab,
  filterRef,
  setIsOpen,
}) {
  const { t } = useTranslation();
  return (
    <section className='w-[30rem] h-screen bg-white p-4 fixed top-0 right-0 z-50'>
      <div className='flex items-center justify-between'>
        <h1 className='text-lg font-medium text-black'>Filters</h1>

        <div className='cursor-pointer' onClick={() => setIsOpen(false)}>
          <i className='fa-solid fa-xmark text-black'></i>
        </div>
      </div>
      <div className='py-2'>
        <p className='text-sm text-gray-500'>
          Please select the org level and location from the following list
        </p>
      </div>
      <div className='Page__action_wrapper'>
        <div className='DataVisualization__container'>
          <form onSubmit={formik.handleSubmit}>
            <div className='dataviz_filters Input__table'>
              <div className='relative z-[999] flex flex-col gap-3'>
                <div className='w-full Input__column'>
                  <p className='Input__label_fs'>{t('org_level')}</p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_big_field'
                      {...formik.getFieldProps('orgLevel')}
                    >
                      {levels?.map((level, index) => (
                        <MenuItem key={index} value={level?.value}>
                          {level?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.orgLevel && formik.touched.orgLevel && (
                    <div className='Input__error_fs'>
                      {formik.errors.orgLevel}
                    </div>
                  )}
                </div>
                <div className='w-full Input__column'>
                  <p className='Input__label_fs'>
                    {t('location')}
                    <b>*</b>{' '}
                  </p>
                  <Autocomplete
                    multiple
                    id='tags-filled'
                    size='small'
                    className='mui_custom_autocomplete'
                    options={districtOptions}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={districtOptions.filter((option) =>
                      formik.values.locations.includes(option.id)
                    )}
                    disabled={!formik?.values?.orgLevel}
                    onChange={(event, value) =>
                      formik.setFieldValue(
                        'locations',
                        value.map((option) => option.id)
                      )
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant='outlined'
                          label={option.name}
                          {...getTagProps({ index })}
                          key={option.id}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className='mui_custom_autocomplete'
                        size='small'
                        variant='outlined'
                        placeholder={
                          formik?.values?.locations?.length > 0
                            ? ''
                            : 'Select locations'
                        }
                      />
                    )}
                  />
                  {formik.errors.locations && formik.touched.locations && (
                    <div className='Input__error_fs'>
                      {formik.errors.locations}
                    </div>
                  )}
                </div>
                {currentTab !== 'inventory' && currentTab !== 'coldchain' && (
                  <div className='w-full Input__column'>
                    <p className='Input__label_fs'>
                      {t('From / To')}
                      <b>*</b>{' '}
                    </p>
                    <div className='Input__row two_column'>
                      <input
                        {...formik.getFieldProps('fromDate')}
                        type='date'
                        className='mi_custom_textfield date_field_big'
                      />
                      <input
                        {...formik.getFieldProps('toDate')}
                        type='date'
                        className='mi_custom_textfield date_field_big'
                      />
                    </div>
                  </div>
                )}

                <div className='w-full flex justify-center pt-4'>
                  <Button
                    type='submit'
                    className='mi_btn mi_btn_medium mi_btn_primary'
                  >
                    <span>Get the Data</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
