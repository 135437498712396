import React from 'react';
import AssetBarChart from './BarStackedGraph';
import BarGraph from '../../dataVisualization/dataGraph/InvGraphs/BarGraph';
import LineGraph from '../../dataVisualization/dataGraph/InvGraphs/LineGraph';

export default function AssetGraph({ createdData, checked }) {
  const bars = [{ dataKey: 'count', fill: '#4F46E5' }];
  const lines = [{ dataKey: 'count', stoke: '#4F46E5' }];
  return (
    <div className='Graph_list grid'>
      {checked ? (
        <AssetBarChart data={createdData} />
      ) : (
        <BarGraph
          data={createdData}
          xAxisKey={checked ? 'location' : 'status'}
          bars={bars}
          title='Asset Status Decision'
          height={200}
          chartConfig={{
            margin: { top: 20, right: 30, left: 20, bottom: 5 },
          }}
          graphType='Bars'
          setGraphType={() => {}}
          noLegends={true}
        />
      )}

      <LineGraph
        data={createdData}
        xAxisKey={checked ? 'location' : 'status'}
        lines={lines}
        title='Asset Status Decision'
        height={200}
        chartConfig={{
          margin: { top: 20, right: 30, left: 20, bottom: 5 },
        }}
        graphType='Bars'
        setGraphType={() => {}}
        noLegends={true}
      />
    </div>
  );
}
