import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import TransferAddressCard from "../../components/orderAddressCard/TransferAddressCard";
import ShipmentProducts from "./ShipmentProducts";
import ProductsTable from "./ProductsTable";
import { callPopup } from "../../../store/slices/popupSlice";
import { createShipment } from "../../../redux/shipmentActions";
import { useDispatch } from "react-redux";
import { getLocations } from "../../../redux/userActions";
import { getCampaigns } from "../../../redux/shipmentActions";
import Shipment from "/icons/all/shipments/Shipment.svg";

export default function ReviewShipments() {
  const { t } = useTranslation();
  const [shipmentData, setShipmentData] = React.useState();
  const [selectedProducts, setSelectedProducts] = React.useState();
  const [locations, setLocations] = React.useState();
  const [shipLocations, setShipLocations] = React.useState();
  const [campaign, setCampaign] = React.useState();

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const params = useLocation();
  React.useEffect(() => {
    setShipmentData(params?.state?.shipmentData);
    setSelectedProducts(params?.state?.selectedProducts);
    setShipLocations({
      supplier: params?.state?.fromLocations,
      delivery: params?.state?.toLocations,
    });
  }, [params]);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await getLocations();
  //     setLocations({
  //       supplier: res?.data?.find(
  //         (loc) => loc?._id === params?.state?.shipmentData?.source
  //       ),
  //       delivery: res?.data?.find(
  //         (loc) => loc?._id === params?.state?.shipmentData?.destination
  //       ),
  //     });
  //   };
  //   fetchData();
  // }, [params]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getCampaigns(true, 0, 0);
      if (result?.success) {
        if (shipmentData?.campaignId) {
          const campData = result?.data?.data?.find(
            (camp) => camp?._id == shipmentData?.campaignId,
          );
          setCampaign(campData);
        }
      }
    };
    fetchData();
  }, [shipmentData]);

  const addShipment = async () => {
    const res = await createShipment(shipmentData);

    if (res.success) {
      Navigate("/user/shipments");
      dispatch(
        callPopup({
          open: true,
          type: "success",
          page: "shipments",
          title: t("shipment_created_msg"),
          action: "/user/shipments",
          element: (
            <ShipmentPopupInfo
              shipmentId={res?.data?.id}
              transitNo={res?.data?.transitNo}
            />
          ),
        }),
      );
    } else {
      dispatch(
        callPopup({
          open: true,
          type: "error",
          page: "orders",
          title: res.data.message,
          message: res.data.data,
        }),
      );
    }
  };

  const goBack = () => {
    Navigate("/user/shipments/new-shipments", {
      state: {
        shipmentData: shipmentData,
        selectedProducts: selectedProducts,
        fromLocations: params?.state?.fromLocations,
        toLocations: params?.state?.toLocations,
      },
    });
  };

  const ShipmentPopupInfo = ({ shipmentId, transitNo }) => {
    return (
      <div className='shipment__message'>
        <p className='page__body_fs'>
          You can use the supply id & transit no. to track your supply
        </p>

        <p className='popup__element_body_fs mi_font_bold'>
          Supply ID : <span>{shipmentId}</span> & Transit No :{" "}
          <span>{transitNo}</span>
        </p>
      </div>
    );
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={Shipment} className='Campaign__icon' />
            <h1 className='Page__headline_title_fs'>{t("review_shipments")}</h1>
          </div>
          <BreadCrumb
            url1='/user/orders'
            Link1={t("shipments")}
            Link2={`${t("add_shipments")}`}
            Link3={`${t("review_shipments")}`}
          />
        </div>

        {shipmentData?.selectedOrder?.length > 0 && (
          <div className='Page__select_tab_wrapper'>
            <div className='Page__select_tab_header'>
              <div className='Select__tab_headline'>
                <h1 className='page__body_heading_fs'>Order Details</h1>
              </div>
            </div>
            <div className='Order__summary_body_list'>
              <div className='summary_body_content_card'>
                <h1 className='page__body_fs mi_bold'>{t("order_id")}</h1>
                <h1 className='page__notion'>:</h1>
                <p className='page__body_fs '>
                  {shipmentData?.selectedOrder?.[0]?.id}
                </p>
              </div>
              <div className='summary_body_content_card'>
                <h1 className='page__body_fs mi_bold'>{t("created_at")}</h1>
                <h1 className='page__notion'>:</h1>
                <p className='page__body_fs '>
                  {new Date(
                    shipmentData?.selectedOrder?.[0]?.createdAt,
                  ).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        )}

        {shipmentData?.campaignId && (
          <div className='Page__select_tab_wrapper'>
            <div className='Page__select_tab_header'>
              <div className='Select__tab_headline'>
                <h1 className='page__body_heading_fs'>Campaign Details</h1>
              </div>
            </div>
            <div className='Order__summary_body_list'>
              <div className='summary_body_content_card'>
                <h1 className='page__body_fs mi_bold'>{t("campaign_id")}</h1>
                <h1 className='page__notion'>:</h1>
                <p className='page__body_fs '>{campaign?.id}</p>
              </div>
              <div className='summary_body_content_card'>
                <h1 className='page__body_fs mi_bold'>{t("campaign_name")}</h1>
                <h1 className='page__notion'>:</h1>
                <p className='page__body_fs '>{campaign?.name}</p>
              </div>
            </div>
          </div>
        )}

        <div className='Page__select_tab_wrapper'>
          <div className='Page__select_tab_header'>
            <div className='Select__tab_headline'>
              <h1 className='page__body_heading_fs'>{t("product_list")}</h1>
            </div>
          </div>
          <div>
            {shipmentData?.orderId ? (
              <ShipmentProducts
                productList={shipmentData?.productList}
                selectedBatchList={shipmentData?.products}
                t={t}
              />
            ) : (
              <ProductsTable
                products={shipmentData?.productsListWOID}
                selectedBatchList={shipmentData?.products}
                t={t}
              />
            )}
          </div>
        </div>

        <TransferAddressCard locations={shipLocations} />

        <section className='InventoryOrders__flex_space_actions'>
          <div className='order__remainder_space'> </div>

          <div className='Page__left_actions'>
            {false ? (
              <button
                onClick={() => Navigate("/user/orders")}
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <span>{t("go_to_shipments")} </span>
              </button>
            ) : (
              <>
                <button
                  className='mi_btn mi_btn_medium mi_btn_outline'
                  onClick={goBack}
                >
                  <span>{t("edit")}</span>
                </button>
                <button
                  onClick={addShipment}
                  className='mi_btn mi_btn_medium mi_btn_primary'
                >
                  <span>{t("confirm")}</span>
                </button>
              </>
            )}
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}
