import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import OrdersOutTable from "../orders/OrdersOutTable";
import OrdersInTable from "../orders/OrdersInTable";
import AnalyticTile from "../../../../shared/utils/analyticTile/AnalyticTile";
import OutboundOrder from "/icons/dashboard/outboundOrder.svg";
import InboundOrder from "/icons/dashboard/inboundOrder.svg";
import { useSearchParams } from "react-router";
import {
  useGetInboundIndentData,
  useGetOutboundIndentData,
} from "../../../../services/shipment-services/queries";
import TabContainer from "../dataGraph/containers/TabContainer";

export default function OrdersTable({ tab, setTab, type }) {
  const { t } = useTranslation();
  const [currentOrder, setCurrentOrder] = useState("outbound");

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get("locations").split(","),
    fromDate: searchParams.get("fromDate") || null,
    toDate: searchParams.get("toDate") || null,
  };

  let data;
  switch (currentOrder) {
    case "outbound":
      ({ data } = useGetOutboundIndentData(apiBody, page, limit));
      break;
    case "inbound":
      ({ data } = useGetInboundIndentData(apiBody, false, page, limit));
      break;

    default:
      data = null;
  }

  const OrderOutboundColumns = [
    { key: "supplier", title: "supplier" },
    { key: "product_quantity", title: "product_quantity" },
    { key: "date_OrderId", title: "date_order_id" },
    { key: "delivery", title: "delivery" },
    { key: "status", title: "status" },
    { key: "lastUpdateBy", title: "last_updated_by" },
  ];

  const OrderInboundColumns = [
    { key: "product_quantity", title: "product_quantity" },
    { key: "order_sent_by", title: "order_sent_by" },
    { key: "orderId", title: "order_id" },
    { key: "order_placed_on", title: "order_placed_on" },
    { key: "delivery_location", title: "delivery_location" },
    { key: "status", title: "status" },
  ];

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <TabContainer currentTab={tab} setCurrentTab={setTab} type={type} />
        <div className='Page__tab_select_space'>
          <div className='tab__btn_group'>
            <AnalyticTile
              state='outbound'
              icon={OutboundOrder}
              title={t("outbound") + " " + t("orders")}
              tab={currentOrder}
              setTab={setCurrentOrder}
            />

            <AnalyticTile
              state='inbound'
              icon={InboundOrder}
              title={t("inbound") + " " + t("orders")}
              tab={currentOrder}
              setTab={setCurrentOrder}
            />
          </div>
        </div>
        {currentOrder === "outbound" ? (
          <OrdersOutTable
            data={data}
            headers={OrderOutboundColumns}
            tab={currentOrder}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        ) : (
          currentOrder === "inbound" && (
            <OrdersInTable
              data={data}
              headers={OrderInboundColumns}
              tab={currentOrder}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )
        )}
      </section>
    </React.Fragment>
  );
}
