import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router";
import InfoIcon from "/icons/table/info.svg";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";
import { capitalToNormalCase } from "../../../../shared/utils/utils";

export default function InvSummary({
  inventory,
  filteredColumns,
  totalRecords,
  page,
  setPage,
  limit,
  setLimit,
  tableRef,
}) {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }} ref={tableRef}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {filteredColumns.map((column, index) => (
              <TableCell key={index}>
                <FieldHead
                  title={t(column.title)}
                  align={column.key === "productName" ? "start" : ""}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'>
          {inventory?.length > 0 ? (
            inventory.map((row, index) => (
              <InvSummaryRow
                key={index}
                row={row}
                filteredColumns={filteredColumns}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: "2rem" }} colSpan={7}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

const InvSummaryRow = React.memo(({ row, filteredColumns }) => {
  const getEventStatus = (event, eventType) => {
    if (eventType === "INVENTORY") {
      if (event === "CREATE") return { status: "ADDED", tone: "green" };
      return { status: "OPENED", tone: "blue" };
    } else {
      if (event === "CREATE") return { status: "SENT", tone: "red" };
      return { status: "RECEIVED", tone: "pink" };
    }
  };
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {filteredColumns.map((column, index) => (
        <TableCell key={index}>
          {column.key === "productCategory" && (
            <FieldBody text={capitalToNormalCase(row?.product?.type)} />
          )}
          {column.key === "productName" && (
            <>
              <ProductIcon
                type={row?.product?.type}
                name={row?.product?.name}
                size='tiny'
                fallback={<FieldBody text={row?.product?.name} color='bold' />}
              />
            </>
          )}
          {column.key === "productId" && (
            <FieldBody text={row?.product?.id} color='bold' />
          )}
          {column.key === "quantity" && (
            <FieldBody text={row?.quantity + " " + row?.product?.units} />
          )}
          {column.key === "transactionDate" && (
            <FieldBody
              text={new Date(row?.createdAt).toLocaleDateString()}
              color='blue'
            />
          )}
          {column.key === "status" && (
            <StatusLabel
              status={getEventStatus(row?.event, row?.eventType).status}
              tone={getEventStatus(row?.event, row?.eventType).tone}
            />
          )}
          {column.key === "view" && (
            <>
              {row?.refId && (
                <div align='center'>
                  {row?.eventType === "SHIPMENT" && (
                    <Link
                      to={"/user/shipments/view-shipment/" + row.refId}
                      className='Table__Icon'
                    >
                      <img src={InfoIcon} alt='Icon' />
                    </Link>
                  )}
                </div>
              )}
            </>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
});
