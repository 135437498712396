import React from "react";
import "../newShipment/NewShipment.css";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router";
import FetchProduct from "../newShipment/fetchProduct/FetchProduct";
import { useTranslation } from "react-i18next";
import InventoryForm from "./inventoryForm/InventoryForm";
import InvProductTable from "./productTable/InvProductTable";
import {
  createAdjustmentInventory,
  updateVVmStatus,
} from "../../../redux/inventoryActions";
import { useDispatch } from "react-redux";
import { callPopup } from "../../../store/slices/popupSlice";
import { Alert } from "@mui/material";
import AdjustmentMessage from "../../components/adjustmentMessage/AdjustmentMessage";
import toast from "react-hot-toast";

export default function CreateAdjustment() {
  const { t } = useTranslation();

  const { url } = useParams();

  const [errorMessage, setErrorMessage] = React.useState();
  const [vvmStatus, setVvmStatus] = React.useState();

  const [adjustSubmitted, setAdjustmentSubmitted] = React.useState(false);

  const [from, setFrom] = React.useState();
  const [to, setTo] = React.useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Product List Popup
  const [openProductPopup, setOpenProductPopup] = React.useState(false);

  const handleOpenProductPopup = () => {
    setOpenProductPopup(true);
  };

  const handleCloseProductPopup = () => {
    setOpenProductPopup(false);
  };

  //  Product List without ORDER ID Flow
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [productsList, setProductsList] = React.useState(null);

  //  Delete Rows
  const handleRemoveProducts = (id) => {
    const newProductsList = productsList?.filter((prod) => prod?._id !== id);

    const newSelectedProducts = selectedProducts?.filter(
      (prod) => prod?._id !== id,
    );

    const updatedSelectedProductList = selectedBatchList
      ?.flat()
      ?.filter((product) => product?.productId !== id);

    setProductsList(newProductsList);
    setSelectedProducts(newSelectedProducts);
    setSelectedBatchList(updatedSelectedProductList);
  };

  const checkReasons = (products) => {
    for (let product of products) {
      if (!product?.reason || product?.reason.trim() === "") {
        return false;
      }
    }
    return true;
  };

  //  Product Batch List Selection
  const [selectedBatchList, setSelectedBatchList] = React.useState([]);

  //  Final Data
  const createShipmentData = {
    fromStatus: from,
    toStatus: to,
    products: selectedBatchList.flat(),
  };

  //  Create shipment API Call
  const handleCreateShipment = async (e) => {
    e.preventDefault();

    if (!createShipmentData?.fromStatus) {
      setErrorMessage("From Status is Required");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
    if (!createShipmentData?.toStatus) {
      setErrorMessage("To Status is Required");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
    if (checkReasons(createShipmentData?.products) === false) {
      setErrorMessage("Please Add Reason to all products");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }

    if (
      createShipmentData?.fromStatus &&
      createShipmentData?.toStatus &&
      checkReasons(createShipmentData?.products) === true
    ) {
      const res = await createAdjustmentInventory(createShipmentData);

      if (res.success == true) {
        // navigate("/user/inventory/adjustment/outbound");
        // dispatch(
        //   callPopup({
        //     open: true,
        //     type: "success",
        //     page: "inventory",
        //     message: t("adjustment_request_created"),
        //     action: "/user/inventory/adjustment/outbound",
        //   })
        // );
        toast.success("Adjustment Request Sent");
        setAdjustmentSubmitted(true);
      } else {
        dispatch(
          callPopup({
            open: true,
            type: "error",
            page: "orders",
            title: res.data.message,
            message: res.data.data,
          }),
        );
      }
    }
  };

  const [vvmUpdateData, setVvmUpdateData] = React.useState([]);

  //  Create shipment API Call
  const handleVVMshipment = async (e) => {
    e.preventDefault();
    const vvmApiData = {
      products: vvmUpdateData,
    };

    const res = await updateVVmStatus(vvmApiData);

    if (res.success == true) {
      navigate("/user/inventory");
      dispatch(
        callPopup({
          open: true,
          type: "success",
          page: "inventory",
          message: t("vvm_status_updated"),
          action: "/user/inventory",
        }),
      );
    } else {
      dispatch(
        callPopup({
          open: true,
          type: "error",
          page: "orders",
          title: res.data.message,
          message: res.data.data,
        }),
      );
    }
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div className='back_Link' onClick={() => navigate(-1)}>
              <i className='bx bx-arrow-back icon_blue'></i>
            </div>

            <h1 className='Page__headline_title_fs'>
              {url === "request"
                ? t("adjustment_inventory")
                : url === "status" && t("update_vvm_status")}
            </h1>
          </div>
          <BreadCrumb
            url1='/user/shipments'
            Link1={t("inventory")}
            Link2={t("adjustment_inventory")}
          />
        </div>

        <form className='ModalPopup__form_container'>
          <div className='CreateShipment__grid_layout'>
            {adjustSubmitted ? (
              <AdjustmentMessage
                from={createShipmentData?.fromStatus}
                to={createShipmentData?.toStatus}
              />
            ) : (
              <div className='CreateShipment__space'>
                {url === "request" && (
                  <InventoryForm
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    t={t}
                    selectedProducts={selectedProducts}
                  />
                )}

                <div className='CreateShipment__table'>
                  <div className='Select__tab_headline'>
                    <h1 className='page__body_heading_fs'>
                      {t("product_list")} :
                    </h1>

                    <p className='Input__note_fs body_fs'>
                      {url === "request"
                        ? t("adjustment_inv_note")
                        : t("vvm_status_note")}
                    </p>
                  </div>

                  <InvProductTable
                    products={productsList}
                    handleRemoveProducts={handleRemoveProducts}
                    selectedBatchList={selectedBatchList}
                    setSelectedBatchList={setSelectedBatchList}
                    vvmUpdateData={vvmUpdateData}
                    setVvmUpdateData={setVvmUpdateData}
                    t={t}
                    url={url}
                    from={from}
                  />

                  <div className='ShipProdTable__addmore'>
                    <div
                      className='ShipProdTable__addmore_btn'
                      onClick={handleOpenProductPopup}
                    >
                      <i className='fa-solid fa-plus'></i>
                    </div>
                  </div>
                </div>

                <section className='Create__shipment_action Create__shipment_action_space'>
                  <div>
                    {errorMessage && (
                      <Alert severity='error'>{errorMessage}</Alert>
                    )}
                  </div>
                  <div className='Page__left_actions'>
                    <Link
                      to='/user/inventory'
                      type='button'
                      className='mi_btn mi_btn_medium mi_btn_outline'
                    >
                      <span>{t("back")}</span>
                    </Link>

                    {url === "request" && (
                      <button
                        type='submit'
                        onClick={(e) => handleCreateShipment(e)}
                        className={`mi_btn mi_btn_medium ${
                          productsList?.length > 0
                            ? productsList?.length >=
                              selectedBatchList?.flat()?.length
                              ? "mi_btn_primary"
                              : "mi_btn_disabled"
                            : "mi_btn_disabled"
                        }`}
                        disabled={
                          productsList?.length > 0
                            ? productsList?.length ===
                              selectedBatchList?.flat()?.length
                              ? false
                              : true
                            : true
                        }
                      >
                        <span>{t("confirm")}</span>
                      </button>
                    )}

                    {url === "status" && (
                      <button
                        type='submit'
                        onClick={(e) => handleVVMshipment(e)}
                        className={`mi_btn mi_btn_medium ${
                          productsList?.length > 0
                            ? productsList?.length >=
                              selectedBatchList?.flat()?.length
                              ? "mi_btn_primary"
                              : "mi_btn_disabled"
                            : "mi_btn_disabled"
                        }`}
                        disabled={vvmUpdateData?.length > 0 ? false : true}
                      >
                        <span>{t("confirm")}</span>
                      </button>
                    )}
                  </div>
                </section>
              </div>
            )}
          </div>
        </form>
      </section>
      <FetchProduct
        keepMounted
        from={"6609d613ce6524ba7365a406"}
        type={url === "status" ? "VACCINE" : null}
        open={openProductPopup}
        onClose={handleCloseProductPopup}
        productsListWOID={productsList}
        setProductsListWOID={setProductsList}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        pageType={"Adjustment"}
        url={url}
        t={t}
      />
    </React.Fragment>
  );
}
