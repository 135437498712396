import TableContainer from '@mui/material/TableContainer';
import React from 'react';

import { useTranslation } from 'react-i18next';

export default function VARExportTable({
  printData,
  filteredColumns,
  tableRef,
}) {
  const { t } = useTranslation();

  // Limit to the first 10 records
  const displayedData = printData?.slice(0, 10);
  return (
    <TableContainer>
      <table style={tableStyle} ref={tableRef}>
        <thead>
          <tr>
            {filteredColumns?.map((column, index) => (
              <th key={index} style={thStyle}>
                {t(column?.title)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedData?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {filteredColumns.map((column) => (
                <React.Fragment key={column?.key}>
                  {column.key === 'varid_no' && (
                    <td style={thTdStyle}>{row?.varid_no}</td>
                  )}

                  {column.key === 'date_of_report' && (
                    <td style={thTdStyle}>
                      {new Date(row?.date_of_report).toLocaleDateString()}
                    </td>
                  )}

                  {column.key === 'date_of_inspection' && (
                    <td style={thTdStyle}>
                      {new Date(row?.date_of_inspection).toLocaleDateString()}
                    </td>
                  )}

                  {column.key === 'vaccine' && (
                    <td style={thTdStyle}>
                      {row?.vaccine}
                    </td>
                  )}

                  {column.key === 'from' && (
                    <td style={thTdStyle}>
                      {row?.from}
                    </td>
                  )}

                  {column.key === 'to' && <td style={thTdStyle}>CMSD</td>}
                  {column.key === 'status' && (
                    <td style={thTdStyle}>{row?.status}</td>
                  )}
                  {column.key === 'view' && <td style={thTdStyle}>--</td>}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thTdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  fontSize: '10px',
};

const thStyle = {
  ...thTdStyle,
  backgroundColor: '#f2f2f2',
};
