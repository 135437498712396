import React from "react";
import "./UserProfile.css";
import { Tooltip } from "@mui/material";
import backupIcon from "/defaults/profile_default.jpg";
import { API_URL } from "../../../../config";
import UpdateProfile from "../../../components/UpdateProfile/UpdateProfile";
import { Link } from "react-router";
import { formatBDPhoneNumber } from "../../../../utils/helper";
import {
  useGetUserInfo,
  useGetUserLocationInfo,
} from "../../../../services/user-services/queries";

export default function UserProfile({ page }) {
  const { data: userInfo } = useGetUserInfo();
  const { data: location } = useGetUserLocationInfo();

  const ImageWithFallback = ({ src, fallbackSrc, alt }) => {
    return (
      <img
        src={src}
        alt={alt}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackSrc;
        }}
      />
    );
  };
  const imageUrl = userInfo?.photoId
    ? `${API_URL.imagesUrl}/${userInfo?.photoId}`
    : backupIcon;

  return (
    <div className='Profile__Container'>
      <section className='UserProfile__container'>
        <article className='UserProfile__account'>
          <div className='UserProfile__personal_info'>
            <div className='UserProfile__headline'>
              <h1 className='UserProfile__account_title_fs'>Personal Info</h1>
              <Tooltip title={"Edit Profile"} arrow>
                <Link
                  to={"/user/services/update"}
                  className='mi_cursor profile_edit_icon'
                >
                  <i className='fa-regular fa-pen-to-square'></i>
                </Link>
              </Tooltip>
            </div>

            <div className='UserProfile_main_wrapper'>
              <div className='UserProfile__personal_wrapper'>
                <div className='Personal__avatar_wrapper'>
                  <ImageWithFallback
                    src={imageUrl}
                    fallbackSrc={backupIcon}
                    alt='Campaign Icon'
                  />
                </div>
                <div className='UserProfile__account_body'>
                  <div className='UserProfile__profile_row'>
                    <p className='Account__info_header_fs'>Full Name</p>

                    <div className='edit_button_wrapper'>
                      <p className='Account__info_body_fs all_caps'>
                        {userInfo?.firstName + " " + userInfo?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className='UserProfile__profile_row'>
                    <p className='Account__info_header_fs'>
                      Organization Level
                    </p>

                    <div className='edit_button_wrapper'>
                      <p className='Account__info_body_fs'>
                        {userInfo?.orgLevel}
                      </p>
                    </div>
                  </div>
                  <div className='UserProfile__profile_row'>
                    <p className='Account__info_header_fs'>Role</p>

                    <div className='edit_button_wrapper'>
                      <p className='Account__info_body_fs'>
                        {userInfo?.roleId?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='UserProfile__account_info'>
            <div className='UserProfile__headline'>
              <h1 className='UserProfile__account_title_fs'>
                Account Settings
              </h1>
            </div>

            <div className='UserProfile__account_body'>
              <div className='UserProfile__account_row'>
                <p className='Account__info_header_fs'>Email Address</p>

                <div className='edit_button_wrapper'>
                  <p className='Account__info_body_fs'>{userInfo?.emailId}</p>
                  {/* <Tooltip title={'Edit Email'} arrow>
                    <Link
                      to={'/user/services/account'}
                      className='mi_cursor profile_edit_icon'
                    >
                      <i class='fa-regular fa-pen-to-square'></i>
                    </Link>
                  </Tooltip> */}
                </div>
              </div>
              <div className='UserProfile__account_row'>
                <p className='Account__info_header_fs'>Phone Number</p>

                <div className='edit_button_wrapper'>
                  <p className='Account__info_body_fs'>
                    {userInfo?.phoneNumber &&
                      formatBDPhoneNumber(userInfo?.phoneNumber)}
                  </p>
                  {/* <Tooltip title={'Edit Phone'} arrow>
                    <div className='mi_cursor profile_edit_icon'>
                      <i class='fa-regular fa-pen-to-square'></i>
                    </div>
                  </Tooltip> */}
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>

      <section className='UserProfile__container'>
        <article className='UserProfile__account'>
          <div className='UserProfile__account_info'>
            <div className='UserProfile__headline'>
              <h1 className='UserProfile__account_title_fs'>Location Info</h1>
            </div>
            <div className='UserProfile__account_body'>
              <div className='UserProfile__account_row'>
                <p className='Account__info_header_fs'>Location Name</p>

                <div className='edit_button_wrapper'>
                  <p className='Account__info_body_fs'>{location?.name}</p>
                </div>
              </div>
              <div className='UserProfile__account_row'>
                <p className='Account__info_header_fs'>Address</p>

                <div className='edit_button_wrapper'>
                  <p className='Account__info_body_fs'>
                    {location?.postalAddress}
                  </p>
                </div>
              </div>
              <div className='UserProfile__account_row'>
                <p className='Account__info_header_fs'>Division & District</p>

                <div className='edit_button_wrapper'>
                  <p className='Account__info_body_fs'>
                    {location?.division + " & " + location?.district}
                  </p>
                </div>
              </div>
              <div className='UserProfile__account_row'>
                <p className='Account__info_header_fs'>Country</p>

                <div className='edit_button_wrapper'>
                  <p className='Account__info_body_fs'>{location?.country}</p>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <UpdateProfile
        keepMounted
        open={page === "update" ? true : false}
        user={userInfo}
        page={page}
      />
      <UpdateProfile
        keepMounted
        open={page === "account" ? true : false}
        user={userInfo}
        page={page}
      />
    </div>
  );
}
