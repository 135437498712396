import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { getDates } from "../../../../shared/common/commonFunctions";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { t } from "i18next";
import dayjs from "dayjs";

export default function PARform1({
  setFormComplete,
  setData,
  storedData,
  countries,
  products,
  mainForm,
}) {
  const navigate = useNavigate();
  const selectedProduct = products?.find(
    (prod) => prod._id === storedData.product,
  );
  const qtyPerUnit = selectedProduct?.manufacturers?.[0].quantityPerUnit?.[0];

  const validationSchema = Yup.object().shape({
    Country: Yup.string().required(t("country_error")),
    po: Yup.string().required(t("purchase_order_no_error")),
    consignee: Yup.string().required(t("consignee_error")),
    manufacturer: Yup.string().required(t("manufacturer_error")),
    invoice_amount: Yup.string().required(t("invoice_amount_error")),
  });

  const formik = useFormik({
    initialValues: {
      Country: storedData?.shipmentDetails?.originCountry || "",
      Question1: storedData?.shipmentDetails?.shortShipmentNotification || true,
      Question2: storedData?.shipmentDetails?.shortShipment || true,
      po: storedData?.shipmentDetails?.poNo || "",

      manufacturer:
        storedData?.shipmentDetails?.products[0].manufacturerId || "",
      consignee:
        storedData?.shipmentDetails?.consignee ||
        "CMSD- Central Medical Stores Depot",
      comment: storedData?.shipmentDetails?.comments || "",
      invoice_amount: storedData?.shippingIndicators?.invoiceAmount || "",

      vaccineDetails: storedData?.shipmentDetails?.products
        ? storedData?.shipmentDetails?.products?.map((detail) => ({
            batchNo: detail?.batchNo || "",
            boxes: detail?.noOfUnits || "",
            mfgDate: dayjs(detail?.mfgDate).format("YYYY-MM-DD") || "",
            expDate: dayjs(detail?.expDate).format("YYYY-MM-DD") || "",
          }))
        : [
            {
              batchNo: "",
              boxes: "",
              mfgDate: "",
              expDate: "",
            },
          ],
    },
    validationSchema,
    onSubmit: (values) => {
      const filteredVaccineDetails = values.vaccineDetails.map((detail) => {
        const { expDate, ...rest } = detail;
        return expDate ? detail : rest;
      });

      const finalData = {
        Country: values.Country,
        consignee: values.consignee,
        manufacture: values.manufacturer,
        po: values.po,
        Question1: values.Question1,
        Question2: values.Question2,
        comment: values.comment,
        invoice_amount: values.invoice_amount,
        quantityPerUnit: qtyPerUnit,
        vaccineDetails: filteredVaccineDetails,
        productInfo: {
          id: selectedProduct?.id,
          name: selectedProduct?.name,
          type: selectedProduct?.type,
        },
      };

      setData(1, finalData);
      navigate("?form=part2");
      setFormComplete(2);

      toast.success(t("PAR_part1_saved"));
    },
  });

  useEffect(() => {
    if (storedData?.shipmentDetails?.shortShipmentNotification) {
      formik.setFieldValue(
        "Question1",
        storedData?.shipmentDetails?.shortShipmentNotification,
      );
      formik.setFieldValue(
        "Question2",
        storedData?.shipmentDetails?.shortShipment,
      );
    }
  }, []);

  useEffect(() => {
    const resData = selectedProduct?.manufacturers.find(
      (item) => item.id === formik.values.manufacturer,
    );

    if (resData?.details?.country) {
      formik.setFieldValue("Country", resData?.details?.country);
    }
  }, [formik.values.manufacturer]);

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className='VARform'>
        <div className='VARform__header'>
          <h1 className='page__body_bigheading_fs'>{t("PAR_part1_title")}</h1>
        </div>
        <div className='VARform__body'>
          <div className='VARform__table'>
            <article className='VARform__row three_column'>
              <div className='VARform__column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("purchase_order_number")} <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("po")}
                  />
                  {formik.touched.po && formik.errors.po && (
                    <span className='Input__error_fs'>{formik.errors.po}</span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("manufacturer")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      {...formik.getFieldProps("manufacturer")}
                      onChange={(event) => {
                        formik.handleChange(event);
                      }}
                    >
                      {selectedProduct?.manufacturers?.map((org) => (
                        <MenuItem
                          key={org.details._id}
                          value={org.details?._id}
                        >
                          {org.details?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.manufacturer &&
                    formik.errors.manufacturer && (
                      <span className='Input__error_fs'>
                        {formik.errors.manufacturer}
                      </span>
                    )}
                </div>
              </div>

              <div className='VARform__column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("invoice_amount")} <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("invoice_amount")}
                  />
                  {formik.touched.invoice_amount &&
                    formik.errors.invoice_amount && (
                      <span className='Input__error_fs'>
                        {formik.errors.invoice_amount}
                      </span>
                    )}
                </div>

                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("country")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      {...formik.getFieldProps("Country")}
                    >
                      {countries?.map((country, index) => (
                        <MenuItem key={index} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.Country && formik.errors.Country && (
                    <span className='Input__error_fs'>
                      {formik.errors.Country}
                    </span>
                  )}
                </div>
              </div>

              <div className='VARform__column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("consignee")} <b>*</b>
                  </p>
                  <input
                    type='text'
                    disabled
                    className='mi_custom_textfield'
                    {...formik.getFieldProps("consignee")}
                    style={{ opacity: "0.4" }}
                  />
                  {formik.touched.consignee && formik.errors.consignee && (
                    <span className='Input__error_fs'>
                      {formik.errors.consignee}
                    </span>
                  )}
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className='VARgreen__form'>
          <GreenFormRow formik={formik} />
        </div>
        <div className='VARform__radio_list'>
          <div className='VARradio__questions one_half_by_column'>
            <p className='page__body_fs mi_black'>{t("PAR_part1_question1")}</p>
            <FormControl>
              <RadioGroup
                row
                name='Question1'
                {...formik.getFieldProps("Question1")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='VARradio__questions one_half_by_column'>
            <p className='page__body_fs mi_black'>{t("PAR_part1_question2")}</p>
            <FormControl>
              <RadioGroup
                row
                name='Question2'
                {...formik.getFieldProps("Question2")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className='VARform__footer'>
          <article className='VARform__row two_by_one_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t("comments")} </p>
              <input
                type='text'
                className='mi_custom_textfield'
                {...formik.getFieldProps("comment")}
              />
            </div>
            <div className='Input__column_action'>
              <button
                className='mi_btn mi_btn_medium mi_btn_primary'
                type='submit'
                disabled={mainForm === false}
              >
                <span>{t("save_continue")}</span>
              </button>
            </div>
          </article>
        </div>
      </form>
    </React.Fragment>
  );
}

function GreenFormRow({ formik }) {
  const [yesterday, tomorrow] = getDates();
  const addNewRow = () => {
    formik.setValues({
      ...formik.values,
      vaccineDetails: [
        ...formik.values.vaccineDetails,

        {
          batchNo: "",
          boxes: "",
          quantity: "",
          expDate: "",
        },
      ],
    });
  };

  const removeRow = (indexOfObject) => {
    formik.setValues({
      ...formik.values,
      vaccineDetails: formik.values.vaccineDetails.filter(
        (_, index) => index !== indexOfObject,
      ),
    });
  };

  return (
    <React.Fragment>
      <div className='GreenFormRow'>
        {formik.values.vaccineDetails.map((vaccineDetail, index) => (
          <article key={index} className='VARform__row two_column space_btm'>
            <div className='VARform__column'>
              <div className='Input__row three_column'>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='page__body_fs mi_black'>{t("product")}</p>
                  </div>
                  {formik.values.vaccineDetails.length > 1 && (
                    <button
                      onClick={() => removeRow(index)}
                      type='button'
                      className='VAR__delete_btn'
                    >
                      <i className='fa-solid fa-trash'></i>
                    </button>
                  )}
                </div>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("lot_number")} <b>*</b>
                    </p>
                    <input
                      type='text'
                      name={`vaccineDetails[${index}].batchNo`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={vaccineDetail.batchNo}
                      required
                    />
                  </div>
                </div>
                <div className='VARform__column'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("no_of_unit")} <b>*</b>
                    </p>
                    <input
                      type='number'
                      name={`vaccineDetails[${index}].boxes`}
                      className='mi_custom_textfield'
                      onChange={formik.handleChange}
                      value={vaccineDetail.boxes}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='VARform__column'>
              <div className='Input__row two_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("manufacturing_date")} <b>*</b>
                  </p>
                  <input
                    type='date'
                    name={`vaccineDetails[${index}].mfgDate`}
                    className='mi_custom_textfield'
                    max={yesterday}
                    onChange={formik.handleChange}
                    value={vaccineDetail.mfgDate}
                    required
                  />
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t("expiry_date")}</p>
                  <input
                    type='date'
                    name={`vaccineDetails[${index}].expDate`}
                    className='mi_custom_textfield'
                    min={tomorrow}
                    onChange={formik.handleChange}
                    value={vaccineDetail.expDate}
                  />
                </div>
              </div>
            </div>
          </article>
        ))}
        <div className='VARgreen__btn'>
          <button
            type='button'
            onClick={addNewRow}
            className='mi_btn mi_btn_medium mi_btn_white'
          >
            <span>{t("add_new_row_button")}</span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
