import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { getLocations, getOrganizationLevel } from "@/redux/userActions";
import { useGetAssetsGraphDecision } from "@/services/coldchain-services/queries";
import {
  FormControl,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  useGetExpiredDecision,
  useGetNearExpiryDecision,
  useGetNetUtilizationDecision,
  useGetStockDecision,
  useGetWastedDecision,
  useOutOfStockGraphDecision,
} from "@/services/inventory-services/queries";
import { use } from "react";

import { Button } from "@/components/ui/button";
import OutofStocks from "../inventoryGraphs/OutofStocks";
import NearExpiry from "../inventoryGraphs/NearExpiry";
import WastedGraph from "../inventoryGraphs/WastedGraph";
import InvStockGraph from "../inventoryGraphs/InvStockGraph";
import NetUtilization from "../inventoryGraphs/NetUtilization";

export default function InventoryDecision() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentGraph, setCurrentGraph] = useState("Out of Stock");

  const [levels, setLevels] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedOrgLevel, setSelectedOrgLevel] = useState("All Levels");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [checked, setChecked] = useState(false);
  const [sorted, setSorted] = useState(false);

  useEffect(() => {
    async function fetchLevels() {
      const resultLevel = await getOrganizationLevel();
      setLevels(resultLevel?.data || []);
    }
    fetchLevels();
  }, []);

  useEffect(() => {
    async function fetchLocations() {
      if (selectedOrgLevel) {
        const res = await getLocations(selectedOrgLevel);
        setLocations(res?.data || []);
      }
    }
    fetchLocations();
  }, [selectedOrgLevel]);

  const districtOptions = useMemo(
    () =>
      locations.map((district) => ({ id: district._id, name: district.name })),
    [locations],
  );

  const { data: outOfStockData } = useOutOfStockGraphDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
  });

  const { data: nearExpiryData } = useGetNearExpiryDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
  });

  const { data: expiredData } = useGetExpiredDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
  });

  const { data: wastedData } = useGetWastedDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
  });

  const { data: stockData } = useGetStockDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
    sortOrder: sorted ? "ASC" : "DESC",
  });

  const { data: netUtilizationData } = useGetNetUtilizationDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    groupByProduct: checked,
    sortOrder: sorted ? "ASC" : "DESC",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/user/decision-making/graphs");
  };

  return (
    <section>
      <div className='Page__action_wrapper'>
        <div className='DataVisualization__container'>
          <form
            onSubmit={handleSubmit}
            className='dataviz_filters Input__table'
          >
            <div className='Input__row four_equal_column'>
              <div className='Input__column'>
                <p className='Input__label_fs'>{t("org_level")}</p>
                <FormControl fullWidth>
                  <Select
                    size='small'
                    value={selectedOrgLevel}
                    onChange={(e) => setSelectedOrgLevel(e.target.value)}
                  >
                    <MenuItem value={"All Levels"}>All Levels</MenuItem>
                    {levels.map((level, index) => (
                      <MenuItem key={index} value={level.value}>
                        {level.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("location")} <b>*</b>
                </p>
                <Autocomplete
                  multiple
                  size='small'
                  options={districtOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={districtOptions.filter((option) =>
                    selectedLocations.includes(option.id),
                  )}
                  onChange={(event, value) =>
                    setSelectedLocations(value.map((option) => option.id))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      variant='outlined'
                      placeholder='Select locations'
                    />
                  )}
                  disabled={!selectedOrgLevel}
                />
              </div>

              <div className='mt-6 flex gap-4 items-center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      name='gilad'
                    />
                  }
                  label='Group by Location'
                />
              </div>

              {(currentGraph === "Stock Graph" ||
                currentGraph === "Net Utilization") && (
                <div className='mt-6'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sorted}
                        onChange={() => setSorted(!sorted)}
                      />
                    }
                    label='Sort the Data'
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className='flex items-center gap-4 mb-4'>
        <Button
          variant={currentGraph === "Out of Stock" ? "" : "outline"}
          onClick={() => setCurrentGraph("Out of Stock")}
        >
          Out of Stock Graph
        </Button>
        <Button
          variant={currentGraph === "Near Expiry" ? "" : "outline"}
          onClick={() => setCurrentGraph("Near Expiry")}
        >
          Near Expiry Graph
        </Button>
        <Button
          variant={currentGraph === "Wasted Graph" ? "" : "outline"}
          onClick={() => setCurrentGraph("Wasted Graph")}
        >
          Wasted Graph
        </Button>
        <Button
          variant={currentGraph === "Stock Graph" ? "" : "outline"}
          onClick={() => setCurrentGraph("Stock Graph")}
        >
          Stock Graph
        </Button>

        <Button
          variant={currentGraph === "Net Utilization" ? "" : "outline"}
          onClick={() => setCurrentGraph("Net Utilization")}
        >
          Predict Stock Out based on Utilization Trend
        </Button>
      </div>

      {currentGraph === "Out of Stock" && (
        <OutofStocks outOfStockData={outOfStockData} checked={checked} />
      )}

      {currentGraph === "Near Expiry" && (
        <NearExpiry
          data={nearExpiryData}
          expiredData={expiredData}
          checked={checked}
        />
      )}

      {currentGraph === "Wasted Graph" && (
        <WastedGraph data={wastedData?.wastageResult} checked={checked} />
      )}

      {currentGraph === "Stock Graph" && (
        <InvStockGraph data={stockData?.data} checked={checked} />
      )}

      {currentGraph === "Net Utilization" && (
        <NetUtilization data={netUtilizationData?.data} checked={checked} />
      )}
    </section>
  );
}
