import React, { useState, useEffect } from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import ProductListTable from "./AssetListTable";
import {
  getAllInventoryProducts,
  getEquipmentList,
  getLocationProducts,
} from "../../../../redux/inventoryActions";
import { useLocation } from "react-router";

export default function FetchAssets(props) {
  const {
    onClose,
    open,
    from,
    selectedAssetList,
    setSelectedAssetList,
    t,
    ...other
  } = props;

  const location = useLocation();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [equipmentData, setEquipmentData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async (page, limit) => {
      try {
        const res = await getEquipmentList(page, limit);
        setEquipmentData(res.data);
      } catch (error) {
        console.error("Error fetching equipment data:", error);
      }
    };
    fetchData(page, limit);
  }, [page, limit]);

  // Getting Selected Products
  const handleToggleProduct = (item) => {
    const isSelected = selectedAssetList?.some(
      (selectedItem) => selectedItem._id === item?._id,
    );

    let newSelectedProducts;

    if (!isSelected) {
      newSelectedProducts = [...selectedAssetList, { ...item }];
    } else {
      newSelectedProducts = selectedAssetList?.filter(
        (selectedItem) => selectedItem._id !== item?._id,
      );
    }

    setSelectedAssetList(newSelectedProducts);
  };

  const handleClose = () => {
    setSelectedAssetList([]);
    onClose();
  };

  const handleSubmit = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className='ModalPopup__dialog'
      maxWidth='md'
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>{t("Asset List")}</div>
          <div className='ModalPopup__close_icon' onClick={handleClose}>
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className='ModalPopup__body'>
          <section className='Page__main_wrapper'>
            <div className='Page__table_space'>
              <ProductListTable
                equipmentData={equipmentData}
                assets={equipmentData?.data}
                selectedAssetList={selectedAssetList}
                handleToggleProduct={handleToggleProduct}
                page={page}
                limit={limit}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                t={t}
              />
            </div>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className='InventoryOrders__actions'>
          <div className='Page__left_actions'>
            <button
              type='submit'
              className={`mi_btn mi_btn_medium ${
                selectedAssetList?.length < 1
                  ? "mi_btn_disable"
                  : "mi_btn_primary"
              }`}
              onClick={handleSubmit}
              disabled={selectedAssetList?.length < 1 ? true : false}
            >
              <span>{t("confirm")}</span>
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}
