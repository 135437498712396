import { Link, useNavigate } from "react-router";
import { assetIcon } from "../../../../shared/utils/utils";

export default function ColdChainTableCard({ item, t }) {
  const navigate = useNavigate();

  const handleEditView = () => {
    navigate("/user/view-functional-status", {
      state: { asset: item },
    });
  };

  return (
    <section className='ColdChainTableCard'>
      <div className='ColdChainTableCard__row'>
        <TableColumnFields
          title='Year of Installation'
          data={item?.yearOfInstallation}
        />
        <TableColumnFields title='Refrigerator ID' data={item?.id || "--"} />
        <TableColumnFields title='Model' data={item?.model || "--"} />
        <TableColumnFields
          title='Serial Number'
          data={item?.serialNumber || "--"}
        />
        <TableColumnFields title='Equipment Type' data={item?.type || "--"} />
      </div>
      <div className='ColdChainTableCard__row'>
        {item?.locationTrail?.length > 0 ? (
          <TableColumnFields
            title='Location Trail'
            data={new Date(
              item?.locationTrail?.[item?.locationTrail?.length - 1]?.start,
            ).toLocaleDateString()}
          />
        ) : (
          <TableColumnFields title='Location Trail' data={"--"} />
        )}

        <TableColumnFields
          title='Power Source'
          data={item?.sourceOfEnergy || "--"}
        />
        <TableColumnFields
          title='Gross Storage Capacity'
          data={item?.capacity.map((u) => u.grossStorage).join(" | ")}
        />
        <TableColumnFields
          title='PQS Status'
          data={`${item?.pqsType} ${item?.pqsStatus}`}
        />
        <TableColumnFields
          title='Operational Status'
          data={item?.status || "--"}
        />
      </div>
      <div className='ColdChainTableCard__actions'>
        <div className='Page__left_actions'>
          <Link
            to={`/user/view-historical-data/${item?._id}`}
            className='mi_btn mi_btn_small mi_btn_primary'
          >
            <span>{t("historical_data")}</span>
          </Link>
          <button
            onClick={handleEditView}
            className='mi_btn mi_btn_small mi_btn_secondary bg_green'
          >
            <span>
              {t("view")} / {t("add")}
            </span>
          </button>
        </div>
      </div>
    </section>
  );
}

// Table Data Info
function TableColumnFields({ title, data, subtext }) {
  return (
    <div className='CartingTableData__wrap'>
      <p className='TableData__heading'>{title}</p>
      <h1 className='TableData__bodyInfo'>{data}</h1>
      {subtext && <h2 className='TableData__subheading'>{subtext}</h2>}
    </div>
  );
}
