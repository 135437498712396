import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import BreadCrumb from "../../../../shared/utils/breadCrumb/BreadCrumb";
import { getProducts } from "../../../../redux/userActions";
import { FormControl, MenuItem, Select } from "@mui/material";

import "../AutoIndent.css";
import toast from "react-hot-toast";
import {
  useGetAllInventoryProductsData,
  useNeedForCastData,
} from "../../../../services/inventory-services/queries";
import { getNeedForCastData } from "../../../../services/inventory-services/api";

export default function AutoIndentForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [product, setProduct] = React.useState(null);

  const [productList, setProductList] = React.useState(null);
  const [productInfo, setProductInfo] = React.useState(null);

  const [population, setPopulation] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts("VACCINE");
      setProductList(res.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData(id) {
      if (id) {
        const res = await getNeedForCastData(product?._id);
        setProductInfo(res);
      }
    }
    fetchData(product?._id);
  }, [product?._id]);

  const totalQuantity =
    (((population * product?.estimatedCoverage) / 100) *
      product?.dosePerBeneficiary *
      100) /
    (100 - product?.estimatedWastage);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div className='back_Link' onClick={() => navigate(-1)}>
              <i className='bx bx-arrow-back '></i>
            </div>
            <h1 className='Page__headline_title_fs'>
              {t("auto-indenting-details")}
            </h1>
          </div>
          {/* <BreadCrumb
            url1="/user/inventory/recall"
            Link1={`${t("recall")}`}
            Link2={`${t("create_recall")}`}
          /> */}
        </div>
      </section>
      <div className='auto_indenting_container'>
        <div className='auto_indent_grid'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t("vaccines")} <b>*</b>
            </p>
            <FormControl fullWidth>
              <Select
                className='mui_custom_input_field'
                value={product || ""}
                onChange={(event) => {
                  setProduct(event.target.value);
                }}
              >
                {productList &&
                  productList?.map((prod, index) => (
                    <MenuItem key={index} value={prod}>
                      {prod?.name?.length > 39
                        ? prod?.name.substring(0, 40) + "..."
                        : prod?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <article className='Input__column'>
            <p className='Input__label_fs'>
              {t("recommended_number_of_doses")}
            </p>
            <h1 className='TableData__bodyInfo'>
              {product?.dosePerBeneficiary ? product?.dosePerBeneficiary : "--"}
            </h1>
          </article>
        </div>

        <div className='auto_indent_grid'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t("Target Population")} <b>*</b>
            </p>
            <input
              type='text'
              className='mi_custom_textfield'
              value={population}
              onChange={(e) => setPopulation(e.target.value)}
            />
          </div>

          <article className='Input__column'>
            <p className='Input__label_fs'>{t("estimated_wastage_rate")}</p>
            <h1 className='TableData__bodyInfo'>
              {" "}
              {product?.estimatedWastage ? product?.estimatedWastage : "--"}
            </h1>
          </article>
        </div>

        <div className='auto_indent_grid'>
          <article className='Input__column'>
            <p className='Input__label_fs'>{t("quantity_dose")}</p>
            <h1 className='TableData__bodyInfo value'>
              {Math.round(totalQuantity)}
            </h1>
          </article>
          <article className='Input__column'>
            <p className='Input__label_fs'>{t("estimated_coverage_rate")}</p>
            <h1 className='TableData__bodyInfo '>
              {product?.estimatedCoverage ? product?.estimatedCoverage : "--"}
            </h1>
          </article>
        </div>

        <div className='auto_indent_grid'>
          <article className='Input__column'>
            <p className='Input__label_fs'>{t("buffer_doses_for_3months")}</p>
            <h1 className='TableData__bodyInfo value'>
              {Math.round((totalQuantity / 12) * 3)}
            </h1>
          </article>

          <article className='Input__column'></article>
        </div>

        <div className='auto_indent_grid'>
          <article className='Input__column'>
            <p className='Input__label_fs'>
              {t("annual_total_requirements_doses")}
            </p>
            <h1 className='TableData__bodyInfo value'>
              {Math.round(totalQuantity + (totalQuantity / 12) * 3)}
            </h1>
          </article>
          <article className='Input__column'></article>
        </div>

        <section className='top_space'>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={() => {
                if (population) {
                  toast.success("Data saved successfully");
                  navigate("/user/auto-indent");
                }
              }}
            >
              <span>{t("submit")}</span>
            </button>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
