import React, { useCallback } from 'react';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

export default function DataShadowExport(props) {
  const {
    onClose,
    open,
    title,
    tableComponent,
    printData,
    currentTab,
    ...other
  } = props;

  const { t } = useTranslation();

  const xport = useCallback(() => {
    try {
      // Convert your data to a worksheet
      const ws = XLSX.utils.json_to_sheet(printData);
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Beneficiaries');

      // Write the workbook to a file
      XLSX.writeFile(
        wb,
        `Report_of_${currentTab}_${dayjs().format('DD-MM-YYYY')}.xlsx`
      );
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, [currentTab, printData]);

  const handleExport = () => {
    xport();
    onClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '100%' },
      }}
      className='ModalPopup__dialog'
      maxWidth={'xl'}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: '0 !important' }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            <div className='modal__heading_group'>
              <div className='modal__heading'>
                <h1 className='page__body_subheading_fs'>Preview Table Data</h1>
              </div>

              <div className='modal__quanity'> </div>
            </div>
          </div>
          <div
            className='ModalPopup__close_icon'
            onClick={() => {
              onClose();
            }}
          >
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '0 !important', borderRadius: '0.6rem !important' }}
      >
        <div className='ModalPopup__body '>
          <section className='Page__main_wrapper'>
            This is the sample structure of the table
            <div className='printing_body'>{tableComponent}</div>
            You will get the full data when you export as excel
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className='InventoryOrders__actions_Popup'>
          <div>Total Records : {printData?.length}</div>
          <div className='Page__left_actions'>
            <button
              onClick={handleExport}
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              Download
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}
