import { useQuery, keepPreviousData } from '@tanstack/react-query';
import {
  getAssetAnalytics,
  getAssetsData,
  getAssetsGapGraphDecision,
  getAssetsGraph,
  getAssetsGraphDecision,
  getAssetTransfer,
  getGapAnalytics,
} from './api';

export const useGetAssetsData = (data, page, limit) => {
  return useQuery({
    queryKey: ['assets', { data, page }],
    queryFn: () => getAssetsData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetAssetsAnalytics = () => {
  return useQuery({
    queryKey: ['assetAnalytics'],
    queryFn: () => getAssetAnalytics(),
  });
};

export const useGetGapAnalytics = () => {
  return useQuery({
    queryKey: ['gapAnalytics'],
    queryFn: () => getGapAnalytics(),
  });
};

export const useGetAssetTransfer = (page, limit) => {
  return useQuery({
    queryKey: ['assetTransfers', { page }],
    queryFn: () => getAssetTransfer(page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetAssetsGraph = (data) => {
  return useQuery({
    queryKey: ['assetGraph', { data }],
    queryFn: () => getAssetsGraph(data),
  });
};

export const useGetAssetsGraphDecision = (data) => {
  return useQuery({
    queryKey: ['assetGraphDecision', { data }],
    queryFn: () => getAssetsGraphDecision(data),
  });
};

export const useGetAssetsGapGraphDecision = (data) => {
  return useQuery({
    queryKey: ['assetGapGraphDecision', { data }],
    queryFn: () => getAssetsGapGraphDecision(data),
  });
};
