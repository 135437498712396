import React, { useEffect, useState } from "react";

import ColdChainTableCard from "../../coldChain/coldChainTable/ColdChainTableCard";
import { getEquipmentList } from "../../../../redux/inventoryActions";
import { useTranslation } from "react-i18next";
import AnalyticTile from "../../../../shared/utils/analyticTile/AnalyticTile";
import { useGetAssetsData } from "../../../../services/coldchain-services/queries";
import { useSearchParams } from "react-router";
import EquipmentTable from "../../coldChain/coldChainTable/EquipmentTable";
import TabContainer from "../dataGraph/containers/TabContainer";

export default function ColdChainTable({ tab, setTab, type }) {
  const [currentTab, setCurrentTab] = useState("summary");
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const apiBody = {
    locations: searchParams.get("locations").split(","),
  };

  const { data: equipmentData } = useGetAssetsData(apiBody, page, limit);

  return (
    <section className='ColdChainTable'>
      <TabContainer currentTab={tab} setCurrentTab={setTab} type={type} />
      {/* <div className='Page__tab_select_space'>
        <div className='tab__btn_group'>
          <AnalyticTile
            state='summary'
            title={t('asset_information')}
            tab={currentTab}
            setTab={setCurrentTab}
          />
        </div>
      </div> */}
      <div className='ColdChainTable__body'>
        <EquipmentTable
          equipmentData={equipmentData}
          page={page}
          handleChangePage={handleChangePage}
          limit={limit}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </section>
  );
}
