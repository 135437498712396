export const buildParams = (data, page, limit) => {
  const params = {
    locations: JSON.stringify(data.locations),
    page: page + 1,
    limit: limit,
  };

  if (data.fromDate) {
    params.fromDate = data.fromDate;
  }

  if (data.toDate) {
    params.toDate = data.toDate;
  }

  return params;
};

export const buildParamsWithPagination = (data, status, isTransfer) => {
  const params = {
    locationIds: data.locations,
  };

  if (data.fromDate) {
    params.fromDate = data.fromDate;
  }

  if (data.toDate) {
    params.toDate = data.toDate;
  }

  if (status) {
    params.status = status;
  }
  if (isTransfer) {
    params.isTransfer = isTransfer;
  }

  return params;
};

export const buildParamsForAssetDecision = (data) => {
  const params = {
    orgLevel: data.orgLevel,
  };
  if (data.locationIds) {
    params.locationIds = data.locationIds;
  }

  if (data.status) {
    params.status = data.status;
  }
  if (data.groupByLocation) {
    params.groupByLocation = data.groupByLocation;
  }
  if (data.groupByProduct) {
    params.groupByProduct = data.groupByProduct;
  }
  if (data.productId) {
    params.productId = data.productId;
  }
  if (data.sortOrder) {
    params.sortOrder = data.sortOrder;
  }

  return params;
};

export function formatBDPhoneNumber(phoneNumber) {
  const cleaned = phoneNumber?.replace(/\D/g, '');

  if (cleaned.length === 13 && cleaned.startsWith('880')) {
    return `+${cleaned.slice(0, 3)} ${cleaned.slice(3, 5)} ${cleaned.slice(
      5,
      9
    )} ${cleaned.slice(9)}`;
  }

  return phoneNumber;
}

export const calculateDateDifference = (data) => {
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // JavaScript months are 0-indexed
  };

  if (!data || data.length === 0) {
    return null; // Return null if no data is passed
  }

  // Parse and sort dates
  const sortedDates = data
    .map((item) => parseDate(item.date))
    .sort((a, b) => a - b);

  const startDate = sortedDates[0];
  const endDate = sortedDates[sortedDates.length - 1];

  const diffInMilliseconds = endDate - startDate;
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  if (diffInDays >= 365) {
    const years = Math.floor(diffInDays / 365);
    if (!isNaN(years) && years > 0) {
      return `${years} years`;
    }
  } else if (diffInDays >= 30) {
    const months = Math.floor(diffInDays / 30);
    if (!isNaN(months) && months > 0) {
      return `${months} months`;
    }
  } else if (diffInDays >= 7) {
    const weeks = Math.floor(diffInDays / 7);
    if (!isNaN(weeks) && weeks > 0) {
      return `${weeks} weeks`;
    }
  }

  if (!isNaN(diffInDays) && diffInDays > 0) {
    return `${diffInDays} days`;
  } else {
    return undefined;
  }
};
