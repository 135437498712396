import React from "react";
import "./NotificationCard.css";
import { Tooltip } from "@mui/material";
import { useUpdateNotificationMsg } from "../../../services/other-services/mutations";
import { useNavigate } from "react-router";

export default function NotificationCard({
  data,
  type,
  eventType,
  title,
  body,
  isCritical,
  isRead,
  date,
  setNotificationDropDownOpen,
}) {
  const navigate = useNavigate();
  const { mutate } = useUpdateNotificationMsg();

  const handleReadStatus = (id, eventType) => {
    mutate(id, {
      onSuccess: (response) => {
        navigate(handleRedirection(eventType));
        setNotificationDropDownOpen(false);
      },
    });
  };

  const handleRedirection = (eventType) => {
    switch (eventType) {
      case "SHIPMENT":
        return "/user/shipments";
      case "ORDER":
        return "/user/orders";
      case "INVENTORY":
        return "/user/inventory";
      case "USER":
        return "/user/services/profile";

      default:
        return "/user/dashboard";
    }
  };

  return (
    <React.Fragment>
      <div
        className={`NotificationFieldBody__row ${isRead && "Readed"}`}
        onClick={() => handleReadStatus(data?._id, eventType)}
      >
        <div className='NotificationFieldBody__body'>
          <div className='NotificationFieldBody__header'>
            <h1 className='NotificationFieldBody__heading_fs'>
              {eventType}: {title}
            </h1>
            <div className='NotificationFieldBody__marks'>
              {type === "ALERT" && (
                <Tooltip title='Alerts' placement='top-end' arrow>
                  <div>
                    <i className='fa-solid fa-circle-info alerts_icon'></i>
                  </div>
                </Tooltip>
              )}

              {isCritical && (
                <Tooltip title='Critical' placement='top-end' arrow>
                  <div>
                    <i className='fa-solid fa-triangle-exclamation critical_icon'></i>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <p className='NotificationFieldBody__body_fs'>{body}</p>
        </div>
        <div className='Notification__timestamp'>
          <p className='Notification__timestamp_fs'>
            {new Date(date).toLocaleDateString()}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
