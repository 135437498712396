import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { capitalToNormalCase, timeAgo } from "../../../../shared/utils/utils";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function InvStockOut({
  inventory,
  filteredColumns,
  totalRecords,
  page,
  setPage,
  limit,
  setLimit,
  tableRef,
  typePage,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 486 }} ref={tableRef}>
          <TableHead className='mi_table__head'>
            <TableRow>
              {/* <TableCell>
                <FieldHead title="Product Category" />
              </TableCell>
              <TableCell>
                <FieldHead title="Product Name" />
              </TableCell>
              <TableCell>
                <FieldHead title="Product ID" />
              </TableCell>
              <TableCell>
                <FieldHead title="Manufacturer" />
              </TableCell>

              <TableCell>
                <FieldHead title="Stock Out(days)" />
              </TableCell>
              <TableCell align="center">
                <FieldHead title="Actions" />
              </TableCell> */}
              {filteredColumns.map((column) => (
                <TableCell key={column.key}>
                  <FieldHead
                    title={t(column.title)}
                    align={column.key === "action" ? "center" : "start"}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {inventory ? (
              <>
                {inventory?.length > 0 ? (
                  <>
                    {inventory?.map((row, i) => (
                      <InvSummaryRow
                        row={row}
                        key={i}
                        filteredColumns={filteredColumns}
                        t={t}
                        typePage={typePage}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: "2rem" }} colSpan={6}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell
                  style={{ padding: "2rem" }}
                  colSpan={6}
                  align='center'
                >
                  <div className='Loading_message'>
                    <EmptyTable />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

function InvSummaryRow({ row, filteredColumns, t, typePage }) {
  const Navigate = useNavigate();

  const newArray = [row].map((item) => ({
    productId: item?.product?.[0]?._id,
    name: item?.product?.[0]?.name,
    units: item?.product?.[0]?.units,
    type: item?.product?.[0]?.type,
    quantity: item?.quantity,
    mfgDate: item?.product?.[0]?.createdAt, // Replace with the actual manufacturing date if available
    expDate: "", // Replace with the actual expiration date if available
    fromPage: "ReOrder",
  }));

  const handleReOrder = () => {
    Navigate("/user/orders/review-orders", {
      state: { products: newArray },
    });
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        {filteredColumns.map((column) => (
          <React.Fragment key={column?.key}>
            {column.key === "productCategory" && (
              <TableCell>
                <FieldBody
                  text={capitalToNormalCase(row?.product?.[0]?.type)}
                />
              </TableCell>
            )}
            {column.key === "productName" && (
              <TableCell>
                <ProductIcon
                  type={row?.product?.[0]?.type}
                  name={row?.product?.[0]?.name}
                  size='tiny'
                  fallback={
                    <FieldBody text={row?.product?.[0]?.name} color='bold' />
                  }
                />
              </TableCell>
            )}
            {column.key === "productId" && (
              <TableCell>
                <FieldBody text={row?.product?.[0]?.id} color='bold' />
              </TableCell>
            )}
            {/* {column.key === "manufacturer" && (
              <TableCell>
                <FieldBody text={row?.product?.[0]?.manufacturer?.name} />
              </TableCell>
            )} */}
            {column.key === "stockOut" && (
              <TableCell>
                {row?.lastOutOfStock ? (
                  <FieldBody subtext={timeAgo(row?.lastOutOfStock)} />
                ) : (
                  <FieldBody subtext={"- - -"} />
                )}
              </TableCell>
            )}
            {column.key === "action" && (
              <>
                {typePage !== "dataVisualization" ? (
                  <TableCell>
                    <div className='Table__actions'>
                      <button
                        className='mi_btn mi_btn_medium mi_btn_variant_green'
                        onClick={handleReOrder}
                      >
                        <span>{t("reorder")}</span>
                      </button>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell align='center'>---</TableCell>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </React.Fragment>
  );
}
