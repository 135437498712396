'use client';

import { TrendingUp } from 'lucide-react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
  AreaChart,
  Area,
  LineChart,
  Line,
} from 'recharts';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

export default function GapGraph({ data, checked }) {
  if (!data || data.length === 0) return <p>No data available</p>;

  const chartConfig = {
    totalCapacity: { label: 'Total Capacity', color: '#4F46E5' },
    totalUsed: { label: 'Total Used', color: '#E57373' },
  };

  return (
    <div className='Graph_list grid'>
      <Card>
        <CardHeader>
          <section className='w-full flex items-center justify-between'>
            <article className='flex flex-col items-start justify-start'>
              <div className='flex gap-2 text-base font-medium leading-none'>
                Total Capacity vs Current Capacity Stacked
              </div>

              <div className='leading-none text-xs text-muted-foreground'>
                Efficiency per Location
              </div>
            </article>
          </section>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart
              data={
                checked
                  ? data.map((item) => ({
                      ...item,
                      location: `${item.location}`,
                    }))
                  : data
              }
              barGap={8}
              barCategoryGap={20}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='location'
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis tickLine={false} tickMargin={10} axisLine={false} />
              <Tooltip content={<ChartTooltipContent />} />
              <Legend content={<ChartLegendContent />} />

              <Bar
                dataKey='totalCapacity'
                fill='#4F46E5'
                stackId='a'
                radius={[0, 0, 4, 4]}
              >
                <LabelList
                  dataKey='gap'
                  position='top'
                  formatter={(val) => `${(val * 100).toFixed(2)}%`}
                />
              </Bar>
              <Bar
                dataKey='totalUsed'
                fill='#E57373'
                stackId='a'
                radius={[0, 0, 4, 4]}
              />
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <section className='w-full flex items-center justify-between'>
            <article className='flex flex-col items-start justify-start'>
              <div className='flex gap-2 text-base font-medium leading-none'>
                Total Capacity vs Current Capacity Lined
              </div>

              <div className='leading-none text-xs text-muted-foreground'>
                Efficiency per Location
              </div>
            </article>
          </section>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <LineChart
              data={
                checked
                  ? data.map((item) => ({
                      ...item,
                      location: `${item.location}`,
                    }))
                  : data
              }
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='location'
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis />
              <Tooltip content={<ChartTooltipContent />} />
              <Legend content={<ChartLegendContent />} />

              <Line
                type='monotone'
                dataKey='totalCapacity'
                stackId='1'
                fill='#4F46E5'
                stroke='#4F46E5'
              />
              <Line
                type='monotone'
                dataKey='totalUsed'
                stackId='1'
                fill='#E57373'
                stroke='#E57373'
              />
            </LineChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
}
