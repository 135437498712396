import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";

import Shipment from "/icons/all/shipments/Shipment.svg";
import ProductInfo from "./ProductInfo";
import TransferAddressCard from "../../components/orderAddressCard/TransferAddressCard";
import { getUserLocationInfo } from "../../../redux/orderActions";
import { createShipment } from "../../../redux/shipmentActions";
import dayjs from "dayjs";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getUserLocations } from "../../../redux/inventoryActions";

export default function ReverseShipment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stateInfo = useLocation();
  const productInfo = stateInfo?.state?.productInfo;
  const [sendingQty, setSendingQty] = React.useState();
  const [location, setLocation] = React.useState();

  const [deliveryLocation, setDeliveryLocation] = React.useState();
  const [deliveryLocationList, setDeliveryLocationList] = React.useState();

  const [shipmentDate, setShipmentDate] = React.useState(
    dayjs().format("YYYY-MM-DD"),
  );
  const [deliveryDate, setDeliveryDate] = React.useState(
    dayjs().format("YYYY-MM-DD"),
  );

  async function fetchData() {
    const result = await getUserLocationInfo();
    setLocation(result?.data);
    const res = await getUserLocations();
    setDeliveryLocationList(res?.data?.childLocations);
  }
  React.useEffect(() => {
    fetchData();
  }, []);

  const reverseApiData = {
    source: location?._id,
    destination: deliveryLocation,
    products: [
      {
        productId: productInfo?.productId?._id,
        batches: [
          {
            atomId: productInfo?._id,
            quantity: parseInt(sendingQty),
          },
        ],
      },
    ],
    shippingDate: shipmentDate,
    deliveryDate: deliveryDate,
  };

  const handleReverseShipment = async () => {
    const res = await createShipment(reverseApiData);

    if (res.success) {
      navigate("/user/inventory");
      dispatch(
        callPopup({
          open: true,
          type: "success",
          page: "shipments",
          message: "Reverse Supply Created Successfully",
          action: "/user/inventory",
        }),
      );
    } else {
      dispatch(
        callPopup({
          open: true,
          type: "error",
          page: "orders",
          title: res.data.message,
          message: res.data.data,
        }),
      );
    }
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={Shipment} className='Campaign__icon' />
            <h1 className='Page__headline_title_fs'>{t("forward_supply")}</h1>
          </div>
          <BreadCrumb
            url1='/user/orders'
            Link1={t("shipments")}
            Link2={`${t("reverse_shipments")}`}
          />
        </div>

        <div className='CreateShipment__grid_layout'>
          <div className='ModalPopup__form_section'>
            <div className='ModalPopup__label_header'>
              <h1 className='ModalPopup__form_section_heading_fs'>
                {t("delivery_details")}
              </h1>
            </div>

            <div className='Input__row two_column'>
              <div className='Input__column'>
                <p className='Input__label_fs'>{t("shipment_date")}</p>
                <input
                  type='date'
                  className='mi_custom_textfield date_field'
                  id='shipmentDate'
                  name='shipmentDate'
                  min={!deliveryDate && dayjs().format("YYYY-MM-DD")}
                  max={deliveryDate}
                  value={shipmentDate}
                  onChange={(event) => {
                    setShipmentDate(event.target.value);
                  }}
                />
              </div>
              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("estimated_delivery_date")}
                </p>
                <input
                  type='date'
                  className='mi_custom_textfield date_field'
                  id='deliveryDate'
                  name='deliveryDate'
                  min={shipmentDate}
                  value={deliveryDate}
                  onChange={(event) => {
                    setDeliveryDate(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='Page__select_tab_wrapper'>
          <div className='Page__select_tab_header'>
            <div className='Select__tab_headline'>
              <h1 className='page__body_heading_fs'>{t("product_list")}</h1>
            </div>
          </div>
          <div>
            <ProductInfo
              product={productInfo}
              sendingQty={sendingQty}
              setSendingQty={setSendingQty}
            />
          </div>
        </div>

        <section className='Order__summary_card'>
          <div className='Order__summary_body'>
            <article className='Order__summary_body_list'>
              <div className='Order__flex address_flex'>
                <h1 className='page__body_heading_fs'>
                  {t("supplier_location")}
                </h1>
              </div>
              <div className='summary_body_content_card'>
                <h1 className='page__body_fs mi_bold'>{t("type")}</h1>
                <h1 className='page__notion'>:</h1>
                <p className='page__body_fs '>{location?.orgLevel}</p>
              </div>
              <div className='summary_body_content_card'>
                <h1 className='page__body_fs mi_bold'>{t("name")}</h1>
                <h1 className='page__notion'>:</h1>
                <p className='page__body_fs '>{location?.name}</p>
              </div>
              <div className='summary_body_content_card'>
                <h1 className='page__body_fs mi_bold'>{t("address")}</h1>
                <h1 className='page__notion'>:</h1>
                <p className='page__body_fs max_width'>
                  {location?.postalAddress +
                    ", " +
                    location?.division +
                    ", " +
                    location?.district}
                </p>
              </div>
            </article>
            <div className='Input__column'>
              <h1 className='page__body_heading_fs location__heading'>
                {t("delivery_location")}
                <b>*</b>
              </h1>
              <FormControl fullWidth>
                <Select
                  id='delivery'
                  name='delivery'
                  size='small'
                  style={{ width: "20rem" }}
                  className='mui_custom_input_field'
                  onChange={(e) => {
                    setDeliveryLocation(e.target.value._id);
                  }}
                  value={deliveryLocation}
                >
                  {deliveryLocationList?.length &&
                    deliveryLocationList?.map((row, index) => (
                      <MenuItem key={index + 1} value={row}>
                        {row?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </section>

        <section className='InventoryOrders__flex_space_actions'>
          <div className='order__remainder_space'> </div>

          <div className='Page__left_actions'>
            <>
              <button
                className='mi_btn mi_btn_medium mi_btn_outline'
                onClick={() => navigate(-1)}
              >
                <span>{t("back")}</span>
              </button>
              <button
                onClick={handleReverseShipment}
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <span>{t("confirm")}</span>
              </button>
            </>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}
