import React from 'react';
import BarGraph from '../../dataVisualization/dataGraph/InvGraphs/BarGraph';

import LineGraph from '../../dataVisualization/dataGraph/InvGraphs/LineGraph';
import AreaGraph from '../../dataVisualization/dataGraph/InvGraphs/AreaGraph';

export default function NearExpiry({ data, checked, expiredData }) {
  const bars = [{ dataKey: 'totalNearExpiry', fill: '#4F46E5' }];
  const expiredBars = [{ dataKey: 'totalExpired', fill: '#E57373' }];


  return (
    <div className='Graph_list grid'>
      <BarGraph
        data={
          checked
            ? data?.data.map((item) => ({
                ...item,
                location: `${item.product} - ${item.location}`,
              }))
            : data?.data
        }
        xAxisKey={'location'}
        bars={bars}
        title='Near Expiry Decision'
        height={200}
        chartConfig={{
          margin: { top: 20, right: 30, left: 20, bottom: 5 },
        }}
        graphType='Bars'
        setGraphType={() => {}}
        noLegends={true}
      />
      <BarGraph
        data={
          checked
            ? expiredData?.data.map((item) => ({
                ...item,
                location: `${item.product} - ${item.location}`,
              }))
            : expiredData?.data
        }
        xAxisKey={'location'}
        bars={expiredBars}
        title='Expired Decision'
        height={200}
        chartConfig={{
          margin: { top: 20, right: 30, left: 20, bottom: 5 },
        }}
        graphType='Bars'
        setGraphType={() => {}}
        noLegends={true}
      />
      {/* <AreaGraph
        data={
          checked
            ? data?.data.map((item) => ({
                ...item,
                name: `${item.product} - ${item.location}`,
              }))
            : data?.data
        }
        xAxisKey={'location'}
        areas={lines}
        title='Near Expiry Decision'
        height={200}
        chartConfig={{
          margin: { top: 20, right: 30, left: 20, bottom: 5 },
        }}
        graphType='Bars'
        setGraphType={() => {}}
      /> */}
    </div>
  );
}
