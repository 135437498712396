import React from "react";
import { Link, useNavigate } from "react-router";
import "./AdjustmentMessage.css";

// Image Import
import OnboardImage from "/illustrations/waiting-img.svg";
import { useTranslation } from "react-i18next";
import { capitalToNormalCase } from "../../../shared/utils/utils";

export default function AdjustmentMessage({ from, to }) {
  const Navigate = useNavigate();

  const statusMessages = {
    ACTIVE: {
      OPEN: "Updated Batches will move to the current stock tab with status as OPEN.",
      DAMAGED:
        "The Updated batches has been marked as DAMAGED in rejected qty tab.",
      LOST: "This item has been marked as LOST in rejected qty tab.",
      COLD_CHAIN_FAILURE:
        "The Updated batches has been marked as COLD CHAIN FAILURE in rejected qty tab.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
      QUARANTINE:
        "Updated Batches will move to the quarantine tab with status as QUARANTINE.",
    },
    EXPIRED: {
      DISPOSED:
        "Updated Batches will move to the quarantine tab with status as DISPOSED.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
    },
    EXPIRED_IN_TRANSIT: {
      DISPOSED:
        "Updated Batches will move to the quarantine tab with status as DISPOSED.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
    },
    OPEN: {
      DAMAGED: "This item has been marked as DAMAGED in rejected qty tab.",
      LOST: "The Updated batches has been marked as LOST in rejected qty tab.",
      COLD_CHAIN_FAILURE:
        "The Updated batches has been marked as COLD CHAIN FAILURE in rejected qty tab.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
      QUARANTINE:
        "Updated Batches will move to the quarantine tab with status as QUARANTINE.",
    },
    DAMAGED: {
      ACTIVE:
        "Updated Batches will move to the current stock tab with status as ACTIVE.",
      DISPOSED:
        "Updated Batches will move to the quarantine tab with status as DISPOSED.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
    },
    LOST: {
      ACTIVE:
        "Updated Batches will move to the current stock tab with status as ACTIVE.",
      DISPOSED:
        "Updated Batches will move to the quarantine tab with status as DISPOSED.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
    },
    COLD_CHAIN_FAILURE: {
      ACTIVE:
        "Updated Batches will move to the current stock tab with status as ACTIVE.",
      DISPOSED:
        "Updated Batches will move to the quarantine tab with status as DISPOSED.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
    },
    RECALL: {
      LOST: "The Updated batches has been marked as LOST in rejected qty tab.",
      DISPOSE:
        "Updated Batches will move to the quarantine tab with status as DISPOSED.",
    },
    QUARANTINE: {
      ACTIVE:
        "Updated Batches will move to the current stock tab with status as ACTIVE.",
      DAMAGED:
        "The Updated batches has been marked as DAMAGED in rejected qty tab.",
      LOST: "The Updated batches has been marked as LOST in rejected qty tab.",
      COLD_CHAIN_FAILURE:
        "The Updated batches has been marked as COLD CHAIN FAILURE in rejected qty tab.",
      RECALL:
        "Updated Batches will move to the quarantine tab with status as RECALL.",
      DISPOSED:
        "Updated Batches will move to the quarantine tab with status as DISPOSED.",
    },
  };

  const getMessageForStatus = (type, status) => {
    return statusMessages[type][status] || "Unknown status";
  };

  return (
    <React.Fragment>
      <div className='AdjustmentMessage__container'>
        <div className='Onboarding__wrapper'>
          <div className='Authform__wrapper single_column'>
            <div className='Adjust__banner'>
              <img src={OnboardImage} alt='OnboardImage' />
            </div>
          </div>
          <div className='AdjustmentMessage_wrapper'>
            <div className='Onboarding__content'>
              <div className='Onboarding__content_headline'>
                <div className='Onboarding__flex'>
                  <h1 className='auth_heading_fs'>Adjustment Request Sent</h1>
                </div>

                <p className='auth_subheading_fs'>
                  Your inventory adjustment request has been sent for approval.
                  Please wait for further instructions.
                </p>

                <div className='approval_status'>
                  <h1 className='page__body_subheading_fs'>
                    {capitalToNormalCase(from)}
                  </h1>
                  <i className='fa-solid fa-arrow-right-long'></i>
                  <h1 className='page__body_subheading_fs'>
                    {capitalToNormalCase(to)}
                  </h1>
                </div>

                <div className='note_message_wrapper'>
                  <p className='page__body_fs'>
                    <strong>Note : </strong>After Approval,{" "}
                    {getMessageForStatus(from, to)}
                  </p>
                </div>
              </div>
              <div className='Onboarding__content_actions'>
                <button
                  className='mi_btn mi_btn_medium mi_btn_primary'
                  onClick={() => Navigate("/user/inventory")}
                >
                  <span> Back to Inventory </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
