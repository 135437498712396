import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { createAlarms } from '@/redux/inventoryActions';

const validationSchema = Yup.object({
  heatAlarms10Hours: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Cannot be negative')
    .required('Required'),
  freezeAlarms1Hour: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Cannot be negative')
    .required('Required'),
  heatAlarms48Hours: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Cannot be negative')
    .required('Required'),
  // failureReason: Yup.string().required('Required'),
  // systemAreaFailed: Yup.string().required('Required'),
  // actionTaken: Yup.string().required('Required'),
  // partsReplaced: Yup.string().required('Required'),
});

export default function AlarmData({ assetData }) {
  const formik = useFormik({
    initialValues: {
      heatAlarms10Hours: '',
      freezeAlarms1Hour: '',
      heatAlarms48Hours: '',
      failureReason: '',
      systemAreaFailed: '',
      actionTaken: '',
      partsReplaced: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log('Form submitted with values:', values);

      const apiData = {
        assetId: assetData?._id,
        heatAlarms: {
          over10hours: values.heatAlarms10Hours,
          over48hours: values.heatAlarms48Hours,
        },
        freezeAlarms: {
          over1hrs: values.freezeAlarms1Hour,
        },
        failure: {
          reason: values.failureReason,
          failedParts: values.partsReplaced,
          replacedParts: values.systemAreaFailed,
          actionTaken: values.actionTaken,
        },
      };

      const fail = {
        reason: values.failureReason,
        failedParts: values.partsReplaced,
        replacedParts: values.systemAreaFailed,
        actionTaken: values.actionTaken,
      };

      const time = new Date();

      const newData = [{ ...fail, time }];

      console.log(newData);

      const res = await createAlarms(apiData);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className='FunctionalData__form'>
      <div className='FunctionalData__card'>
        <div className='FunctionalData__header'>
          <h1 className='FunctionalData_heading_fs'>Functional Status</h1>
        </div>
        <div className='FunctionalData__body extra__bottom_space'>
          <section className='FunctionalData__row_group'>
            {[
              {
                label:
                  'How many heat alarms with over 10-hour duration above 8°C are recorded?',
                field: 'heatAlarms10Hours',
              },
              {
                label:
                  'How many freeze alarms with over 1-hour duration below -0.5°C are recorded?',
                field: 'freezeAlarms1Hour',
              },
              {
                label:
                  'How many heat alarms with 48-hour or longer duration are recorded?',
                field: 'heatAlarms48Hours',
              },
            ].map(({ label, field }) => (
              <div className='FunctionalData__row' key={field}>
                <article className='Input__column'>
                  <p className='Input__label_fs'>{label}</p>
                  <div className='Input__column_single_col_width'>
                    <input
                      type='text'
                      placeholder='Enter here...'
                      {...formik.getFieldProps(field)}
                      className='mi_custom_textfield'
                    />
                    {formik.touched[field] && formik.errors[field] ? (
                      <div className='Input__error_fs'>
                        {formik.errors[field]}
                      </div>
                    ) : null}
                  </div>
                </article>
              </div>
            ))}
          </section>
          <section className='FunctionalData__row_group'>
            <div className='FunctionalData__row four_column'>
              {[
                {
                  label: 'Why did the refrigerator fail?',
                  field: 'failureReason',
                },
                {
                  label: 'What system/area failed?',
                  field: 'systemAreaFailed',
                },
                { label: 'What action was taken?', field: 'actionTaken' },
                { label: 'What parts were replaced?', field: 'partsReplaced' },
              ].map(({ label, field }) => (
                <article className='Input__column' key={field}>
                  <p className='Input__label_fs'>{label}</p>
                  <input
                    type='text'
                    placeholder='Enter here...'
                    {...formik.getFieldProps(field)}
                    className='mi_custom_textfield'
                  />
                  {formik.touched[field] && formik.errors[field] ? (
                    <div className='Input__error_fs'>
                      {formik.errors[field]}
                    </div>
                  ) : null}
                </article>
              ))}
            </div>
          </section>
          <div className='FunctionalData__actions mt-3 mr-1'>
            <button
              type='submit'
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              <span>Submit Alarm Data</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
