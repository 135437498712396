import { TrendingUp } from 'lucide-react';
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

export default function SupplyGraphs({ data, checked }) {
  const chartConfig = {
    supplyQuantity: {
      label: 'Supply Quantity',
      color: '#10b981',
    },
    orderQuantity: {
      label: 'Order Quantity',
      color: '#2563eb',
    },
    mismatch: {
      label: 'Mismatch',
      color: '#fda4af',
    },
  };
  return (
    <div className='Graph_list grid'>
      <Card>
        <CardHeader>
          <section className='w-full flex items-center justify-between'>
            <article className='flex flex-col items-start justify-start'>
              <div className='flex gap-2 text-base font-medium leading-none'>
                Supply vs Order Bar Chart
              </div>

              <div className='leading-none text-xs text-muted-foreground'>
                Comparison of supply and order quantities
              </div>
            </article>
          </section>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart
              accessibilityLayer
              data={
                checked
                  ? data.map((item) => ({
                      ...item,
                      location: `${item.product} - ${item.location}`,
                    }))
                  : data
              }
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='location'
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator='dashed' />}
              />
              <Bar dataKey='supplyQuantity' fill='#10b981' radius={4} />
              <Bar dataKey='orderQuantity' fill='#2563eb' radius={4} />
              <Bar dataKey='mismatch' fill='#fda4af' radius={4} />
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <section className='w-full flex items-center justify-between'>
            <article className='flex flex-col items-start justify-start'>
              <div className='flex gap-2 text-base font-medium leading-none'>
                Supply vs Order Line Chart
              </div>

              <div className='leading-none text-xs text-muted-foreground'>
                Comparison of supply and order quantities
              </div>
            </article>
          </section>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <LineChart
              accessibilityLayer
              data={
                checked
                  ? data.map((item) => ({
                      ...item,
                      location: `${item.product} - ${item.location}`,
                    }))
                  : data
              }
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='location'
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator='dashed' />}
              />
              <Line
                type='monotone'
                dataKey='supplyQuantity'
                stroke='var(--color-supply)'
                strokeWidth={2}
              />
              <Line
                type='monotone'
                dataKey='orderQuantity'
                stroke='var(--color-order)'
                strokeWidth={2}
              />
              <Line
                type='monotone'
                dataKey='mismatch'
                stroke='var(--color-mismatch)'
                strokeWidth={2}
              />
            </LineChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
}
