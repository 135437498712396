import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

export default function InventorySummary({ inventory }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead className='mi_table__head th_downborder'>
            <TableRow>
              <TableCell align='left'>
                <FieldHead title={t("product_name")} />
              </TableCell>
              <TableCell align='center'>
                <FieldHead title={t("quantity")} align={"center"} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory ? (
              <>
                {inventory?.length > 0 ? (
                  <>
                    {inventory?.map((row) => (
                      <TableBodyRow row={row} key={row?._id} index={row?._id} />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: "2rem" }} colSpan={2}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: 0 }} colSpan={2} align='center'>
                  <div className='Loading_message'>
                    <p className='page__note_fs'>{t("loading")}...</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function TableBodyRow({ row, index }) {
  return (
    <React.Fragment>
      <TableRow
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align='left'>
          <FieldBody
            text={row?.product?.name}
            subtext={row?.product?.type}
            color='bold'
          />
        </TableCell>
        <TableCell align='center'>
          <FieldBody text={row?.quantity + " " + row?.product?.units} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
