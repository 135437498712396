import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { getLocations, getOrganizationLevel } from "@/redux/userActions";
import {
  useGetAssetsGapGraphDecision,
  useGetAssetsGraphDecision,
} from "@/services/coldchain-services/queries";
import {
  FormControl,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import BarGraph from "../../dataVisualization/dataGraph/InvGraphs/BarGraph";
import OrderIcon from "/icons/all/orders/Orders.svg";
import AssetBarChart from "../graphs/BarStackedGraph";
import LineGraph from "../../dataVisualization/dataGraph/InvGraphs/LineGraph";
import AssetGraph from "../graphs/AssetGraph";
import GapGraph from "../graphs/GapGraph";
import { Button } from "@/components/ui/button";

export const ASSET_STATUS_ENUM = [
  "WORKING",
  "OUT_OF_SERVICE",
  "INTACT",
  "MAINTENANCE",
  "REPAIRABLE",
  "NOT_REPAIRABLE",
  "DISPOSED",
  "TRANSIT",
];

export default function ColdchainDecision() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentGraph, setCurrentGraph] = useState("Asset Status");

  const [levels, setLevels] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedOrgLevel, setSelectedOrgLevel] = useState("All Levels");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [checked, setChecked] = useState(false);
  const [sorted, setSorted] = useState(false);

  useEffect(() => {
    async function fetchLevels() {
      const resultLevel = await getOrganizationLevel();
      setLevels(resultLevel?.data || []);
    }
    fetchLevels();
  }, []);

  useEffect(() => {
    async function fetchLocations() {
      if (selectedOrgLevel) {
        const res = await getLocations(selectedOrgLevel);
        setLocations(res?.data || []);
      }
    }
    fetchLocations();
  }, [selectedOrgLevel]);

  const districtOptions = useMemo(
    () =>
      locations.map((district) => ({ id: district._id, name: district.name })),
    [locations],
  );

  const {
    data: createdData,
    isLoading,
    isError,
  } = useGetAssetsGraphDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    status: selectedStatus === "All Statuses" ? null : selectedStatus,
    groupByLocation: checked,
  });

  const { data: gapData } = useGetAssetsGapGraphDecision({
    orgLevel: selectedOrgLevel === "All Levels" ? null : selectedOrgLevel,
    locationIds: selectedLocations,
    status: selectedStatus === "All Statuses" ? null : selectedStatus,
    groupByLocation: checked,
    sortOrder: sorted ? "ASC" : "DESC",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/user/decision-making/graphs");
  };

  if (isLoading) return <>Loading...</>;
  if (isError) return <div>Error loading data</div>;

  return (
    <section>
      <div className='Page__action_wrapper'>
        <div className='DataVisualization__container'>
          <form
            onSubmit={handleSubmit}
            className='dataviz_filters Input__table'
          >
            <div className='Input__row four_equal_column'>
              <div className='Input__column'>
                <p className='Input__label_fs'>{t("org_level")}</p>
                <FormControl fullWidth>
                  <Select
                    size='small'
                    value={selectedOrgLevel}
                    onChange={(e) => setSelectedOrgLevel(e.target.value)}
                  >
                    <MenuItem value={"All Levels"}>All Levels</MenuItem>
                    {levels.map((level, index) => (
                      <MenuItem key={index} value={level.value}>
                        {level.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("location")} <b>*</b>
                </p>
                <Autocomplete
                  multiple
                  size='small'
                  options={districtOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={districtOptions.filter((option) =>
                    selectedLocations.includes(option.id),
                  )}
                  onChange={(event, value) =>
                    setSelectedLocations(value.map((option) => option.id))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      variant='outlined'
                      placeholder='Select locations'
                    />
                  )}
                  disabled={!selectedOrgLevel}
                />
              </div>

              <div className='Input__column w-96'>
                <p className='Input__label_fs'>{t("status")}</p>
                <FormControl fullWidth>
                  <Select
                    size='small'
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <MenuItem value='All Statuses'>All Statuses</MenuItem>
                    {ASSET_STATUS_ENUM.map((status, index) => (
                      <MenuItem key={index} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className='mt-6 flex gap-4 items-center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      name='gilad'
                    />
                  }
                  label='Group by Location'
                />
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sorted}
                        onChange={() => setSorted(!sorted)}
                      />
                    }
                    label='Sort the Data'
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className='flex items-center gap-4 mb-4'>
        <Button
          variant={currentGraph === "Asset Status" ? "" : "outline"}
          onClick={() => setCurrentGraph("Asset Status")}
        >
          Asset Status
        </Button>
        <Button
          variant={
            currentGraph === "Total Capacity vs Current Capacity"
              ? ""
              : "outline"
          }
          onClick={() => setCurrentGraph("Total Capacity vs Current Capacity")}
        >
          Total Capacity vs Current Capacity
        </Button>
      </div>

      {currentGraph === "Asset Status" && (
        <AssetGraph createdData={createdData} checked={checked} />
      )}

      {currentGraph === "Total Capacity vs Current Capacity" && (
        <GapGraph data={gapData?.data} checked={checked} />
      )}
    </section>
  );
}
