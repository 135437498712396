import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";
import { capitalToNormalCase } from "../../../../shared/utils/utils";
import { quarantineInventory } from "../../../../redux/inventoryActions";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import { useNavigate } from "react-router";
import { useDisposeProducts } from "../../../../services/inventory-services/mutations";
import toast from "react-hot-toast";

export default function InvQuarantine({
  inventory,
  filteredColumns,
  page,
  setPage,
  limit,
  setLimit,
  tableRef,
  typePage,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();

  const [data, setData] = React.useState();
  const [fetchAPIData, setFetchAPIData] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await quarantineInventory(page, limit);
      setData(res.data);
    };
    if (typePage !== "dataVisualization") {
      fetchData();
    }
  }, [fetchAPIData, page, limit]);

  const apiData = typePage === "dataVisualization" ? inventory : data;

  return (
    <React.Fragment>
      <TableContainer>
        <Table ref={tableRef} sx={{ minWidth: 486 }}>
          <TableHead className='mi_table__head'>
            <TableRow>
              {filteredColumns?.map((column, index) => (
                <TableCell key={index}>
                  <FieldHead
                    title={t(column.title)}
                    align={column.key === "action" ? "center" : "start"}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {apiData?.data?.length > 0 ? (
              apiData?.data?.map((row, index) => (
                <InvSummaryRow
                  key={index}
                  row={row}
                  filteredColumns={filteredColumns}
                  t={t}
                  setFetchAPIData={setFetchAPIData}
                  typePage={typePage}
                />
              ))
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: "2rem" }} colSpan={6}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={apiData?.totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

function InvSummaryRow({ row, filteredColumns, t, setFetchAPIData, typePage }) {
  const navigate = useNavigate();

  const reverseShipmentRedirection = () => {
    navigate("/user/shipments/reverse-shipments", {
      state: { productInfo: row },
    });
  };
  const { mutate } = useDisposeProducts();
  const handleDispose = (id) => {
    mutate(
      { atomId: id },
      {
        onSuccess: (response) => {
          setFetchAPIData((PREV) => !PREV);
          toast.success("Batch Disposed Successfully");
        },
      },
    );
  };
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {filteredColumns.map((column, index) => (
        <React.Fragment key={index}>
          {column.key === "productCategory" && (
            <TableCell>
              <FieldBody text={capitalToNormalCase(row?.productId?.type)} />
            </TableCell>
          )}
          {column.key === "productName" && (
            <TableCell>
              <ProductIcon
                type={row?.productId?.type}
                name={row?.productId?.name}
                size='tiny'
                fallback={
                  <FieldBody text={row?.productId?.name} color='bold' />
                }
              />
            </TableCell>
          )}
          {column.key === "batchNo" && (
            <TableCell>
              <FieldBody text={row?.batchNo} color='bold' />
            </TableCell>
          )}
          {column.key === "manufacturer" && (
            <TableCell>
              <FieldBody text={row?.manufacturer?.name} />
            </TableCell>
          )}
          {column.key === "quantity" && (
            <TableCell>
              <FieldBody text={row?.quantity + " " + row?.productId?.units} />
            </TableCell>
          )}
          {column.key === "status" && (
            <TableCell>
              <StatusLabel status={row?.status} tone='grey' />
            </TableCell>
          )}
          {column.key === "action" && (
            <>
              {typePage !== "dataVisualization" ? (
                <TableCell>
                  {row?.status === "RECALL" && (
                    <div className='Table__actions'>
                      <button
                        className='mi_btn mi_btn_small mi_btn_secondary'
                        onClick={reverseShipmentRedirection}
                      >
                        <span>{t("forward_supply")}</span>
                      </button>
                      <button
                        className='mi_btn mi_btn_small mi_btn_secondary'
                        onClick={() => handleDispose(row?._id)}
                      >
                        <span>{t("Dispose")}</span>
                      </button>
                    </div>
                  )}
                </TableCell>
              ) : (
                <TableCell align='center'>---</TableCell>
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </TableRow>
  );
}
