import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import InfoIcon from "/icons/table/info.svg";
import { useNavigate } from "react-router";
import { getVarList } from "../../../../redux/inventoryActions";
import React, { useEffect, useState } from "react";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function VARtable({ filteredColumns }) {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [varData, setVarData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getVarList(page, limit);
      setVarData(res.data);
    };
    fetchData();
  }, [page, limit]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label='simple table'>
        <TableHead className='mi_table__head'>
          <TableRow>
            {filteredColumns.map((column, index) => (
              <TableCell key={column.key}>
                <FieldHead
                  title={t(column.title)}
                  align={
                    column.key === "status" || column.key === "view"
                      ? "center"
                      : ""
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {varData?.data?.length > 0 ? (
            varData?.data?.map((row) => (
              <TableBodyRow
                row={row}
                key={row?._id}
                filteredColumns={filteredColumns}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: "2rem" }} colSpan={6}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={varData?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function TableBodyRow({ row, filteredColumns }) {
  const navigate = useNavigate();

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {filteredColumns.map((column) => (
        <React.Fragment key={column?.key}>
          {column.key === "varid_no" && (
            <TableCell>
              <FieldBody text={row?.id} color='bold' />
            </TableCell>
          )}

          {column.key === "date_of_report" && (
            <TableCell>
              <FieldBody
                text={new Date(row?.reportDate).toLocaleDateString()}
              />
            </TableCell>
          )}

          {column.key === "date_of_inspection" && (
            <TableCell>
              <FieldBody
                text={new Date(row?.inspectionDateTime).toLocaleDateString()}
              />
            </TableCell>
          )}

          {column.key === "vaccine" && (
            <TableCell>
              <ProductIcon
                type={row?.shipmentDetails?.products[0]?.product?.type}
                name={row?.shipmentDetails?.products[0]?.product?.name}
                size='tiny'
                fallback={
                  <FieldBody
                    text={row?.shipmentDetails?.products[0]?.product?.name}
                    color='bold'
                  />
                }
              />
            </TableCell>
          )}

          {column.key === "from" && (
            <TableCell>
              <FieldBody
                text={row?.shipmentDetails?.products[0]?.manufacturer?.name}
              />
            </TableCell>
          )}

          {column.key === "to" && (
            <TableCell>
              <FieldBody text='CMSD' />
            </TableCell>
          )}

          {column.key === "status" && (
            <TableCell align='center'>
              {row?.status ? (
                <StatusLabel
                  status={row?.status}
                  tone={
                    row?.status === "ACCEPTED"
                      ? "green"
                      : row?.status === "REJECTED"
                      ? "red"
                      : "blue"
                  }
                />
              ) : (
                "---"
              )}
            </TableCell>
          )}

          {column.key === "view" && (
            <TableCell>
              <div
                onClick={() =>
                  navigate(`/user/vaccine-arrival-report/view-var/${row?._id}`)
                }
                className='Table__Icon mi_link'
              >
                <img src={InfoIcon} alt='Icon' />
              </div>
            </TableCell>
          )}
        </React.Fragment>
      ))}
    </TableRow>
  );
}
