import { useSelector } from "react-redux";
import { Navigate } from "react-router";

export default function PermissionRoute({ children, allowed, type }) {
  const { permissions } = useSelector((state) => state.auth);

  const isAuthorized = permissions && permissions?.includes(allowed);

  if (type) {
    if (type === "actions") {
      return isAuthorized ? children : null;
    }
  }

  if (!type) {
    return isAuthorized ? children : <Navigate to='/unauthorized' replace />;
  }
}
