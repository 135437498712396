import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./VARform.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import toast from "react-hot-toast";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const VARform4 = ({ setFormTab, setFormComplete, setData, storedData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      Question1: storedData?.documents?.invoice || true,
      Question2: storedData?.documents?.packingList || true,
      Question3: storedData?.documents?.vaccineArrivalReport || true,
      Question4: storedData?.documents?.releaseCertificate || true,
      comment: storedData?.documents?.comment || "",
    },
    onSubmit: (values) => {
      setData(4, {
        invoice: values.Question1,
        packingList: values.Question2,
        vaccineArrivalReport: values.Question3,
        releaseCertificate: values.Question4,
        comment: values.comment,
      });
      navigate("?form=part5");
      setFormComplete(5);

      toast.success(t("VAR_documents_saved"));
    },
  });

  const handlePrev = () => {
    navigate("?form=part3");
    setFormComplete(3);
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className='VARform'>
        <div className='VARform__header'>
          <h1 className='page__body_bigheading_fs'>{t("VAR_part4_title")}</h1>
        </div>
        <div className='VARform__body'>
          <div className='VARform__table'>
            <article className='VARform__row two_column'>
              <div className='VARform__column'>
                <div className='VARradio__questions two_column'>
                  <div></div>
                </div>
                <div className='VARradio__questions two_column'>
                  <p className='page__body_fs mi_black'>{t("invoice")}</p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question1")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className='VARradio__questions two_column'>
                  <p className='page__body_fs mi_black'>{t("packing_list")}</p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question2")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {/* <div className="VARradio__questions two_column">
                  <p className="page__body_fs mi_black">
                    {t("vaccine_arrival_report")}
                  </p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question3")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div> */}
                <div className='VARradio__questions two_column'>
                  <p className='page__body_fs mi_black'>
                    {t("release_certificate")}
                  </p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question4")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className='VARform__column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t("comments")}</p>
                  <textarea
                    className='mi_custom_textarea'
                    {...formik.getFieldProps("comment")}
                  />
                  {formik.touched.comment && formik.errors.comment && (
                    <span className='Input__error_fs'>
                      {formik.errors.comment}
                    </span>
                  )}
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className='VARform__footer'>
          <div className='Input__column_action'>
            <button
              type='button'
              className='mi_btn mi_btn_medium mi_btn_secondary'
              onClick={handlePrev}
            >
              <span>{t("prev")}</span>
            </button>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              type='submit'
            >
              <span>{t("save_continue")}</span>
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default VARform4;
