import { useQuery, keepPreviousData } from '@tanstack/react-query';
import {
  getCurrentStockData,
  getInventoryEventsData,
  getOutOfStockData,
  getAllInventoryProductsData,
  getNearExpiryData,
  getExpiredStockData,
  getRejectedStockData,
  getUnitsWastedData,
  getNeedForCastData,
  getUnitsDisposedData,
  getUnitsQuarantinedData,
  getCurrentStockGraph,
  outOfStockGraph,
  outOfStockGraphDecision,
  getNearExpiryDecision,
  getExpiredDecision,
  getWastedDecision,
  getStockDecision,
  getNetUtilizationDecision,
} from './api';

export const useGetCurrentStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['currentStocks', { data, page }],
    queryFn: () => getCurrentStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetInventoryEventsData = (data, page, limit) => {
  return useQuery({
    queryKey: ['inventoryEvents', { data, page }],
    queryFn: () => getInventoryEventsData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetOutOfStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['outOfStock', { data, page }],
    queryFn: () => getOutOfStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetNearExpiryData = (data, page, limit) => {
  return useQuery({
    queryKey: ['nearExpiry', { data, page }],
    queryFn: () => getNearExpiryData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetExpiredStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['expiredStock', { data, page }],
    queryFn: () => getExpiredStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetRejectedStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['rejectedStock', { data, page }],
    queryFn: () => getRejectedStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetUnitsWastedData = (data, page, limit) => {
  return useQuery({
    queryKey: ['unitsWasted', { data, page }],
    queryFn: () => getUnitsWastedData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetUnitsQuarantinedData = (data, page, limit) => {
  return useQuery({
    queryKey: ['unitsQuarantined', { data, page }],
    queryFn: () => getUnitsQuarantinedData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetUnitsDisposedData = (data, page, limit) => {
  return useQuery({
    queryKey: ['unitsDisposed', { data, page }],
    queryFn: () => getUnitsDisposedData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetAllInventoryProductsData = (data, page, limit) => {
  return useQuery({
    queryKey: ['allInventoryProducts', { data, page }],
    queryFn: () => getAllInventoryProductsData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useNeedForCastData = (id) => {
  return useQuery({
    queryKey: ['needForCast'],
    queryFn: () => getNeedForCastData(id),
  });
};

export const useGetCurrentStockGraph = (data) => {
  return useQuery({
    queryKey: ['currentStockGraph', { data }],
    queryFn: () => getCurrentStockGraph(data),
  });
};

export const useOutOfStockGraph = (data) => {
  return useQuery({
    queryKey: ['outOfStockGraph', { data }],
    queryFn: () => outOfStockGraph(data),
  });
};

export const useOutOfStockGraphDecision = (data) => {
  return useQuery({
    queryKey: ['outOfStockGraphDecision', { data }],
    queryFn: () => outOfStockGraphDecision(data),
  });
};

export const useGetNearExpiryDecision = (data) => {
  return useQuery({
    queryKey: ['getNearExpiryDecision', { data }],
    queryFn: () => getNearExpiryDecision(data),
  });
};

export const useGetExpiredDecision = (data) => {
  return useQuery({
    queryKey: ['getExpiredDecision', { data }],
    queryFn: () => getExpiredDecision(data),
  });
};

export const useGetWastedDecision = (data) => {
  return useQuery({
    queryKey: ['getWastedDecision', { data }],
    queryFn: () => getWastedDecision(data),
  });
};

export const useGetStockDecision = (data) => {
  return useQuery({
    queryKey: ['getStockDecision', { data }],
    queryFn: () => getStockDecision(data),
  });
};

export const useGetNetUtilizationDecision = (data) => {
  return useQuery({
    queryKey: ['getNetUtilizationDecision', { data }],
    queryFn: () => getNetUtilizationDecision(data),
  });
};
