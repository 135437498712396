import "./Breadcrumb.css";
import { Link } from "react-router";

export default function BreadCrumb({ url1, Link1, Link2, Link3 }) {
  return (
    <div className='BookingBreadCrumb__list'>
      <Link to={url1} className='BookingBreadCrumb__link_fs'>
        {Link1}
      </Link>
      <i className='bx bx-chevron-right'></i>
      <p className={`BookingBreadCrumb__link_fs ${Link3 ? "" : "active"}`}>
        {Link2}
      </p>
      {Link3 && (
        <>
          <i className='bx bx-chevron-right'></i>
          <p className='BookingBreadCrumb__link_fs active'>{Link3}</p>
        </>
      )}
    </div>
  );
}
