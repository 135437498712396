import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import InfoIcon from "/icons/table/info.svg";
import AcceptIcon from "/icons/table/accepted.svg";
import NotAcceptIcon from "/icons/table/not-accepted.svg";
import { Link } from "react-router";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { useTranslation } from "react-i18next";

export default function ShipmentSummary({ shipments, ismobile }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead className='mi_table__head th_downborder'>
            <TableRow>
              <TableCell>
                <FieldHead title={t("shipment_id")} />
              </TableCell>
              {!ismobile && (
                <TableCell>
                  <FieldHead title={t("from")} />
                </TableCell>
              )}
              {!ismobile && (
                <TableCell>
                  <FieldHead title={t("to")} />
                </TableCell>
              )}
              {!ismobile && (
                <TableCell>
                  <FieldHead title={t("status")} align={"center"} />
                </TableCell>
              )}
              <TableCell align='center'>
                <FieldHead title={t("view")} align={"center"} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipments ? (
              <>
                {shipments?.length > 0 ? (
                  <>
                    {shipments?.map((row) => (
                      <TableBodyRow
                        row={row}
                        key={row?._id}
                        index={row?._id}
                        ismobile={ismobile}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: "2rem" }} colSpan={5}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: 0 }} colSpan={5} align='center'>
                  <div className='Loading_message'>
                    <p className='page__note_fs'>{t("loading")}...</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function TableBodyRow({ row, index, ismobile }) {
  return (
    <React.Fragment>
      <TableRow
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>
          <FieldBody
            text={row?.id}
            subtext={new Date(row.createdAt).toLocaleDateString()}
            color='blue'
          />
        </TableCell>
        {!ismobile && (
          <TableCell>
            <FieldBody
              text={row?.source?.name}
              subtext={row?.source?.orgLevel}
            />
          </TableCell>
        )}

        {!ismobile && (
          <TableCell>
            <FieldBody
              text={row?.destination?.name}
              subtext={row?.destination?.orgLevel}
            />
          </TableCell>
        )}

        {!ismobile && (
          <TableCell align={"center"}>
            <StatusLabel
              status={row?.status}
              tone={row?.status === "sent" ? "blue" : "green"}
            />
          </TableCell>
        )}

        <TableCell>
          <Link
            to={"/user/shipments/view-shipment/" + row?._id}
            className='Table__Icon'
          >
            <img src={InfoIcon} alt='Icon' />
          </Link>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
