import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getProducts } from "../../../../redux/userActions";
import {
  getBatchRecall,
  getRecallBatches,
  recallInventory,
} from "../../../../redux/inventoryActions";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { callPopup } from "../../../../store/slices/popupSlice";
import BreadCrumb from "../../../../shared/utils/breadCrumb/BreadCrumb";
import RecallLocations from "../recallTables/RecallLocations";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { Alert } from "@mui/material";

export default function CreateRecall() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = React.useState();

  const [product, setProduct] = React.useState(null);
  const [batch, setBatch] = React.useState();

  const [productList, setProductList] = React.useState(null);

  const [manufacturer, setManufacturer] = React.useState(null);
  const [batchResult, setBatchResult] = React.useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts();
      setProductList(res.data);
    }
    fetchData();
  }, []);

  const [batchList, setBatchList] = React.useState();

  useEffect(() => {
    async function fetchData(product, batch, page, limit) {
      if (product && batch) {
        const res = await getRecallBatches(
          product?._id,
          manufacturer,
          batch,
          page,
          limit,
        );
        if (res.success == true) {
          setBatchList(res?.data);
        } else {
          dispatch(
            callPopup({
              open: true,
              type: "error",
              page: "orders",
              title: res.data.message,
              message: res.data.data,
            }),
          );
        }
      }
    }
    fetchData(product, batch, page, limit);
  }, [batchResult, page, limit]);

  const handleRecallAction = async () => {
    const res = await recallInventory({
      productId: product?._id,
      manufacturerId: manufacturer,
      batchNo: batch,
    });

    if (res.success == true) {
      navigate("/user/inventory/recall");
      dispatch(
        callPopup({
          open: true,
          type: "success",
          page: "inventory",
          message: t("recall_has_been_created_successfully"),
          action: "/user/inventory/recall",
        }),
      );
    } else {
      setErrorMessage(res.data.data || res.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div className='back_Link' onClick={() => navigate(-1)}>
              <i className='bx bx-arrow-back '></i>
            </div>
            <h1 className='Page__headline_title_fs'>{t("recall_inventory")}</h1>
          </div>
          <BreadCrumb
            url1='/user/inventory/recall'
            Link1={`${t("recall")}`}
            Link2={`${t("create_recall")}`}
          />
        </div>

        <div className='Recall__container'>
          <div className='Recall__input_actions' style={{ maxHeight: "25rem" }}>
            <p className='Input__note_fs body_fs'>{t("create_recall_note")}</p>
            <div className='Input__column'>
              <p className='Input__label_fs'>
                {t("product")} <b>*</b>
              </p>
              <FormControl fullWidth>
                <Select
                  className='mui_custom_input_field'
                  value={product || ""}
                  onChange={(event) => {
                    setProduct(event.target.value);
                  }}
                >
                  {productList &&
                    productList?.map((prod, index) => (
                      <MenuItem key={index} value={prod}>
                        {prod?.name?.length > 39
                          ? prod?.name.substring(0, 40) + "..."
                          : prod?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className='Input__column'>
              <p className='Input__label_fs'>
                {t("manufacturer")} <b>*</b>
              </p>
              <FormControl fullWidth>
                <Select
                  className='mui_custom_input_field'
                  value={manufacturer || ""}
                  onChange={(event) => {
                    setManufacturer(event.target.value);
                  }}
                  disabled={product !== null ? false : true}
                >
                  {product?.manufacturers &&
                    product?.manufacturers?.map((org, index) => (
                      <MenuItem key={index} value={org?.details?._id}>
                        {org?.details?.name?.length > 39
                          ? org?.details?.name.substring(0, 40) + "..."
                          : org?.details?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className='Input__column'>
              <p className='Input__label_fs'>
                {t("batch")} <b>*</b>
              </p>
              <input
                type='text'
                className={`mi_custom_textfield `}
                disabled={manufacturer !== null ? false : true}
                value={batch || ""}
                onChange={(event) => {
                  setBatch(event.target.value);
                }}
              />
            </div>

            <div className='flex_end'>
              <div className='recall__right_actions'>
                <button
                  className='mi_btn mi_btn_medium mi_btn_outline'
                  onClick={() => navigate("/user/inventory")}
                >
                  <span>{t("back")}</span>
                </button>
                <button
                  className={`mi_btn mi_btn_medium  ${
                    product && batch ? "mi_btn_approved" : "mi_btn_disabled"
                  }`}
                  onClick={() => setBatchResult((prev) => !prev)}
                >
                  <i className='fa-solid fa-magnifying-glass'></i>
                  <span>{t("get_current_locations")}</span>
                </button>
              </div>
            </div>

            <div>
              {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
            </div>
          </div>

          <div className='Table__page_wrapper'>
            {batchList && (
              <>
                {" "}
                {batchList?.data?.length > 0 && (
                  <div className='Page__table_space'>
                    <div className='Select__tab_headline'>
                      <h1 className='page__body_heading_fs'>
                        {t("batch_info")} :
                      </h1>
                    </div>
                    <RecallLocations
                      data={batchList?.data}
                      page={page}
                      limit={limit}
                      totalRecords={batchList?.totalRecords}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      t={t}
                    />
                    <div className='flex_end'>
                      <div className='recall__right_actions top_space'>
                        <button
                          className={`mi_btn mi_btn_medium  ${
                            product && batch
                              ? "mi_btn_approved"
                              : "mi_btn_disabled"
                          }`}
                          onClick={() => handleRecallAction()}
                        >
                          <i className='fa-solid fa-rotate-left'></i>
                          <span>{t("recall_batch_from_all_locations")}</span>
                        </button>
                      </div>
                    </div>{" "}
                  </div>
                )}
                {batchList?.data?.length === 0 && (
                  <div>
                    <EmptyTable />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
