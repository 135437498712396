import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import "./Campaign.css";
import CampaignHistory from "./CampaignHistory";
import OngoingCampaign from "./OngoingCampaign";
import { getCampaigns } from "../../../redux/shipmentActions";
import PermissionRoute from "../../../web/routes/routeLayers/PermissionRoute";
import { useTranslation } from "react-i18next";
import Campaign1 from "/icons/all/Campaign.svg";
import { useSelector } from "react-redux";

export default function Campaign() {
  const { userInfo } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [campaignHistory, setCampaignHistory] = useState();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getCampaigns(true, page, limit);
      setData(res?.data?.data);
      const resHistory = await getCampaigns(false);
      setCampaignHistory(resHistory?.data?.data);
    };
    fetchData();
  }, []);

  return (
    <section className='Page__main_wrapper'>
      {/* <div className="Page__headline_wrapper">
        <div className="Page__headline">
          <img src={Campaign1} className="Campaign__icon" />
          <h1 className="Page__headline_title_fs">
            {" "}
            {`${t("campaign_management")}`}{" "}
          </h1>
        </div>
      </div> */}
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          {userInfo?.orgLevel === "EPI" && (
            <PermissionRoute allowed={"CREATE_CAMPAIGN"} type='actions'>
              <Link
                to='/user/campaign/new'
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <i className='fa-solid fa-plus'></i>
                <span> {`${t("create_campaign")}`}</span>
              </Link>
            </PermissionRoute>
          )}
        </div>
      </div>

      <OngoingCampaign data={data} level={userInfo?.orgLevel} />

      <div className='Campaign__table_wrapper'>
        <div className='Campaign__head_space'>
          <h1 className='page__body_heading_fs'>
            {`${t("campaign_history")}`} :
          </h1>
        </div>

        <div className='Campaign__table_wrapper'>
          <CampaignHistory level={userInfo?.orgLevel} />
        </div>
      </div>
    </section>
  );
}
