import React from 'react';
import VaccineDetails from './VaccineDetails';
import BeneficiaryDetails from './BeneficiaryDetails';
import BeneficiaryTable from './beneficiaryTable/BeneficiaryTable';

export default function SelectBeneficiary({
  formik,
  addProduct,
  error1,
  editItem,
  setEditItem,
  updatePatient,
  setVacList,
  reOpened,
  handleHistoryValidation,
}) {
  return (
    <React.Fragment>
      <div>
        <VaccineDetails
          formik={formik}
          setVacList={setVacList}
          reOpened={reOpened}
        />
        <BeneficiaryDetails
          formik={formik}
          addProduct={addProduct}
          error1={error1}
          editItem={editItem}
          updatePatient={updatePatient}
          reOpened={reOpened}
          handleHistoryValidation={handleHistoryValidation}
        />
        <div className='SelectBenef__table'>
          <BeneficiaryTable
            formik={formik}
            setEditItem={setEditItem}
            reOpened={reOpened}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
