import React from 'react';

('use client');

import { TrendingUp } from 'lucide-react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from 'recharts';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

const chartConfig = {
  daysRemaining: {
    label: 'Days Remaining',
    color: '#4F46E5',
  },
  dailyUtilization: {
    label: 'Daily Utilization',
    color: 'hsl(var(--chart-2))',
  },
  label: {
    color: 'hsl(var(--background))',
  },
};

export default function NetUtilization({ data }) {
  // console.log(data);

  return (
    <div>
      <VerticalBarChart data={data} />
    </div>
  );
}

function formatDaysRemaining(value) {
  if (value < 7) {
    return `${Math.ceil(value)} day${Math.ceil(value) > 1 ? 's' : ''}`;
  } else if (value < 30) {
    return `${Math.round(value / 7)} week${
      Math.round(value / 7) > 1 ? 's' : ''
    }`;
  } else if (value < 365) {
    return `${Math.round(value / 30)} month${
      Math.round(value / 30) > 1 ? 's' : ''
    }`;
  } else {
    return `${Math.round(value / 365)} year${
      Math.round(value / 365) > 1 ? 's' : ''
    }`;
  }
}

function VerticalBarChart({ data }) {
  return (
    <Card>
      <CardHeader>
        <section className='w-full flex items-center justify-between'>
          <article className='flex flex-col items-start justify-start'>
            <div className='flex gap-2 text-base font-medium leading-none'>
              Predict Stock Out based on Utilization Trend
            </div>
          </article>
        </section>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            accessibilityLayer
            data={data?.map((item) => ({
              ...item,
              daysRemainingLabel: formatDaysRemaining(item.daysRemaining),
            }))}
            layout='vertical'
            margin={{
              right: 16,
            }}
          >
            <CartesianGrid horizontal={false} />
            <YAxis
              dataKey='location'
              type='category'
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              width={150}
            />
            <XAxis dataKey='daysRemaining' type='number' hide />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator='line' />}
            />
            <Bar
              dataKey='daysRemaining'
              layout='vertical'
              fill='#4F46E5'
              radius={4}
            >
              {/* <LabelList
                dataKey='daysRemaining'
                position='insideLeft'
                offset={8}
                className='fill-[--color-label]'
                fontSize={12}
              /> */}
              <LabelList
                dataKey='daysRemainingLabel'
                position='right'
                offset={8}
                className='fill-foreground'
                fontSize={12}
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
