import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import DraftIndentRequest from "./DraftTables/DraftIndentRequest";
import DraftIndentHistory from "./DraftTables/DraftIndentHistory";
import { getInBoundOrders } from "../../../redux/orderActions";
import AutoIndentTable from "../autoIndent/AutoForm/AutoIndentTable";
import MinMaxTable from "../autoIndent/minMaxForm/MinMaxTable";
import ConsumptionTable from "../autoIndent/consumptionForm/ConsumptionTable";

export default function DraftIndents() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tab } = useParams();

  let initialColumns = {};
  let columns = [];
  const OrderOutboundColumns = [
    { key: "product_quantity", title: "product_quantity" },
    { key: "order_sent_by", title: "order_sent_by" },
    { key: "orderId", title: "order_id" },
    { key: "order_placed_on", title: "order_placed_on" },
    { key: "delivery_location", title: "delivery_location" },
    { key: "status", title: "status" },
  ];

  const OrderInboundColumns = [
    { key: "product_quantity", title: "product_quantity" },
    { key: "order_sent_by", title: "order_sent_by" },
    { key: "orderId", title: "order_id" },
    { key: "order_placed_on", title: "order_placed_on" },
    { key: "delivery_location", title: "delivery_location" },
    { key: "status", title: "status" },
    { key: "action", title: "action" },
  ];

  const OrderOutboundInitialColumns = {
    product_quantity: true,
    order_sent_by: true,
    orderId: true,
    order_placed_on: true,
    delivery_location: true,
    status: true,
  };

  const OrderInboundInitialColumns = {
    product_quantity: true,
    order_sent_by: true,
    orderId: true,
    order_placed_on: true,
    delivery_location: true,
    status: true,
    action: true,
  };

  if (tab === "history") {
    initialColumns = OrderOutboundInitialColumns;
    columns = OrderOutboundColumns;
  } else if (tab === "draft") {
    initialColumns = OrderInboundInitialColumns;
    columns = OrderInboundColumns;
  }

  useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [tab]);

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key],
  );

  const [orders, setOrders] = useState([]);

  const [pagination, setPagination] = useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      const inbound = await getInBoundOrders("CREATED", 0, 10);

      setOrders(inbound?.data?.data);
      setPagination({
        recordsPerPage: inbound?.data?.limit,
        totalRecords: inbound?.data?.totalRecords,
        currentPage: inbound?.data?.page,
      });
    };
    fetchData();
  }, []);
  return (
    <section className='Page__main_wrapper'>
      {/* <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <Link to={'/user/need-forecasting/auto-indent'} className='back_Link'>
            <i className='bx bx-arrow-back icon_blue'></i>
          </Link>
          <h1 className='Page__headline_title_fs'>
            {` ${t('Auto Generated Indents')}`}
          </h1>
        </div>
      </div> */}
      <div className='Page__action_wrapper'>
        <div className='tab__btn_group'>
          <Link to={"/user/need-forecasting/draft"}>
            <div className={`tab__button ${tab === "draft" && "active"}`}>
              <h1 className='tab__btn_text_fs'>{t("Auto Indent Request")}</h1>
            </div>
          </Link>
          <Link to={"/user/need-forecasting/history"}>
            <div className={`tab__button ${tab === "history" && "active"}`}>
              <h1 className='tab__btn_text_fs'>{t("Auto Indent History")}</h1>
            </div>
          </Link>
        </div>
        <div className='tab__btn_group'>
          <Link to={"/user/need-forecasting/auto-indent"}>
            <div className={`tab__button ${tab === "auto-indent" && "active"}`}>
              <h1 className='tab__btn_text_fs'>{t("auto_indenting")}</h1>
            </div>
          </Link>
          <Link to={"/user/need-forecasting/minmax"}>
            <div className={`tab__button ${tab === "minmax" && "active"}`}>
              <h1 className='tab__btn_text_fs'>{t("minimum_maximum")}</h1>
            </div>
          </Link>
          <Link to={"/user/need-forecasting/consumption"}>
            <div className={`tab__button ${tab === "consumption" && "active"}`}>
              <h1 className='tab__btn_text_fs'>{t("consumption")}</h1>
            </div>
          </Link>
        </div>
      </div>

      <div className='Page__table_wrapper'>
        {tab === "draft" && (
          <DraftIndentRequest
            filteredColumns={filteredColumns}
            orders={orders}
          />
        )}
        {tab === "history" && (
          <DraftIndentHistory
            filteredColumns={filteredColumns}
            orders={orders}
          />
        )}
        {tab === "auto-indent" && <AutoIndentTable />}
        {tab === "minmax" && <MinMaxTable />}
        {tab === "consumption" && <ConsumptionTable />}
      </div>
    </section>
  );
}
